import { ComponentProps, FC, useCallback } from "react"
import { IProductV1 } from "src/domain/entities"
import { productUpdateService } from "src/domain/services/product/productUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IProductUpdateFormFields, ProductUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof ProductUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (product: IProductV1) => void
}

export const ProductUpdateDrawerFormController: FC<Props> = ({
	product,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IProductUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedProduct = await productUpdateService(
					{ filter: { id: product.id }, update: values },
					token,
				)
				onSuccess && onSuccess(updatedProduct)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, product.id],
	)

	return (
		<ProductUpdateDrawerFormView
			product={product}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
