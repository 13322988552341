import {
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
} from "@chakra-ui/react"
import { FC } from "react"
import {
	BsArrowBarDown,
	BsArrowBarUp,
	BsBoxArrowInDown,
	BsBoxArrowUp,
	BsCashCoin,
	BsPlus,
	BsReceiptCutoff,
} from "react-icons/bs"
import { EItemType } from "../entries/EItemType"
import { useGlobalContext } from "../pages/GlobalContext"

export const SlipAddButton: FC = () => {
	const {
		setCashReceiptAddType,
		cashReceiptAddDrawerDisclosure,
		setJobWorkItemSlipAddType,
		jobWorkItemSlipAddDrawerDisclosure,
		setJobWorkBillAddType,
		jobWorkBillAddDrawerDisclosure,
		setBillAddType,
		billAddDrawerDisclosure,
	} = useGlobalContext()

	return (
		<Popover placement="bottom-start">
			<PopoverTrigger>
				<Button
					margin={2}
					colorScheme={"blue"}
					size="sm"
					leftIcon={<BsPlus size={25} />}
				>
					Add Entry
				</Button>
			</PopoverTrigger>
			<PopoverContent
				width="45vw"
				background={"rgba(250,250,250,0.70)"}
				backdropFilter="blur(8px)"
				_focus={{ outline: "none" }}
			>
				<PopoverBody>
					<Flex justify="space-between">
						<Box flex={1}>
							<MegaMenuButton
								title="Delivery Challan"
								subTitle="Delivery Challan issued by your company"
								icon={<BsBoxArrowUp size={22} />}
								onClick={() => {
									setJobWorkBillAddType(EItemType.INWARD_JOBWORK_BILL)

									jobWorkBillAddDrawerDisclosure?.onOpen()
								}}
							/>
							<MegaMenuButton
								title="Received Challan"
								subTitle="Challan received and issued by your party"
								icon={<BsBoxArrowInDown size={22} />}
								onClick={() => {
									setJobWorkBillAddType(EItemType.OUTWARD_JOBWORK_BILL)
									jobWorkBillAddDrawerDisclosure?.onOpen()
								}}
							/>
							<MegaMenuButton
								title="Sale Bill"
								subTitle="Sale bill issued by your company"
								icon={<BsReceiptCutoff size={22} />}
								onClick={() => {
									setBillAddType(EItemType.SALE_BILL)
									billAddDrawerDisclosure?.onOpen()
								}}
							/>
							<MegaMenuButton
								title="Purchase Bill"
								subTitle="Bill issued by your party"
								icon={<BsReceiptCutoff size={22} />}
								onClick={() => {
									setBillAddType(EItemType.PURCHASE_BILL)
									billAddDrawerDisclosure?.onOpen()
								}}
							/>
						</Box>
						<Box flex={1}>
							<MegaMenuButton
								title="In Slip"
								subTitle="Goods received slip"
								icon={<BsArrowBarDown size={22} />}
								onClick={() => {
									setJobWorkItemSlipAddType(EItemType.INWARD)
									jobWorkItemSlipAddDrawerDisclosure?.onOpen()
								}}
							/>
							<MegaMenuButton
								title="Out Slip"
								subTitle="Goods sent slip"
								icon={<BsArrowBarUp size={22} />}
								onClick={() => {
									setJobWorkItemSlipAddType(EItemType.OUTWARD)
									jobWorkItemSlipAddDrawerDisclosure?.onOpen()
								}}
							/>

							<MegaMenuButton
								title="Cash Received"
								subTitle="Cash received from party"
								icon={<BsCashCoin size={22} />}
								onClick={() => {
									setCashReceiptAddType(EItemType.INWARD_CASH)
									cashReceiptAddDrawerDisclosure?.onOpen()
								}}
							/>
							<MegaMenuButton
								title="Cash Paid"
								subTitle="Cash paid by you"
								icon={<BsCashCoin size={22} />}
								onClick={() => {
									setCashReceiptAddType(EItemType.OUTWARD_CASH)
									cashReceiptAddDrawerDisclosure?.onOpen()
								}}
							/>
						</Box>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}

const MegaMenuButton: FC<{
	title: string
	subTitle: string
	onClick: () => void
	icon?: JSX.Element
}> = ({ title, subTitle, icon, onClick }) => {
	return (
		<Button
			paddingX={6}
			paddingY={8}
			variant="ghost"
			width="full"
			_hover={{
				backgroundColor: "gray.200",
			}}
			onClick={onClick}
		>
			<Box textAlign="left" width="full">
				<Flex alignItems="center">
					{icon && <Box paddingRight={5}>{icon}</Box>}
					<Box>
						<Text fontSize="lg" fontWeight="semibold" lineHeight={1.3}>
							{title}
						</Text>
						<Text fontSize="sm" fontWeight="normal" color="gray.600">
							{subTitle}
						</Text>
					</Box>
				</Flex>
			</Box>
		</Button>
	)
}
