import { Box } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"

interface Props extends Omit<ComponentProps<typeof Box>, "children"> {}

export const InputDescription: FC<Props> = ({ children, ...rest }) => (
	<Box
		textStyle="p"
		fontSize="sm"
		color={"gray.500"}
		lineHeight="1.2"
		padding="1"
		{...rest}
	>
		{children}
	</Box>
)
