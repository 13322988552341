import { IPagination, ISubPartyV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id?: string; partyId?: string; isDefault?: boolean } & IPagination
	include?: { details?: boolean; party?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: ISubPartyV1[]
}

export async function subPartyListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/subParty/list/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
