import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
	Stack,
} from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { Link } from "react-router-dom"
import { JobWorkLogo } from "src/components/shared/JobWorkLogo"
import { ErrorMessageField } from "src/components/ui/ErrorMessageField"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { IForgotPasswordFormFields } from "./IForgotPasswordFormFields"

interface Props {
	handleSubmit: FormikOnSubmit<IForgotPasswordFormFields>
}

export const ForgotPasswordFormView: FC<Props> = ({ handleSubmit }) => (
	<Formik<IForgotPasswordFormFields>
		initialValues={{ mobileNo: "", password: "", confirmPassword: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ handleChange, isSubmitting }) => (
			<Stack as={Form} maxWidth={"sm"}>
				<Box>
					<Box textAlign={"center"}>
						<JobWorkLogo />
					</Box>
					<Heading as="h1" size="lg">
						Forgot Password
					</Heading>
					<Box pt="1" color="gray.600">
						Remember the password?{" "}
						<Link to="/login">
							<Box as="span" fontWeight={"semibold"} color={"blue.600"}>
								Go Back
							</Box>
						</Link>
					</Box>
					<br />
				</Box>
				<Flex direction={"column"} gridGap="2">
					{/* Mobile No */}
					<FormControl>
						<InputLabel label="Mobile No" />
						<InputGroup>
							<InputLeftAddon children="+91" />
							<Input
								type="tel"
								name="mobileNo"
								onChange={handleChange}
								isRequired
								autoFocus
							/>
						</InputGroup>
						<InputDescription>
							OTP will be sent to this number.
							<br />
							You will not receive any other SMS in future except OTP.
						</InputDescription>
						<ErrorMessage component={ErrorMessageField} name="mobileNo" />
					</FormControl>
					{/* Password */}
					<FormControl>
						<InputLabel label="New Password" />
						<Input
							type="password"
							name="password"
							isRequired
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="password" />
					</FormControl>
					{/* Conform Password */}
					<FormControl>
						<InputLabel label="Confirm Password" />
						<Input
							type="password"
							name="confirmPassword"
							isRequired
							onChange={handleChange}
						/>
						<ErrorMessage
							component={ErrorMessageField}
							name="confirmPassword"
						/>
					</FormControl>
				</Flex>
				<Box>
					{/* Submit Button */}
					<Button
						colorScheme={"blue"}
						type="submit"
						disabled={isSubmitting}
						isLoading={isSubmitting}
						width="full"
					>
						Send OTP
					</Button>
				</Box>
			</Stack>
		)}
	</Formik>
)
