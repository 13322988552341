import { ComponentProps, FC, useCallback } from "react"
import { userPasswordUpdateService } from "src/domain/services/user/userPasswordUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IUserPasswordUpdateFormFields, UserPasswordUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof UserPasswordUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: () => void
}

export const UserPasswordUpdateDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IUserPasswordUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.newPassword !== values.newPasswordConfirm) {
					throw new Error("New password and confirm password does not match")
				}

				await userPasswordUpdateService(values, token)
				onSuccess?.()
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ newPasswordConfirm: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <UserPasswordUpdateDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
