import { Center } from "@chakra-ui/react"
import { FC } from "react"
import { ContainerWrapper } from "../../wrappers/ContainerWrapper"
import { RegisterFormContainer } from "./RegisterForm/RegisterFormContainer"

export const RegisterPage: FC = () => {
	return (
		<ContainerWrapper>
			<Center height={"100vh"}>
				<RegisterFormContainer />
			</Center>
		</ContainerWrapper>
	)
}
