import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { useAuthContext } from "src/components/context/auth"
import { EItemType } from "src/components/entries/EItemType"
import { IJobWorkItemSlipV2 } from "src/domain/entities"
import { formatDate, toINR, toKGs } from "src/utils/helpers"

interface Props {
	slip?: IJobWorkItemSlipV2
	type?: EItemType
	mode?: "single" | "dual"
}

const demoSlip = {
	id: "340fedb1-9934-4c7d-8158-78168ee1b276",
	issueDate: 1644015600000,
	prefix: "SL",
	slipNo: 96,
	grossQuantity: 20,
	bagQuantity: 0,
	rejectedQuantity: 0,
	netQuantity: 20,
	note: "",
	createdAt: 1644037096441,
	modifiedAt: 1644037096441,
	jobWorkItem: {
		id: "9e481c0c-a72a-4477-97c9-2635f25d47f2",
		marketRate: 0,
		workRate: 40,
		lossPercentage: 5,
		isLossInclusive: true,
		includeMarketRate: false,
		quantity: 70,
		inclusiveLossQuantity: 3.5,
		exclusiveLossQuantity: 0,
		amount: 2800,
		createdAt: 1644037096395,
		modifiedAt: 1644037096395,
		product: {
			id: "09adb17a-174a-42ba-9b79-e98564043b9b",
			name: "Brass Butt Hinges 3x12 mm",
		},
		slips: [
			{
				id: "5ac4bd72-1e18-4bde-8f39-dbecf91260ef",
				issueDate: 1644015600000,
				prefix: "SL",
				slipNo: 99,
				grossQuantity: 20,
				bagQuantity: 0,
				rejectedQuantity: 0,
				netQuantity: 20,
				note: "",
				createdAt: 1644047134612,
				modifiedAt: 1644047134612,
			},
			{
				id: "9ae2ac11-4523-4786-bcf1-aac8c8670ab9",
				issueDate: 1644015600000,
				prefix: "SL",
				slipNo: 97,
				grossQuantity: 30,
				bagQuantity: 0,
				rejectedQuantity: 0,
				netQuantity: 30,
				note: "",
				createdAt: 1644037129818,
				modifiedAt: 1644037129818,
			},
			{
				id: "340fedb1-9934-4c7d-8158-78168ee1b276",
				issueDate: 1644015600000,
				prefix: "SL",
				slipNo: 96,
				grossQuantity: 20,
				bagQuantity: 0,
				rejectedQuantity: 0,
				netQuantity: 20,
				note: "",
				createdAt: 1644037096441,
				modifiedAt: 1644037096441,
			},
		],
	},
	subParty: {
		id: "adc0c7ef-3c13-4eb3-90c9-2b7a48615103",
		name: "Krishna Brass",
		isDefault: true,
		isArchived: false,
	},
}

export const SlipPrintView: FC<Props> = (props) => {
	if (props.mode === "dual") {
		return (
			<>
				<Box display={"inline-block"} width={"48%"}>
					<SlipPrintViewView {...props} />
				</Box>
				<Box display={"inline-block"} width={"4%"}></Box>
				<Box display={"inline-block"} width={"48%"}>
					<SlipPrintViewView {...props} />
				</Box>
			</>
		)
	}

	return <SlipPrintViewView {...props} />
}

export const SlipPrintViewView: FC<Props> = ({ slip, type = EItemType.INWARD }) => {
	if (!slip) slip = demoSlip
	const { company } = useAuthContext()

	let fromSubPartyName = "",
		toSubPartyName = ""

	if (type === EItemType.INWARD) {
		fromSubPartyName = slip.subParty?.name ?? ""
		toSubPartyName = company?.name ?? ""
	} else {
		fromSubPartyName = company?.name ?? ""
		toSubPartyName = slip.subParty?.name ?? ""
	}

	if (!slip) return null

	let burningLoss = 0

	if (slip.jobWorkItem) {
		burningLoss = (slip.netQuantity * slip.jobWorkItem.lossPercentage) / 100

		if (slip.jobWorkItem.isLossInclusive) {
			burningLoss = -burningLoss
		}
	}

	return (
		<Box mt={4}>
			{/* Slip Header */}
			<Box>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Box fontSize={14} lineHeight={1}>
								From
							</Box>
							<Box fontWeight={"bold"}>{fromSubPartyName}</Box>
						</Box>
						<Box marginTop={4}>
							<Box fontSize={14} lineHeight={1}>
								To
							</Box>
							<Box fontWeight={"bold"}>{toSubPartyName}</Box>
						</Box>
					</Box>
					<Box
						borderWidth={1}
						borderColor={"black"}
						textAlign="center"
						height="fit-content"
					>
						<Box padding={2}>
							<Box fontSize={14} lineHeight={1}>
								Slip No
							</Box>
							<Box
								fontWeight={"bold"}
								fontSize={"xl"}
								lineHeight={1}
								mt={1}
								whiteSpace="nowrap"
							>
								{slip.prefix}-{slip.slipNo}
							</Box>
						</Box>
						<Box
							borderTopWidth={1}
							borderTopColor={"gray.500"}
							padding={1}
							paddingX={2}
							whiteSpace="nowrap"
						>
							{formatDate(slip.issueDate)}
						</Box>
					</Box>
				</Flex>
			</Box>
			{/* Item Details */}
			<Box mt={10}>
				<Box>
					<Box lineHeight={1}>Item Name</Box>
					<Box fontSize={"lg"} fontWeight={"semibold"}>
						{slip.jobWorkItem?.product?.name}
					</Box>
				</Box>
				{/* Item Details Table */}
				<Box mt={4}>
					<Table className="slipTable">
						<Tbody>
							<Tr>
								<Td padding={2}>
									<Box lineHeight={1}>Gross Quantity</Box>
								</Td>
								<Td padding={2} isNumeric>
									<Box>{toKGs(slip.grossQuantity)}</Box>
								</Td>
							</Tr>
							{slip.bagQuantity ? (
								<Tr>
									<Td padding={2}>
										<Box lineHeight={1}>Bag Quantity</Box>
									</Td>
									<Td padding={2} isNumeric>
										<Box>{toKGs(slip.bagQuantity)}</Box>
									</Td>
								</Tr>
							) : null}
							{slip.rejectedQuantity ? (
								<>
									<Tr>
										<Td padding={2}>
											<Box lineHeight={1}>Rejected Quantity</Box>
										</Td>
										<Td padding={2} isNumeric>
											<Box>{toKGs(slip.rejectedQuantity)}</Box>
										</Td>
									</Tr>
									<Tr>
										<Td padding={2}>
											<Box lineHeight={1}>
												Quantity after Rejection
											</Box>
										</Td>
										<Td padding={2} isNumeric>
											<Box>{toKGs(slip.netQuantity)}</Box>
										</Td>
									</Tr>
								</>
							) : null}
							{/* Burning Loss */}
							{slip.jobWorkItem && slip.jobWorkItem.lossPercentage ? (
								<>
									<Tr>
										<Td padding={2}>
											<Box lineHeight={1}>Burning Loss</Box>
										</Td>
										<Td padding={2} isNumeric>
											<Box>{toKGs(burningLoss)}</Box>
										</Td>
									</Tr>

									<Tr>
										<Td padding={2}>
											<Box lineHeight={1}>Quantity after Loss</Box>
										</Td>
										<Td padding={2} isNumeric>
											<Box>
												{toKGs(slip.netQuantity + burningLoss)}
											</Box>
										</Td>
									</Tr>
								</>
							) : null}
						</Tbody>
					</Table>
				</Box>
				{/* Summary */}

				<Box mt={4}>
					<Flex>
						<Box flex={2}></Box>
						<Box flex={3}>
							<Table className="slipTable">
								<Thead>
									<Tr>
										<Th padding={2} textAlign={"center"} colSpan={2}>
											Summary
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td padding={2}>
											<Box lineHeight={1}>Total Quantity</Box>
										</Td>
										<Td
											padding={2}
											isNumeric
											fontWeight={
												slip.jobWorkItem?.workRate
													? "normal"
													: "semibold"
											}
										>
											<Box>{toKGs(slip.netQuantity)}</Box>
										</Td>
									</Tr>
									{slip.jobWorkItem && slip.jobWorkItem.workRate ? (
										<>
											<Tr>
												<Td padding={2}>
													<Box lineHeight={1}>Labour Rate</Box>
												</Td>
												<Td padding={2} isNumeric>
													<Box>
														{toINR(slip.jobWorkItem.workRate)}
													</Box>
												</Td>
											</Tr>
											<Tr>
												<Td padding={2}>
													<Box lineHeight={1}>Total</Box>
												</Td>
												<Td
													fontWeight={"semibold"}
													padding={2}
													isNumeric
												>
													<Box>
														{toINR(
															slip.netQuantity *
																slip.jobWorkItem.workRate,
														)}
													</Box>
												</Td>
											</Tr>
										</>
									) : null}
								</Tbody>
							</Table>
						</Box>
					</Flex>
				</Box>

				{slip.note && (
					<Box marginTop={10}>
						<Box>
							<Box lineHeight={1} fontWeight="semibold">
								Remarks
							</Box>
							<Box>{slip.note}</Box>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	)
}
