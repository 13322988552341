import {
	Badge,
	Box,
	Button,
	Flex,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC, useCallback, useRef } from "react"
import { BsArrowLeftRight, BsFileEarmark, BsFiles } from "react-icons/bs"
import ReactToPrint from "react-to-print"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { PrintIconButton } from "src/components/ui/iconButtons/PrintIconButton"
import {
	IBillV2,
	ICashReceiptV2,
	IJobWorkBillV2,
	IJobWorkItemSlipV2,
	IJobWorkItemV2,
	IPartyMonthStatementV3,
	IPartyMonthV1,
	ISubPartyV1,
} from "src/domain/entities"
import { formatDate, toINR, toKGs } from "src/utils/helpers"
import { EItemType } from "../../../entries/EItemType"
import { SlipPrintView } from "./SlipPrintView"

interface Props {
	selectedSubParty?: ISubPartyV1
	partyMonth: IPartyMonthV1
	statement: IPartyMonthStatementV3
	onItemSlipTransferClick: (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		jobWorkItem: IJobWorkItemV2,
	) => void
	onItemSlipEditClick: (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		jobWorkItem: IJobWorkItemV2,
	) => void
	onItemSlipDeleteClick: (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		jobWorkItem: IJobWorkItemV2,
	) => void
	onCashReceiptEditClick: (receipt: ICashReceiptV2, type: EItemType) => void
	onCashReceiptDeleteClick: (receipt: ICashReceiptV2, type: EItemType) => void
	onJobWorkBillEditClick: (bill: IJobWorkBillV2, type: EItemType) => void
	onJobWorkBillDeleteClick: (bill: IJobWorkBillV2, type: EItemType) => void
	onBillEditClick: (bill: IBillV2, type: EItemType) => void
	onBillDeleteClick: (bill: IBillV2, type: EItemType) => void
}

interface DateSpecificEntry {
	date: number
	subParty?: ISubPartyV1
	subPartyName?: string
	inwardItemSlips: IJobWorkItemSlipV2[]
	outwardItemSlips: IJobWorkItemSlipV2[]
	inwardCashReceipts: ICashReceiptV2[]
	outwardCashReceipts: ICashReceiptV2[]
	inwardJobWorkBills: IJobWorkBillV2[]
	outwardJobWorkBills: IJobWorkBillV2[]
	saleBills: IBillV2[]
	purchaseBills: IBillV2[]
}

export const PartyMonthSlipListView: FC<Props> = ({
	statement,
	selectedSubParty,
	onItemSlipTransferClick,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
	onCashReceiptEditClick,
	onCashReceiptDeleteClick,
	onJobWorkBillEditClick,
	onJobWorkBillDeleteClick,
	onBillEditClick,
	onBillDeleteClick,
}) => {
	let subPartyMonthStatementList = statement.subPartyMonthStatementList
	let toShowTable = true
	let entryList: DateSpecificEntry[] = []

	if (selectedSubParty) {
		subPartyMonthStatementList = subPartyMonthStatementList.filter(
			(el) => el.subParty.id === selectedSubParty.id,
		)
		if (!subPartyMonthStatementList[0]) {
			toShowTable = false
		}
	}

	subPartyMonthStatementList.forEach((spmStatement) => {
		spmStatement.inJobWorkItemList?.forEach((inwardItem) => {
			inwardItem.slips?.forEach((slip) => {
				const entry = entryList.find((entryEl) => entryEl.date === slip.issueDate)
				if (!entry) {
					entryList.push({
						date: slip.issueDate,
						subParty: spmStatement.subParty,
						subPartyName: !spmStatement.subParty.isDefault
							? spmStatement.subParty.name
							: undefined,
						inwardItemSlips: [{ ...slip, jobWorkItem: inwardItem }],
						outwardItemSlips: [],
						inwardCashReceipts: [],
						outwardCashReceipts: [],
						inwardJobWorkBills: [],
						outwardJobWorkBills: [],
						saleBills: [],
						purchaseBills: [],
					})
				} else {
					entry.inwardItemSlips.push({ ...slip, jobWorkItem: inwardItem })
				}
			})
		})
		spmStatement.outJobWorkItemList?.forEach((outwardItem) => {
			outwardItem.slips?.forEach((slip) => {
				const entry = entryList.find((entryEl) => entryEl.date === slip.issueDate)
				if (!entry) {
					entryList.push({
						date: slip.issueDate,
						subParty: spmStatement.subParty,
						subPartyName: !spmStatement.subParty.isDefault
							? spmStatement.subParty.name
							: undefined,
						inwardItemSlips: [],
						outwardItemSlips: [{ ...slip, jobWorkItem: outwardItem }],

						inwardCashReceipts: [],
						outwardCashReceipts: [],
						inwardJobWorkBills: [],
						outwardJobWorkBills: [],
						saleBills: [],
						purchaseBills: [],
					})
				} else {
					entry.outwardItemSlips.push({ ...slip, jobWorkItem: outwardItem })
				}
			})
		})
		spmStatement.inJobWorkBillList?.forEach((inwardJobWorkBill) => {
			const entry = entryList.find(
				(entryEl) => entryEl.date === inwardJobWorkBill.issueDate,
			)
			if (!entry) {
				entryList.push({
					date: inwardJobWorkBill.issueDate,
					subPartyName: !spmStatement.subParty.isDefault
						? spmStatement.subParty.name
						: undefined,
					inwardItemSlips: [],
					outwardItemSlips: [],

					inwardCashReceipts: [],
					outwardCashReceipts: [],
					inwardJobWorkBills: [inwardJobWorkBill],
					outwardJobWorkBills: [],
					saleBills: [],
					purchaseBills: [],
				})
			} else {
				entry.inwardJobWorkBills.push(inwardJobWorkBill)
			}
		})
		spmStatement.outJobWorkBillList?.forEach((outwardJobWorkBill) => {
			const entry = entryList.find(
				(entryEl) => entryEl.date === outwardJobWorkBill.issueDate,
			)
			if (!entry) {
				entryList.push({
					date: outwardJobWorkBill.issueDate,
					subPartyName: !spmStatement.subParty.isDefault
						? spmStatement.subParty.name
						: undefined,
					inwardItemSlips: [],
					outwardItemSlips: [],

					inwardCashReceipts: [],
					outwardCashReceipts: [],
					inwardJobWorkBills: [],
					outwardJobWorkBills: [outwardJobWorkBill],
					saleBills: [],
					purchaseBills: [],
				})
			} else {
				entry.outwardJobWorkBills.push(outwardJobWorkBill)
			}
		})
		spmStatement.saleBillList?.forEach((saleBill) => {
			const entry = entryList.find((entryEl) => entryEl.date === saleBill.issueDate)
			if (!entry) {
				entryList.push({
					date: saleBill.issueDate,
					subPartyName: !spmStatement.subParty.isDefault
						? spmStatement.subParty.name
						: undefined,
					inwardItemSlips: [],
					outwardItemSlips: [],

					inwardCashReceipts: [],
					outwardCashReceipts: [],
					inwardJobWorkBills: [],
					outwardJobWorkBills: [],
					saleBills: [saleBill],
					purchaseBills: [],
				})
			} else {
				entry.saleBills.push(saleBill)
			}
		})
		spmStatement.purchaseBillList?.forEach((purchaseBill) => {
			const entry = entryList.find(
				(entryEl) => entryEl.date === purchaseBill.issueDate,
			)
			if (!entry) {
				entryList.push({
					date: purchaseBill.issueDate,
					subPartyName: !spmStatement.subParty.isDefault
						? spmStatement.subParty.name
						: undefined,
					inwardItemSlips: [],
					outwardItemSlips: [],

					inwardCashReceipts: [],
					outwardCashReceipts: [],
					inwardJobWorkBills: [],
					outwardJobWorkBills: [],
					saleBills: [],
					purchaseBills: [purchaseBill],
				})
			} else {
				entry.purchaseBills.push(purchaseBill)
			}
		})
	})

	statement.inCashList?.forEach((inwardCashReceipt) => {
		const entry = entryList.find(
			(entryEl) => entryEl.date === inwardCashReceipt.issueDate,
		)
		if (!entry) {
			entryList.push({
				date: inwardCashReceipt.issueDate,
				inwardItemSlips: [],
				outwardItemSlips: [],
				inwardCashReceipts: [inwardCashReceipt],
				outwardCashReceipts: [],
				inwardJobWorkBills: [],
				outwardJobWorkBills: [],
				saleBills: [],
				purchaseBills: [],
			})
		} else {
			entry.inwardCashReceipts.push(inwardCashReceipt)
		}
	})

	statement.outCashList?.forEach((outwardCashReceipt) => {
		const entry = entryList.find(
			(entryEl) => entryEl.date === outwardCashReceipt.issueDate,
		)
		if (!entry) {
			entryList.push({
				date: outwardCashReceipt.issueDate,
				inwardItemSlips: [],
				outwardItemSlips: [],

				inwardCashReceipts: [],
				outwardCashReceipts: [outwardCashReceipt],
				inwardJobWorkBills: [],
				outwardJobWorkBills: [],
				saleBills: [],
				purchaseBills: [],
			})
		} else {
			entry.outwardCashReceipts.push(outwardCashReceipt)
		}
	})

	// sort by date
	entryList.sort((a, b) => {
		return a.date - b.date
	})

	return (
		<Box className="slip-list-view">
			{toShowTable && (
				<Box border="1px" borderColor="gray.300" borderRadius="lg">
					<Table variant="simple" size="sm">
						<TableHead />
						<Tbody>
							{entryList.map((entry) => {
								let slipRows: JSX.Element[] = []
								slipRows = [
									...slipRows,
									...entry.inwardItemSlips.map((slip) => (
										<SlipRow
											key={slip.id}
											slip={slip}
											type={EItemType.INWARD}
											subParty={entry.subParty}
											partyName={entry.subPartyName}
											onItemSlipTransferClick={
												onItemSlipTransferClick
											}
											onItemSlipEditClick={onItemSlipEditClick}
											onItemSlipDeleteClick={onItemSlipDeleteClick}
										/>
									)),
									...entry.outwardItemSlips.map((slip) => (
										<SlipRow
											key={slip.id}
											slip={slip}
											type={EItemType.OUTWARD}
											subParty={entry.subParty}
											partyName={entry.subPartyName}
											onItemSlipTransferClick={
												onItemSlipTransferClick
											}
											onItemSlipEditClick={onItemSlipEditClick}
											onItemSlipDeleteClick={onItemSlipDeleteClick}
										/>
									)),
									...entry.inwardCashReceipts.map((receipt) => (
										<CashReceiptRow
											key={receipt.id}
											type={EItemType.INWARD_CASH}
											receipt={receipt}
											onCashReceiptEditClick={() => {
												onCashReceiptEditClick(
													receipt,
													EItemType.INWARD_CASH,
												)
											}}
											onCashReceiptDeleteClick={() => {
												onCashReceiptDeleteClick(
													receipt,
													EItemType.INWARD_CASH,
												)
											}}
										/>
									)),
									...entry.outwardCashReceipts.map((receipt) => (
										<CashReceiptRow
											key={receipt.id}
											type={EItemType.OUTWARD_CASH}
											receipt={receipt}
											onCashReceiptEditClick={() => {
												onCashReceiptEditClick(
													receipt,
													EItemType.OUTWARD_CASH,
												)
											}}
											onCashReceiptDeleteClick={() => {
												onCashReceiptDeleteClick(
													receipt,
													EItemType.OUTWARD_CASH,
												)
											}}
										/>
									)),
									...entry.inwardJobWorkBills.map((bill) => (
										<InOutJobWorkBillRow
											key={bill.id}
											type="inward"
											bill={bill}
											onItemSlipEditClick={() => {
												onJobWorkBillEditClick(
													bill,
													EItemType.INWARD_JOBWORK_BILL,
												)
											}}
											onItemSlipDeleteClick={() => {
												onJobWorkBillDeleteClick(
													bill,
													EItemType.INWARD_JOBWORK_BILL,
												)
											}}
										/>
									)),
									...entry.outwardJobWorkBills.map((bill) => (
										<InOutJobWorkBillRow
											key={bill.id}
											type="outward"
											bill={bill}
											onItemSlipEditClick={() => {
												onJobWorkBillEditClick(
													bill,
													EItemType.OUTWARD_JOBWORK_BILL,
												)
											}}
											onItemSlipDeleteClick={() => {
												onJobWorkBillDeleteClick(
													bill,
													EItemType.OUTWARD_JOBWORK_BILL,
												)
											}}
										/>
									)),
									...entry.saleBills.map((bill) => (
										<SalePurchaseBillRow
											key={bill.id}
											type={EItemType.SALE_BILL}
											bill={bill}
											onItemSlipEditClick={onBillEditClick}
											onItemSlipDeleteClick={onBillDeleteClick}
										/>
									)),
									...entry.purchaseBills.map((bill) => (
										<SalePurchaseBillRow
											key={bill.id}
											type={EItemType.PURCHASE_BILL}
											bill={bill}
											onItemSlipEditClick={onBillEditClick}
											onItemSlipDeleteClick={onBillDeleteClick}
										/>
									)),
								]

								return slipRows
							})}
						</Tbody>
					</Table>
				</Box>
			)}
		</Box>
	)
}

const TableHead: FC = () => (
	<Thead>
		<Tr>
			<Th paddingY="2">Issue Date</Th>
			<Th paddingY="2">Slip No.</Th>
			<Th paddingY="2">Particulars</Th>
			<Th paddingY="2" isNumeric>
				Rate
			</Th>
			<Th paddingY="2" isNumeric>
				Quantity
			</Th>
			<Th paddingY="2" isNumeric>
				Amount
			</Th>
			<Th paddingY="2" isNumeric className="print-hidden">
				Actions
			</Th>
		</Tr>
	</Thead>
)

interface SlipRowProps {
	slip: IJobWorkItemSlipV2
	type: EItemType
	subParty?: ISubPartyV1
	partyName?: string
	onItemSlipTransferClick?: Props["onItemSlipTransferClick"]
	onItemSlipEditClick?: Props["onItemSlipEditClick"]
	onItemSlipDeleteClick?: Props["onItemSlipDeleteClick"]
}

const SlipRow: FC<SlipRowProps> = ({
	type,
	slip,
	subParty,
	partyName,
	onItemSlipTransferClick,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	let typeLabel: string | JSX.Element = "SLIP"

	if (type === EItemType.INWARD) {
		typeLabel = <Badge colorScheme="green">IN</Badge>
	} else if (type === EItemType.OUTWARD) {
		typeLabel = <Badge colorScheme="red">OUT</Badge>
	}

	const printComponentRef = useRef<HTMLDivElement>(null)
	const dualPrintComponentRef = useRef<HTMLDivElement>(null)

	const reactToPrintContent = useCallback(() => {
		return printComponentRef.current
	}, [])

	const reactToDualPrintContent = useCallback(() => {
		return dualPrintComponentRef.current
	}, [])

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				body {
					padding: 0.5cm;
				}
				table.slipTable {
					border: solid #000 !important;
					border-width: 1px 0 0 1px !important;
				}
				table.slipTable th, table.slipTable td {
					border: solid #000 !important;
					border-width: 0 1px 1px 0 !important;
				}
			}
		`
	}

	return (
		<>
			<Tr key={slip.id}>
				<Td whiteSpace={"nowrap"}>{formatDate(slip.issueDate)}</Td>
				<Td>
					<Flex alignItems="center">
						<Box whiteSpace={"nowrap"}>{slip.prefix + "-" + slip.slipNo}</Box>
						<Box marginLeft={2}>{typeLabel}</Box>
					</Flex>
				</Td>
				<Td>
					<Box whiteSpace={"nowrap"}>
						{partyName && <Box marginTop={1}>{partyName}</Box>}
					</Box>
					<Box fontSize="md" fontWeight="semibold" marginTop={1}>
						{slip.jobWorkItem?.product?.name}
					</Box>
					{slip.note && <Box marginTop={1}>{slip.note}</Box>}
				</Td>
				<Td isNumeric>
					{toINR(slip.jobWorkItem?.workRate ?? 0, { dashIfZero: true })}
				</Td>
				<Td isNumeric>{toKGs(slip.netQuantity, { dashIfZero: true })}</Td>
				<Td isNumeric>
					{toINR(slip.netQuantity * (slip.jobWorkItem?.workRate ?? 0), {
						dashIfZero: true,
					})}
				</Td>
				<Td isNumeric className="print-hidden">
					<Box ref={printComponentRef}>
						<style>{getPageStyles()}</style>
						<div className="printable">
							<div style={{ pageBreakBefore: "always" }}>
								<SlipPrintView
									slip={{ ...slip, subParty: subParty }}
									type={type}
								/>
							</div>
						</div>
					</Box>
					<Box ref={dualPrintComponentRef}>
						<style>{getPageStyles()}</style>
						<div className="printable">
							<div style={{ pageBreakBefore: "always" }}>
								<SlipPrintView
									slip={{ ...slip, subParty: subParty }}
									type={type}
									mode="dual"
								/>
							</div>
						</div>
					</Box>
					<Popover placement="bottom-start">
						<PopoverTrigger>
							<Box display={"inline-block"}>
								<PrintIconButton />
							</Box>
						</PopoverTrigger>
						<PopoverContent maxW={"fit-content"} _focus={{ outline: "none" }}>
							<PopoverBody>
								<Flex>
									<ReactToPrint
										content={reactToPrintContent}
										copyStyles={true}
										trigger={() => (
											<Button
												aria-label="edit"
												variant="ghost"
												leftIcon={<BsFileEarmark />}
												size={"sm"}
												mx="1"
												colorScheme={"blue"}
											>
												Single
											</Button>
										)}
									/>
									<ReactToPrint
										content={reactToDualPrintContent}
										copyStyles={true}
										trigger={() => (
											<Button
												aria-label="edit"
												variant="ghost"
												leftIcon={<BsFiles />}
												size={"sm"}
												mx="1"
												colorScheme={"blue"}
											>
												Dual
											</Button>
										)}
									/>
								</Flex>
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<IconButton
						aria-label="transfer"
						variant="ghost"
						icon={<BsArrowLeftRight />}
						size={"sm"}
						mx="1"
						colorScheme={"purple"}
						onClick={() =>
							onItemSlipTransferClick?.(slip, type, slip.jobWorkItem!)
						}
					/>
					<EditIconButton
						onClick={() =>
							onItemSlipEditClick?.(slip, type, slip.jobWorkItem!)
						}
					/>
					<DeleteIconButton
						onClick={() =>
							onItemSlipDeleteClick?.(slip, type, slip.jobWorkItem!)
						}
					/>
				</Td>
			</Tr>
		</>
	)
}

interface InOutJobWorkBillRowProps {
	bill: IJobWorkBillV2
	type: "inward" | "outward"
	partyName?: string
	onItemSlipEditClick?: Props["onJobWorkBillEditClick"]
	onItemSlipDeleteClick?: Props["onJobWorkBillDeleteClick"]
}

const InOutJobWorkBillRow: FC<InOutJobWorkBillRowProps> = ({
	type,
	bill,
	partyName,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	let typeLabel: string | JSX.Element = "CHALLAN"

	if (type === "inward") {
		typeLabel = <Badge colorScheme="red">Delivery Challan</Badge>
	} else if (type === "outward") {
		typeLabel = <Badge colorScheme="green">Received Challan</Badge>
	}

	return (
		<Tr key={bill.id}>
			<Td>{formatDate(bill.issueDate)}</Td>
			<Td>
				<Flex alignItems="center">
					<Box>{bill.fullBillNo}</Box>
					<Box marginLeft={2}>{typeLabel}</Box>
				</Flex>
			</Td>
			<Td>
				<Box>{partyName && <Box marginTop={1}>{partyName}</Box>}</Box>
				<Box fontSize="md" fontWeight="semibold" marginTop={1}>
					{bill.product?.name}
				</Box>
				<Box marginTop={1}>{bill.note}</Box>
			</Td>
			<Td isNumeric>{toINR(bill.workRate, { dashIfZero: true })}</Td>
			<Td isNumeric>{toKGs(bill.quantity, { dashIfZero: true })}</Td>
			<Td isNumeric>
				{toINR(bill.quantity * bill.workRate, { dashIfZero: true })}
			</Td>
			<Td isNumeric className="print-hidden">
				<EditIconButton onClick={onItemSlipEditClick} />
				<DeleteIconButton onClick={onItemSlipDeleteClick} />
			</Td>
		</Tr>
	)
}

interface CashReceiptRowProps {
	receipt: ICashReceiptV2
	type: EItemType
	onCashReceiptEditClick?: Props["onCashReceiptEditClick"]
	onCashReceiptDeleteClick?: Props["onCashReceiptDeleteClick"]
}

const CashReceiptRow: FC<CashReceiptRowProps> = ({
	type,
	receipt,
	onCashReceiptEditClick,
	onCashReceiptDeleteClick,
}) => {
	let typeLabel: string | JSX.Element = "RECEIPT"

	if (type === EItemType.INWARD_CASH) {
		typeLabel = <Badge colorScheme="green">CASH IN</Badge>
	} else if (type === EItemType.OUTWARD_CASH) {
		typeLabel = <Badge colorScheme="red">CASH OUT</Badge>
	}

	return (
		<Tr key={receipt.id}>
			<Td>{formatDate(receipt.issueDate)}</Td>
			<Td>{typeLabel}</Td>
			<Td>{receipt.note && <Box marginTop={1}>{receipt.note}</Box>}</Td>
			<Td isNumeric>-</Td>
			<Td isNumeric>-</Td>
			<Td isNumeric>{toINR(receipt.amount, { dashIfZero: true })}</Td>
			<Td isNumeric className="print-hidden">
				<EditIconButton onClick={onCashReceiptEditClick} />
				<DeleteIconButton onClick={onCashReceiptDeleteClick} />
			</Td>
		</Tr>
	)
}

interface SalePurchaseBillRowProps {
	bill: IBillV2 | IBillV2
	type: EItemType
	partyName?: string
	onItemSlipEditClick: Props["onBillEditClick"]
	onItemSlipDeleteClick?: Props["onBillDeleteClick"]
}

const SalePurchaseBillRow: FC<SalePurchaseBillRowProps> = ({
	type,
	bill,
	partyName,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	let typeLabel: string | JSX.Element = "BILL"

	if (type === EItemType.SALE_BILL) {
		typeLabel = <Badge colorScheme="green">Sale Bill</Badge>
	} else if (type === EItemType.PURCHASE_BILL) {
		typeLabel = <Badge colorScheme="red">Purchase Bill</Badge>
	}

	return (
		<Tr key={bill.id}>
			<Td>{formatDate(bill.issueDate)}</Td>
			<Td>
				<Flex alignItems="center">
					<Box>{bill.fullBillNo}</Box>
					<Box marginLeft={2}>{typeLabel}</Box>
				</Flex>
			</Td>
			<Td>
				<Box>{partyName && <Box marginTop={1}>{partyName}</Box>}</Box>
				<Box fontSize="md" fontWeight="semibold" marginTop={1}>
					{bill.product?.name}
				</Box>
				<Box marginTop={1}>{bill.note}</Box>
			</Td>
			<Td isNumeric>
				<Box>{toINR(bill.workRate, { dashIfZero: true })}</Box>
				<Box>{toINR(bill.saleRate, { dashIfZero: true })}</Box>
			</Td>
			<Td isNumeric>{toKGs(bill.quantity, { dashIfZero: true })}</Td>
			<Td isNumeric>
				{toINR(bill.quantity * (bill.workRate + bill.saleRate), {
					dashIfZero: true,
				})}
			</Td>
			<Td isNumeric className="print-hidden">
				<EditIconButton onClick={() => onItemSlipEditClick?.(bill, type)} />
				<DeleteIconButton onClick={() => onItemSlipDeleteClick?.(bill, type)} />
			</Td>
		</Tr>
	)
}
