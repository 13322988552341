import { ApiRequester } from "../ApiRequester"
import { IPagination, IPartyMonthV1 } from "./../../entities"

interface RequestDataShape {
	filter: {
		id?: string
		partyId?: string
		year?: number
		month?: number
	} & IPagination
	include?: { stats?: boolean; party?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: IPartyMonthV1[]
}

export async function partyMonthListApi(payload: RequestDataShape, token: string | null) {
	const endpoint = "/party/month/list/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
