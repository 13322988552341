import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	mobileNo: string
	otp: number
}

interface ResponseDataShape {
	success: boolean
	data: { token: string }
}

export async function userMobileVerifyApi(payload: RequestDataShape) {
	const endpoint = "/user/mobile/verify/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload)
	return response.data
}
