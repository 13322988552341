import { AddIcon } from "@chakra-ui/icons"
import { IconButton, useDisclosure } from "@chakra-ui/react"
import { FC } from "react"
import { IProductV1 } from "src/domain/entities"
import { ProductAddDrawerFormController } from "../pages/ProductPage/ProductAddDrawerForm"

export const ProductAddButton: FC<{ onSuccess: (product: IProductV1) => void }> = ({
	onSuccess,
}) => {
	const productAddDrawerDisclosure = useDisclosure()

	return (
		<>
			<IconButton
				aria-label="add-product"
				icon={<AddIcon />}
				onClick={productAddDrawerDisclosure.onOpen}
				title="Add Product"
			/>
			{productAddDrawerDisclosure.isOpen && (
				<ProductAddDrawerFormController
					{...productAddDrawerDisclosure}
					onSuccess={onSuccess}
				/>
			)}
		</>
	)
}

export const getProductOption = (productId: string, productList?: IProductV1[]) => {
	if (!productList) return undefined
	const product = productList.find((product) => product.id === productId)
	if (!product) return undefined

	return {
		label: product.name,
		value: product.id,
	}
}
