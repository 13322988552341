import { ComponentProps, FC, useCallback } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { EJobWorkItemType } from "src/domain/api/jobWorkItem/EJobWorkItemType"
import { IJobWorkItemSlipV2 } from "src/domain/entities"
import { jobWorkItemSlipUpdateService } from "src/domain/services/jobWorkItem/slip/jobWorkItemSlipUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IItemSlipUpdateFormFields, ItemSlipUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof ItemSlipUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (itemSlip: IJobWorkItemSlipV2) => void
}

export const ItemSlipUpdateDrawerFormController: FC<Props> = ({
	onSuccess,
	type,
	slip,
	jobWorkItem,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IItemSlipUpdateFormFields["update"]>>(
		async (values, { setErrors }) => {
			try {
				const updatedItemSlip = await jobWorkItemSlipUpdateService(
					{
						filter: {
							id: slip.id,
							jobWorkItemId: jobWorkItem.id ?? "",
							itemType:
								type === EItemType.INWARD
									? EJobWorkItemType.IN
									: EJobWorkItemType.OUT,
						},
						update: values,
					},
					token,
				)
				onSuccess && onSuccess(updatedItemSlip)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, type, slip, jobWorkItem],
	)

	return (
		<ItemSlipUpdateDrawerFormView
			type={type}
			slip={slip}
			jobWorkItem={jobWorkItem}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
