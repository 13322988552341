import { Box, Flex } from "@chakra-ui/react"
import { ResponsiveLine } from "@nivo/line"
import { FC, useCallback, useEffect, useState } from "react"
import { ICompanyMonthV2 } from "src/domain/entities"
import { companyMonthListService } from "src/domain/services/company/companyMonthListServie"
import { userSelfGetService } from "src/domain/services/user/userSelfGetService"
import { useAuth } from "src/hooks"
import { monthName, toINR, toKGs } from "src/utils/helpers"
import { useAuthContext } from "../context/auth"
import { CenteredSpinner } from "../shared/CenteredSpinner"
import { SlipAddButton } from "../shared/SlipAddButton"
import { SlipAddDrawers } from "../shared/SlipAddDrawers"
import { TabMenu } from "../shared/TabMenu"
import { DashboardWrapper } from "../wrappers"

export const HomePage: FC = () => {
	// const { token } = useAuth()

	// const handleStatsRefresh = useCallback(async () => {
	// 	await companyStatsRefreshApi({}, token)
	// }, [token])

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Box padding={2} borderBottom="1px" borderBottomColor="gray.300">
					<Flex justify={"space-between"}>
						<Box>
							<Box fontSize="2xl" fontWeight={"bold"} lineHeight={"1.2"}>
								Dashboard
							</Box>
							<Box color="gray.600">See overview of your company here.</Box>
						</Box>
						<Box>
							{/* <Button
								onClick={handleStatsRefresh}
								variantColor="teal"
								size="sm"
							>
								Refresh Stats
							</Button> */}
							<SlipAddButton />
						</Box>
					</Flex>
				</Box>
				<Box padding={2}>
					<CompanyOverViewHeaderCards />
					<DashboardChart />
				</Box>
			</Box>
			<SlipAddDrawers />
		</DashboardWrapper>
	)
}

const CompanyOverViewHeaderCards: FC = () => {
	const {
		company: { stats: companyStats },
		token,
	} = useAuth()

	const { setCompany } = useAuthContext()
	const fetchCompanyDetails = useCallback(async () => {
		const { company } = await userSelfGetService(token)
		setCompany(company)
	}, [token, setCompany])

	useEffect(() => {
		fetchCompanyDetails()
	}, [fetchCompanyDetails])

	if (!companyStats) return null

	return (
		<Flex>
			<StatCard
				title="Available Stock (KG)"
				value={toKGs(companyStats.closingStockQuantity)}
			/>
			<StatCard
				title={"Receivable Quantity (KG)"}
				value={toKGs(companyStats.closingReceivableQuantity)}
			/>
			<StatCard
				title={"Payable Quantity (KG)"}
				value={toKGs(companyStats.closingPayableQuantity)}
			/>
			<StatCard
				title="Receivable Amount"
				value={toINR(companyStats.closingReceivableAmount)}
			/>
			<StatCard
				title="Payable Amount"
				value={toINR(companyStats.closingPayableAmount)}
			/>
		</Flex>
	)
}

const StatCard: FC<{
	title: string
	value: string | number | JSX.Element
}> = ({ title, value }) => {
	let titleColor = "gray.900"

	return (
		<Box
			flex={1}
			padding={2}
			paddingY={4}
			backgroundColor="gray.100"
			borderRadius="lg"
			textAlign="center"
			marginX={2}
			color={titleColor}
		>
			<Box fontSize="sm" fontWeight={"semibold"} color="gray.700">
				{title}
			</Box>
			<Box fontSize="2xl">{value}</Box>
		</Box>
	)
}

const DashboardChart: FC = () => {
	const { token } = useAuth()

	const [companyMonthList, setCompanyMonthList] = useState<ICompanyMonthV2[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const [chartViewType, setChartViewType] = useState<string>("quantity")

	const fetchDashboardChartData = useCallback(async () => {
		setIsLoading(true)
		const monthList = await companyMonthListService({}, token)
		monthList.reverse()
		setCompanyMonthList(monthList)
		setIsLoading(false)
	}, [token])

	useEffect(() => {
		fetchDashboardChartData()
	}, [fetchDashboardChartData])

	const getDashboardChartData = useCallback(
		(type: keyof NonNullable<ICompanyMonthV2["stats"]>) => {
			const data = companyMonthList.map((month) => {
				if (!month.stats) return []
				return {
					x: monthName(month.month) + " " + month.year,
					y: month.stats[type],
				}
			})
			return data
		},
		[companyMonthList],
	)

	const getQuantityChartData = useCallback(() => {
		return [
			{
				id: "Opening Stock",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("openingStockQuantity"),
			},
			{
				id: "Closing Stock",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("closingStockQuantity"),
			},
			{
				id: "Payable Quantity",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("closingPayableQuantity"),
			},
			{
				id: "Receivable Quantity",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("closingReceivableQuantity"),
			},
		]
	}, [getDashboardChartData])

	const getAmountChartData = useCallback(
		() => [
			{
				id: "Payable Amount",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("closingPayableAmount"),
			},
			{
				id: "Receivable Amount",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("closingReceivableAmount"),
			},
			{
				id: "Inward Slip Amount",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("inSlipAmount"),
			},
			{
				id: "Outward Slip Amount",
				color: "hsl(273, 70%, 50%)",
				data: getDashboardChartData("outSlipAmount"),
			},
		],
		[getDashboardChartData],
	)

	if (isLoading)
		return (
			<Box margin={5}>
				<CenteredSpinner />
			</Box>
		)

	if (companyMonthList.length === 0) return null

	return (
		<Box marginTop={5}>
			<Flex justifyContent={"center"}>
				<TabMenu
					tabs={[
						{ value: "quantity", child: "Quantities" },
						{ value: "amount", child: "Amounts" },
					]}
					onTabChange={(value) => setChartViewType(value)}
					selectedTab={chartViewType}
				/>
			</Flex>

			<Box height={500}>
				<TheResponsiveLineChart
					data={
						chartViewType === "quantity"
							? getQuantityChartData()
							: getAmountChartData()
					}
					chartViewType={chartViewType}
				/>
			</Box>
		</Box>
	)
}

const TheResponsiveLineChart: FC<{
	data: {
		id: string
		color: string
		data: (
			| any[]
			| {
					x: string
					y: number
			  }
		)[]
	}[]
	chartViewType: string
}> = ({ data, chartViewType }) => {
	return (
		<ResponsiveLine
			data={data}
			margin={{ top: 50, right: 150, bottom: 50, left: 80 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: false,
				reverse: false,
			}}
			curve="monotoneX"
			yFormat=" >-.2f"
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "Months",
				legendOffset: 40,
				legendPosition: "middle",
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: chartViewType === "quantity" ? "Quantity(KG)" : "Amount(INR)",
				legendOffset: -70,
				legendPosition: "middle",
			}}
			pointSize={5}
			pointColor={{ theme: "background" }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[
				{
					anchor: "bottom-right",
					direction: "column",
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: "left-to-right",
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: "circle",
					symbolBorderColor: "rgba(0, 0, 0, .5)",
					effects: [
						{
							on: "hover",
							style: {
								itemBackground: "rgba(0, 0, 0, .03)",
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	)
}
