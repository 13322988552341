import { createContext, FC, useContext, useState } from "react"
import { ICompanyV2, IUserV1 } from "../../domain/entities"

interface IAuthState {
	token: string | null
	user: IUserV1 | null
	company: ICompanyV2 | null
	setToken: React.Dispatch<React.SetStateAction<string | null>>
	setUser: React.Dispatch<React.SetStateAction<IUserV1 | null>>
	setCompany: React.Dispatch<React.SetStateAction<ICompanyV2 | null>>
}

const AuthContext = createContext<IAuthState>({
	token: null,
	user: null,
	company: null,
	setToken: () => {},
	setUser: () => {},
	setCompany: () => {},
})

export const AuthContextProvider: FC = ({ children, ...rest }) => {
	const [token, setToken] = useState<IAuthState["token"]>(null)
	const [user, setUser] = useState<IAuthState["user"]>(null)
	const [company, setCompany] = useState<IAuthState["company"]>(null)

	return (
		<AuthContext.Provider
			value={{ token, user, company, setToken, setUser, setCompany }}
			{...rest}
		>
			{children}
		</AuthContext.Provider>
	)
}

export function useAuthContext() {
	return useContext(AuthContext)
}
