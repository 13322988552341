import { useCallback, useState } from "react"
import { IPartyMonthV1 } from "src/domain/entities"
import { partyMonthListService } from "src/domain/services/partyMonth/partyMonthListService"
import { useAuth } from ".."

export function usePartyMonthListService(): [
	IPartyMonthV1[],
	boolean,
	(filter: Parameters<typeof partyMonthListService>[0]) => void,
] {
	const { token } = useAuth()
	const [partyMonthList, setPartyMonthList] = useState<IPartyMonthV1[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchPartyMonthList = useCallback(
		async (filter: Parameters<typeof partyMonthListService>[0]) => {
			setIsLoading(true)
			const data = await partyMonthListService(filter, token)
			setPartyMonthList(data)
			setIsLoading(false)
		},
		[token],
	)

	return [partyMonthList, isLoading, fetchPartyMonthList]
}
