import { FormControl, Stack, Textarea } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { ISubPartyMonthV2 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyMonthUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	subPartyMonth: ISubPartyMonthV2
	handleSubmit: FormikOnSubmit<ISubPartyMonthUpdateFormFields>
}

export const SubPartyMonthUpdateDrawerFormView: FC<Props> = ({
	subPartyMonth,
	handleSubmit,
	...rest
}) => (
	<Formik<ISubPartyMonthUpdateFormFields>
		initialValues={{ note: subPartyMonth?.note ?? "" }}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel="Update Note"
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							placeholder="Note"
							onChange={handleChange}
							value={values.note ?? ""}
							rows={10}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)
