import { IProductV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	include?: { audits?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: IProductV1
}

export async function productAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/product/add/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
