import { CheckIcon } from "@chakra-ui/icons"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FC } from "react"
import { IPartyMonthV1 } from "../../../../domain/entities"
import { fullMonthAndYear } from "../../../../utils/helpers"

interface IProps {
	list: IPartyMonthV1[]
	selectedPartyMonth?: IPartyMonthV1
	setSelectedPartyMonth: (partyMonth?: IPartyMonthV1) => void
}

export const PartyMonthSectionView: FC<IProps> = ({
	list,
	selectedPartyMonth,
	setSelectedPartyMonth,
}) => (
	<Box margin={3}>
		<Flex justify="space-between" align="baseline" paddingX={1}>
			<Text fontWeight="semibold">Months</Text>
		</Flex>
		<Box backgroundColor="gray.200" rounded="md" overflow="hidden">
			{list.map((partyMonth) => {
				const isSelected = partyMonth.id === selectedPartyMonth?.id
				return (
					<Box
						key={partyMonth.id}
						borderTop="1px"
						borderTopColor="gray.300"
						onClick={() => setSelectedPartyMonth(partyMonth)}
					>
						<ListItem
							key={partyMonth.id}
							partyMonth={partyMonth}
							isSelected={isSelected}
						/>
					</Box>
				)
			})}
		</Box>
	</Box>
)

const ListItem: FC<{ partyMonth: IPartyMonthV1; isSelected: boolean }> = ({
	partyMonth,
	isSelected,
}) => {
	const title = fullMonthAndYear({ month: partyMonth.month, year: partyMonth.year })

	const checkIcon = isSelected ? (
		<Box paddingLeft={2} fontSize="x-small" color="green.400">
			<CheckIcon />
		</Box>
	) : null

	return (
		<Box
			paddingY={1.5}
			paddingX={3}
			cursor="pointer"
			_hover={isSelected ? {} : { backgroundColor: "gray.300" }}
			backgroundColor={isSelected ? "gray.700" : "transparent"}
			color={isSelected ? "white" : "gray.900"}
			fontSize="sm"
		>
			<Flex alignItems="center" justify="space-between">
				<Box>{title}</Box>
				{checkIcon}
			</Flex>
		</Box>
	)
}
