import { companyExpiresAtUpdateApi } from "src/domain/api/company/companyExpiresAtUpdateApi"

type PayloadShape = Parameters<typeof companyExpiresAtUpdateApi>[0]

export async function companyExpiresAtUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await companyExpiresAtUpdateApi(payload, token)
}
