import { IPartyV1 } from "./../../entities"
import { partyListApi } from "../../api/party/partyListApi"

export async function partyGetService(
	id: string,
	token?: null | string,
): Promise<IPartyV1> {
	const [party] = await partyListApi(
		{ filter: { id }, include: { details: true, stats: true } },
		token,
	)

	if (!party) {
		throw new Error("Party not found")
	}
	return party
}
