import { useDisclosure } from "@chakra-ui/react"
import { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import { IPartyV1, IProductV1, ISubPartyV1 } from "src/domain/entities"
import {
	usePartyListService,
	useProductListService,
	useSubPartyListService,
} from "src/hooks"
import { EItemType } from "../entries/EItemType"

interface DrawerDisclosure {
	isOpen: boolean
	onOpen: () => void
	onClose: () => void
	onToggle: () => void
	isControlled: boolean
	getButtonProps: (props?: any) => any
	getDisclosureProps: (props?: any) => any
}

interface IGlobalState {
	cashReceiptAddType: EItemType | null
	setCashReceiptAddType: (type: EItemType | null) => void
	cashReceiptAddDrawerDisclosure: DrawerDisclosure | null
	jobWorkItemSlipAddType: EItemType | null
	setJobWorkItemSlipAddType: (type: EItemType | null) => void
	jobWorkItemSlipAddDrawerDisclosure: DrawerDisclosure | null
	jobWorkBillAddType: EItemType | null
	setJobWorkBillAddType: (type: EItemType | null) => void
	jobWorkBillAddDrawerDisclosure: DrawerDisclosure | null
	billAddType: EItemType | null
	setBillAddType: (type: EItemType | null) => void
	billAddDrawerDisclosure: DrawerDisclosure | null
	//Party
	partyList: IPartyV1[]
	isPartyListLoading: boolean
	fetchPartyList: () => void
	// SubParty
	subPartyList: ISubPartyV1[]
	isSubPartyListLoading: boolean
	fetchSubPartyList: () => void
	// Product
	productList: IProductV1[]
	isProductListLoading: boolean
	fetchProductList: () => void
	refreshSlipData: () => void
}

const GlobalContext = createContext<IGlobalState>({
	cashReceiptAddType: null,
	setCashReceiptAddType: () => {},
	cashReceiptAddDrawerDisclosure: null,
	jobWorkItemSlipAddType: null,
	setJobWorkItemSlipAddType: () => {},
	jobWorkItemSlipAddDrawerDisclosure: null,
	jobWorkBillAddType: null,
	setJobWorkBillAddType: () => {},
	jobWorkBillAddDrawerDisclosure: null,
	billAddType: null,
	setBillAddType: () => {},
	billAddDrawerDisclosure: null,
	// Parties
	partyList: [],
	isPartyListLoading: false,
	fetchPartyList: () => {},
	// SubParty
	subPartyList: [],
	isSubPartyListLoading: false,
	fetchSubPartyList: () => {},
	// Product
	productList: [],
	isProductListLoading: false,
	fetchProductList: () => {},
	refreshSlipData: () => {},
})

export const GlobalContextProvider: FC = ({ children, ...rest }) => {
	const [cashReceiptAddType, setCashReceiptAddType] = useState<EItemType | null>(null)
	const cashReceiptAddDrawerDisclosure = useDisclosure()
	const [jobWorkItemSlipAddType, setJobWorkItemSlipAddType] =
		useState<EItemType | null>(null)
	const jobWorkItemSlipAddDrawerDisclosure = useDisclosure()
	const [jobWorkBillAddType, setJobWorkBillAddType] = useState<EItemType | null>(null)
	const jobWorkBillAddDrawerDisclosure = useDisclosure()
	const [billAddType, setBillAddType] = useState<EItemType | null>(null)
	const billAddDrawerDisclosure = useDisclosure()

	const [partyList, isPartyListLoading, fetchPartyList] = usePartyListService()
	const [subPartyList, isSubPartyListLoading, fetchSubPartyList] =
		useSubPartyListService()
	const [productList, isProductListLoading, fetchProductList] = useProductListService()

	const refreshSlipData = useCallback(() => {
		fetchPartyList()
		fetchSubPartyList({})
		fetchProductList()
	}, [fetchPartyList, fetchSubPartyList, fetchProductList])

	useEffect(() => {
		if (cashReceiptAddDrawerDisclosure.isOpen) {
			fetchPartyList()
		}
	}, [fetchPartyList, cashReceiptAddDrawerDisclosure?.isOpen])

	useEffect(() => {
		if (jobWorkItemSlipAddDrawerDisclosure.isOpen) {
			fetchSubPartyList({})
			fetchProductList()
		}
	}, [fetchSubPartyList, fetchProductList, jobWorkItemSlipAddDrawerDisclosure?.isOpen])

	useEffect(() => {
		if (jobWorkBillAddDrawerDisclosure.isOpen) {
			fetchSubPartyList({})
			fetchProductList()
		}
	}, [fetchSubPartyList, fetchProductList, jobWorkBillAddDrawerDisclosure?.isOpen])

	useEffect(() => {
		if (billAddDrawerDisclosure.isOpen) {
			fetchSubPartyList({})
			fetchProductList()
		}
	}, [fetchSubPartyList, fetchProductList, billAddDrawerDisclosure?.isOpen])

	useEffect(() => {
		refreshSlipData()
	}, [refreshSlipData])

	return (
		<GlobalContext.Provider
			value={{
				cashReceiptAddType,
				setCashReceiptAddType,
				cashReceiptAddDrawerDisclosure,
				jobWorkItemSlipAddType,
				setJobWorkItemSlipAddType,
				jobWorkItemSlipAddDrawerDisclosure,
				jobWorkBillAddType,
				setJobWorkBillAddType,
				jobWorkBillAddDrawerDisclosure,
				billAddType,
				setBillAddType,
				billAddDrawerDisclosure,
				// Parties
				partyList,
				isPartyListLoading,
				fetchPartyList,
				// SubParty
				subPartyList,
				isSubPartyListLoading,
				fetchSubPartyList: () => fetchSubPartyList({}),
				// Product
				productList,
				isProductListLoading,
				fetchProductList,
				refreshSlipData,
			}}
			{...rest}
		>
			{children}
		</GlobalContext.Provider>
	)
}

export function useGlobalContext() {
	return useContext(GlobalContext)
}
