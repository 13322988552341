import { ComponentProps, FC, useCallback } from "react"
import { IPartyV1 } from "src/domain/entities"
import { partyUpdateService } from "src/domain/services/party/partyUpdateService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IPartyUpdateFormFields, PartyUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof PartyUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (party: IPartyV1) => void
}

export const PartyUpdateDrawerFormController: FC<Props> = ({
	party,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IPartyUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedParty = await partyUpdateService(
					{ filter: { id: party.id }, update: values },
					token,
				)
				rest.onClose()
				onSuccess && onSuccess(updatedParty)
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token, party.id],
	)
	return (
		<PartyUpdateDrawerFormView party={party} handleSubmit={handleSubmit} {...rest} />
	)
}
