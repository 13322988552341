import { useCallback, useState } from "react"
import { IPartyV1 } from "src/domain/entities"
import { partyListService } from "src/domain/services/party/partyListService"
import { useAuth } from ".."

export function usePartyListService(): [IPartyV1[], boolean, () => void] {
	const { token } = useAuth()
	const [partyList, setPartyList] = useState<IPartyV1[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchPartyList = useCallback(async () => {
		setIsLoading(true)
		const data = await partyListService({}, token)
		setPartyList(data)
		setIsLoading(false)
	}, [token])

	return [partyList, isLoading, fetchPartyList]
}
