import { Box, Text } from "@chakra-ui/react"
import { FC } from "react"
import { Link } from "react-router-dom"
import { EItemType } from "src/components/entries/EItemType"
import {
	IBillV2,
	ICashReceiptV2,
	IJobWorkBillV2,
	IJobWorkItemSlipV2,
	ISubPartyV1,
} from "src/domain/entities"
import { formatDate, toINR } from "src/utils/helpers"
import { EntryRowProps } from "./EntryList"

const SubPartyLink: FC<{ subParty?: ISubPartyV1 }> = ({ subParty }) => {
	if (!subParty) return null

	let subPartyName = subParty.name ?? ""
	if (!subParty.isDefault) {
		subPartyName += `(${subParty.party?.name ?? ""})`
	}

	let subPartyLink = `/party/${subParty.party?.id}`
	if (!subParty.isDefault) {
		subPartyLink += `?subPartyId=${subParty.id}`
	}

	return (
		<Box fontSize={"xs"} color={"blue.600"}>
			<Link to={subPartyLink}>{subPartyName}</Link>
		</Box>
	)
}

export const getParsedInwardEntries = (slips: IJobWorkItemSlipV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.jobWorkItem?.product?.name ?? ""}</Box>
				{slip.jobWorkItem?.workRate ? (
					<Box fontWeight={"semibold"}>
						@ {toINR(slip.jobWorkItem.workRate)}
					</Box>
				) : null}
				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.prefix + "/" + String(slip.slipNo).padStart(4, "0")}
			${formatDate(slip.issueDate) ?? ""}
			${slip.jobWorkItem?.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
			${slip.prefix + "/" + slip.slipNo}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: (
				<>
					<Text as={"span"}>{slip.prefix}</Text>{" "}
					<Text as={"span"} fontWeight={"semibold"}>
						/ {slip.slipNo.toString()}
					</Text>
				</>
			),
			type: EItemType.INWARD,
			particulars: particulars,
			grossQuantity: slip.grossQuantity,
			bagQuantity: slip.bagQuantity,
			burnLossQuantity:
				((slip.jobWorkItem?.lossPercentage ?? 0) * slip.grossQuantity) / 100,
			rejectedQuantity: slip.rejectedQuantity,
			netQuantity: slip.netQuantity,
			amount: slip.netQuantity * (slip.jobWorkItem?.workRate ?? 0),
			searchTerm: searchTerm,
		}
	})
}

export const getParsedOutwardEntries = (slips: IJobWorkItemSlipV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.jobWorkItem?.product?.name ?? ""}</Box>
				{slip.jobWorkItem?.workRate ? (
					<Box fontWeight={"semibold"}>
						@ {toINR(slip.jobWorkItem.workRate)}
					</Box>
				) : null}
				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.prefix + "/" + String(slip.slipNo).padStart(4, "0")}
			${formatDate(slip.issueDate) ?? ""}
			${slip.jobWorkItem?.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
			${slip.prefix + "/" + slip.slipNo}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			prefix: slip.prefix,
			slipNo: (
				<>
					<Text as={"span"}>{slip.prefix}</Text>{" "}
					<Text as={"span"} fontWeight={"semibold"}>
						/ {slip.slipNo.toString()}
					</Text>
				</>
			),
			type: EItemType.OUTWARD,
			particulars: particulars,
			grossQuantity: slip.grossQuantity,
			bagQuantity: slip.bagQuantity,
			burnLossQuantity:
				((slip.jobWorkItem?.lossPercentage ?? 0) * slip.grossQuantity) / 100,
			rejectedQuantity: slip.rejectedQuantity,
			netQuantity: slip.netQuantity,
			amount: slip.netQuantity * (slip.jobWorkItem?.workRate ?? 0),
			searchTerm: searchTerm,
		}
	})
}

export const getParsedSaleBillEntries = (slips: IBillV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.product?.name ?? ""}</Box>

				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.fullBillNo}
			${formatDate(slip.issueDate) ?? ""}
			${slip.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: slip.fullBillNo.toString(),
			type: EItemType.SALE_BILL,
			particulars: particulars,
			grossQuantity: slip.quantity,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: slip.quantity,
			amount: slip.quantity * slip.saleRate,
			searchTerm: searchTerm,
		}
	})
}

export const getParsedPurchaseBillEntries = (slips: IBillV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.product?.name ?? ""}</Box>
				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.fullBillNo}
			${formatDate(slip.issueDate) ?? ""}
			${slip.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: slip.fullBillNo.toString(),
			type: EItemType.PURCHASE_BILL,
			particulars: particulars,
			grossQuantity: slip.quantity,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: slip.quantity,
			amount: slip.quantity * slip.saleRate,
			searchTerm: searchTerm,
		}
	})
}

export const getParsedInJobWorkBillEntries = (
	slips: IJobWorkBillV2[],
): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.product?.name ?? ""}</Box>
				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.fullBillNo}
			${formatDate(slip.issueDate) ?? ""}
			${slip.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: slip.fullBillNo?.toString() ?? "",
			type: EItemType.INWARD_JOBWORK_BILL,
			particulars: particulars,
			grossQuantity: slip.quantity,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: slip.quantity,
			amount: slip.quantity * slip.workRate,
			searchTerm: searchTerm,
		}
	})
}

export const getParsedOutJobWorkBillEntries = (
	slips: IJobWorkBillV2[],
): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.product?.name ?? ""}</Box>
				<SubPartyLink subParty={slip.subParty} />
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.fullBillNo}
			${formatDate(slip.issueDate) ?? ""}
			${slip.product?.name ?? ""}
			${slip.note ?? ""}
			${slip.subParty?.name ?? ""}
			${slip.subParty?.party?.name ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: slip.fullBillNo?.toString() ?? "",
			type: EItemType.OUTWARD_JOBWORK_BILL,
			particulars: particulars,
			grossQuantity: slip.quantity,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: slip.quantity,
			amount: slip.quantity * slip.workRate,
			searchTerm: searchTerm,
		}
	})
}

export const getParsedInCashEntries = (slips: ICashReceiptV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.party?.name ?? ""}</Box>
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.party?.name ?? ""}
			${slip.note ?? ""}
			${slip.amount ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: "-",
			type: EItemType.INWARD_CASH,
			particulars: particulars,
			grossQuantity: 0,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: 0,
			amount: slip.amount,
			searchTerm: searchTerm,
		}
	})
}

export const getParsedOutCashEntries = (slips: ICashReceiptV2[]): EntryRowProps[] => {
	return slips.map((slip) => {
		const particulars = (
			<Box fontSize={"sm"}>
				<Box>{slip.party?.name ?? ""}</Box>
				<Box fontSize={"xs"}>{slip.note}</Box>
			</Box>
		)

		const searchTerm = `
			${slip.party?.name ?? ""}
			${slip.note ?? ""}
			${slip.amount ?? ""}
		`

		return {
			id: slip.id,
			issueDate: slip.issueDate,
			slipNo: "-",
			type: EItemType.OUTWARD_CASH,
			particulars: particulars,
			grossQuantity: 0,
			bagQuantity: 0,
			burnLossQuantity: 0,
			rejectedQuantity: 0,
			netQuantity: 0,
			amount: slip.amount,
			searchTerm: searchTerm,
		}
	})
}
