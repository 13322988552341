import { IJobWorkBillV2 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id: string }
	update: {
		fullBillNo?: string
		workRate?: number
		quantity?: number
		note?: null | string
	}
}

interface ResponseDataShape {
	success: boolean
	data: IJobWorkBillV2
}

export async function inwardJobWorkBillUpdateApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/inwardJobWorkBill/update/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
