import { useDisclosure } from "@chakra-ui/react"
import { ComponentProps, FC, useState } from "react"
import { ICashReceiptV2 } from "src/domain/entities"
import { usePartyDetailsPageContext } from "../../../PartyDetailsPageContext"
import { CashReceiptUpdateDrawerFormController } from "../../../../../entries/cashReceipt/CashReceiptUpdateDrawerForm"
import { CashReceiptListDrawer } from "./CashReceiptListDrawer"
import { CashReceiptDeleteDialogController } from "src/components/entries/cashReceipt/CashReceiptDeleteDialogController"

interface Props extends ComponentProps<typeof CashReceiptListDrawer> {}

export const CashReceiptListController: FC<Props> = (props) => {
	const itemSlipEditDisclosure = useDisclosure()
	const [selectedCashReceipt, setSelectedCashReceipt] = useState<ICashReceiptV2 | null>(
		null,
	)
	const { refreshPageData } = usePartyDetailsPageContext()

	const [isCashReceiptDeleteDialogOpen, setIsCashReceiptDeleteDialogOpen] =
		useState(false)

	const handleCashReceiptEditClick = (slip: ICashReceiptV2) => {
		itemSlipEditDisclosure.onOpen()
		setSelectedCashReceipt(slip)
	}
	const handleCashReceiptDeleteClick = (slip: ICashReceiptV2) => {
		setSelectedCashReceipt(slip)
		setIsCashReceiptDeleteDialogOpen(true)
	}

	return (
		<>
			<CashReceiptListDrawer
				{...props}
				onEditClick={handleCashReceiptEditClick}
				onDeleteClick={handleCashReceiptDeleteClick}
			/>
			{selectedCashReceipt && (
				<CashReceiptUpdateDrawerFormController
					{...itemSlipEditDisclosure}
					type={props.type}
					slip={selectedCashReceipt}
					onSuccess={() => {
						itemSlipEditDisclosure.onClose()
						refreshPageData()
						setSelectedCashReceipt(null)
					}}
				/>
			)}
			{selectedCashReceipt && (
				<CashReceiptDeleteDialogController
					isOpen={isCashReceiptDeleteDialogOpen}
					setIsOpen={setIsCashReceiptDeleteDialogOpen}
					type={props.type}
					slip={selectedCashReceipt}
					onSuccess={() => {
						refreshPageData()
						setSelectedCashReceipt(null)
					}}
				/>
			)}
		</>
	)
}
