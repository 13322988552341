import classNames from "classnames"
import { FC } from "react"
import {
	IBillV2,
	ICashReceiptV2,
	IJobWorkBillV2,
	IJobWorkItemV2,
	IPartyMonthStatementV3,
	IPartyMonthV1,
	ISubPartyV1,
} from "src/domain/entities"
import { formatDate, fullMonthAndYear, toINR, toKGs } from "src/utils/helpers"

interface PartyMonthStatementStats {
	totalInQuantity: number
	totalInAmount: number
	totalOutQuantity: number
	totalOutAmount: number
}

export const SubPartyStatementPrintView: FC<{
	selectedSubParty?: ISubPartyV1
	partyMonth: IPartyMonthV1
	statement: IPartyMonthStatementV3
}> = ({ selectedSubParty, partyMonth, statement }) => {
	let toShowCashEntries = true
	let subPartyMonthStatementList = statement.subPartyMonthStatementList
	let title = "Party Statement"

	let stats: PartyMonthStatementStats = {
		totalInQuantity: statement.partyMonth.stats.in.totalQuantity,
		totalOutQuantity: statement.partyMonth.stats.out.totalQuantity,
		totalInAmount: statement.partyMonth.stats.in.totalAmount,
		totalOutAmount: statement.partyMonth.stats.out.totalAmount,
	}

	let statementNote = ""

	if (selectedSubParty) {
		toShowCashEntries = false
		subPartyMonthStatementList = subPartyMonthStatementList.filter(
			(el) => el.subParty.id === selectedSubParty.id,
		)
		title = `${selectedSubParty.name} Sub-Party Statement`
		if (subPartyMonthStatementList[0]) {
			statementNote = subPartyMonthStatementList[0].subPartyMonth.note ?? ""
			stats = {
				totalOutQuantity:
					subPartyMonthStatementList[0].subPartyMonth.stats.out.totalQuantity,
				totalOutAmount:
					subPartyMonthStatementList[0].subPartyMonth.stats.out.totalAmount,
				totalInQuantity:
					subPartyMonthStatementList[0].subPartyMonth.stats.in.totalQuantity,
				totalInAmount:
					subPartyMonthStatementList[0].subPartyMonth.stats.in.totalAmount,
			}
		}
	} else {
		if (statement.subPartyMonthStatementList[0]) {
			statementNote =
				statement.subPartyMonthStatementList[0].subPartyMonth.note ?? ""
		}
	}

	return (
		<div className="print-view">
			<style>
				{`
                
                .print-view table th {
                    font-size: 12px;
                    border: 1px solid #000;
                    padding: 5px;
                    background-color: #eee;
                }
                .print-view table td {
                    border: 1px solid #000;
                    font-size: 11px;
                    padding: 2px 5px;
                    page-break-inside: avoid;
                }
                .print-view table {
                    width: 100%;
                }

                .print-view .slip-table {
                    margin: 5px;
                }
                .print-view .slip-table th, .print-view .slip-table td {
                    border: 1px solid #999;
                }
                .print-view .text-right {
                    text-align: right;
                }
                .print-view .text-center {
                    text-align: center;
                }
                /* page margins */    
                @page {
                    margin: 0.3cm;
                }

                .summary-table td, .summary-table th {
                    padding: 5px;
                }
            `}
			</style>
			<div style={{ margin: 10, marginLeft: 50 }}>
				<div style={{ fontSize: 20, fontWeight: "bold" }}>
					<span>{title}</span>
					<span className="print-only"> of {statement.party.name} </span>
					<span> ({fullMonthAndYear(partyMonth)})</span>
				</div>
				<div className="content">
					<div style={{ padding: 10 }}></div>
					<table>
						<thead>
							<tr>
								<th colSpan={6}>OUTWARD</th>
							</tr>
							<tr>
								<th>PARTICULARS</th>
								<th className={classNames("text-right")}>MARKET RATE</th>
								<th className={classNames("text-right")}>LABOUR RATE</th>
								<th className={classNames("text-right")}>QUANTITY</th>
								<th className={classNames("text-right")}>REJECTION</th>
								<th className={classNames("text-right")}>AMOUNT</th>
							</tr>
						</thead>
						<tbody>
							{subPartyMonthStatementList.map((spmStatement) => {
								return spmStatement.outJobWorkItemList.map((item) => (
									<JobWorkItemRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
									/>
								))
							})}
							{subPartyMonthStatementList.map((spmStatement) =>
								spmStatement.outJobWorkBillList.map((item) => (
									<JobWorkBillRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
									/>
								)),
							)}
							{subPartyMonthStatementList.map((spmStatement) =>
								spmStatement.purchaseBillList.map((item) => (
									<BillRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
										badgeLabel="PURCHASE"
									/>
								)),
							)}
							{toShowCashEntries
								? statement.outCashList.map((item) => (
										<CashRow
											key={item.id}
											item={item}
											badgeLabel="CASH OUT"
										/>
								  ))
								: null}
							<TableTotal
								totalQuantity={stats.totalOutQuantity}
								totalAmount={stats.totalOutAmount}
							/>
						</tbody>
					</table>
					<div style={{ padding: 20 }}></div>
					<table>
						<thead>
							<tr>
								<th colSpan={6}>INWARD</th>
							</tr>
							<tr>
								<th>PARTICULARS</th>
								<th className={classNames("text-right")}>MARKET RATE</th>
								<th className={classNames("text-right")}>LABOUR RATE</th>
								<th className={classNames("text-right")}>QUANTITY</th>
								<th className={classNames("text-right")}>REJECTION</th>
								<th className={classNames("text-right")}>AMOUNT</th>
							</tr>
						</thead>
						<tbody>
							{subPartyMonthStatementList.map((spmStatement) => {
								return spmStatement.inJobWorkItemList.map((item) => (
									<JobWorkItemRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
									/>
								))
							})}
							{subPartyMonthStatementList.map((spmStatement) =>
								spmStatement.inJobWorkBillList.map((item) => (
									<JobWorkBillRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
									/>
								)),
							)}
							{subPartyMonthStatementList.map((spmStatement) =>
								spmStatement.saleBillList.map((item) => (
									<BillRow
										key={item.id}
										item={item}
										subPartyName={
											!selectedSubParty &&
											!spmStatement.subParty.isDefault
												? spmStatement.subParty.name
												: undefined
										}
										badgeLabel="SALE"
									/>
								)),
							)}
							{toShowCashEntries
								? statement.inCashList.map((item) => (
										<CashRow
											key={item.id}
											item={item}
											badgeLabel="CASH IN"
										/>
								  ))
								: null}
							<TableTotal
								totalQuantity={stats.totalInQuantity}
								totalAmount={stats.totalInAmount}
							/>
						</tbody>
					</table>
					<div style={{ padding: 20 }}></div>
					<div>
						<SummaryTable
							stats={stats}
							selectedSubParty={selectedSubParty}
							statement={statement}
						/>
					</div>
					<div>
						{statementNote ? (
							<div style={{ width: "100%" }}>
								<span style={{ fontWeight: "bold" }}>Note:</span>
								<pre
									style={{ fontFamily: "inherit", fontSize: "0.9rem" }}
								>
									{statementNote}
								</pre>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
}

const JobWorkItemRow: FC<{
	item: IJobWorkItemV2
	subPartyName?: string
}> = ({ item, subPartyName }) => {
	const itemRejectedQuantity: number =
		item.slips
			?.map((slip) => slip.rejectedQuantity)
			?.reduce((a, b) => {
				return a + b
			}, 0) ?? 0

	return (
		<>
			<tr key={item.id}>
				<td>
					<span>{item.product?.name}</span>
					{subPartyName && <span color={"gray.600"}>({subPartyName})</span>}
					<div>
						<PrintableStatementSlips item={item} />
					</div>
				</td>
				<td
					className={classNames("text-right", {
						"text-center": !item.marketRate,
					})}
				>
					{toINR(item.marketRate, { dashIfZero: true })}
				</td>
				<td
					className={classNames("text-right", {
						"text-center": !item.workRate,
					})}
				>
					{toINR(item.workRate, { dashIfZero: true })}
				</td>
				<td
					className={classNames("text-right", {
						"text-center": !item.quantity,
					})}
				>
					{toKGs(item.quantity, { dashIfZero: true })}
					{item.lossPercentage && (
						<div color={"red.600"}>
							{item.isLossInclusive
								? `-${toKGs(item.inclusiveLossQuantity)}`
								: `+${toKGs(item.exclusiveLossQuantity)}`}
						</div>
					)}
				</td>
				<td
					className={classNames("text-right", {
						"text-center": !itemRejectedQuantity,
					})}
				>
					{toKGs(itemRejectedQuantity, { dashIfZero: true })}
				</td>
				<td
					className={classNames("text-right", {
						"text-center": !item.amount,
					})}
				>
					{toINR(item.amount, { dashIfZero: true })}
				</td>
			</tr>
		</>
	)
}

const JobWorkBillRow: FC<{ item: IJobWorkBillV2; subPartyName?: string }> = ({
	item,
	subPartyName,
}) => {
	return (
		<tr key={item.id}>
			<td>
				{item.product?.name}
				{subPartyName && <span color={"gray.600"}>({subPartyName})</span>}
				<div className="badge">JW Bill</div>
			</td>
			<td className="text-center">-</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.workRate,
				})}
			>
				{toINR(item.workRate, { dashIfZero: true })}
			</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.quantity,
				})}
			>
				{toKGs(item.quantity, { dashIfZero: true })}
			</td>
			<td className={classNames("text-center")}>-</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.amount,
				})}
			>
				{toINR(item.amount, { dashIfZero: true })}
			</td>
		</tr>
	)
}

const BillRow: FC<{ item: IBillV2; badgeLabel: string; subPartyName?: string }> = ({
	item,
	badgeLabel,
	subPartyName,
}) => {
	return (
		<tr key={item.id}>
			<td>
				{item.product?.name}
				{subPartyName && <span color={"gray.600"}>({subPartyName})</span>}
				<div className="badge">{badgeLabel}</div>
			</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.saleRate,
				})}
			>
				{toINR(item.saleRate, { dashIfZero: true })}
			</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.workRate,
				})}
			>
				{toINR(item.workRate, { dashIfZero: true })}
			</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.quantity,
				})}
			>
				{toKGs(item.quantity, { dashIfZero: true })}
			</td>
			<td className={classNames("text-center")}>-</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.amount,
				})}
			>
				{toINR(item.amount, { dashIfZero: true })}
			</td>
		</tr>
	)
}

const CashRow: FC<{ item: ICashReceiptV2; badgeLabel: string }> = ({
	item,
	badgeLabel,
}) => {
	return (
		<tr key={item.id}>
			<td colSpan={5}>
				<div style={{ display: "flex" }}>
					<div className="badge">{badgeLabel}</div>
					<div style={{ display: "flex", marginLeft: 2, fontSize: "sm" }}>
						<div style={{ fontWeight: "semibold" }} color={"gray.700"}>
							Note:{" "}
						</div>
						<div>{item.note || ""}</div>
					</div>
				</div>
			</td>
			<td
				className={classNames("text-right", {
					"text-center": !item.amount,
				})}
			>
				{toINR(item.amount, { dashIfZero: true })}
			</td>
		</tr>
	)
}

const TableTotal: FC<{ totalQuantity: number; totalAmount: number }> = ({
	totalQuantity,
	totalAmount,
}) => (
	<tr>
		<th colSpan={3}>TOTAL</th>
		<td className={classNames("text-right")} style={{ fontWeight: "bold" }}>
			{toKGs(totalQuantity)}
		</td>
		<td className={classNames("text-right")}></td>
		<td className={classNames("text-right")} style={{ fontWeight: "bold" }}>
			{toINR(totalAmount)}
		</td>
	</tr>
)

const previousMonthName = ({ month, year }: { month: number; year: number }) => {
	const date = new Date(year, month - 1, 0)
	return date.toLocaleString("default", { month: "long", year: "numeric" })
}

const nextMonthName = ({ month, year }: { month: number; year: number }) => {
	const date = new Date(year, month + 1, 0)
	return date.toLocaleString("default", { month: "long", year: "numeric" })
}

const SummaryTable: FC<{
	selectedSubParty?: ISubPartyV1
	stats: PartyMonthStatementStats
	statement: IPartyMonthStatementV3
}> = ({ selectedSubParty, stats, statement }) => {
	let openingTitle = `Carried from ${previousMonthName(statement.partyMonth)}`
	let closingTitle = `Forward to ${nextMonthName(statement.partyMonth)}`

	return (
		<div className="summary-table" style={{ display: "flex" }}>
			<div style={{ flex: 1 }}></div>
			<div style={{ flex: 1 }}>
				<table>
					<thead>
						<tr>
							<th colSpan={3}>SUMMARY</th>
						</tr>
						<tr>
							<th></th>
							<th className={classNames("text-right")}>Quantity</th>
							<th className={classNames("text-right")}>Amount</th>
						</tr>
					</thead>
					<tbody>
						{!selectedSubParty ? (
							<tr>
								<td>{openingTitle}</td>
								<td className={classNames("text-right")}>
									{toKGs(
										statement.partyMonth.stats.opening
											.receivableQuantity,
										{ dashIfZero: true },
									)}
								</td>
								<td className={classNames("text-right")}>
									{toINR(
										statement.partyMonth.stats.opening
											.receivableAmount,
										{
											dashIfZero: true,
										},
									)}
								</td>
							</tr>
						) : null}
						<tr>
							<td>Total Out</td>
							<td className={classNames("text-right")}>
								{toKGs(stats.totalOutQuantity, {
									dashIfZero: true,
								})}
							</td>
							<td className={classNames("text-right")}>
								{toINR(stats.totalOutAmount, {
									dashIfZero: true,
								})}
							</td>
						</tr>
						<tr>
							<td>Total In</td>
							<td className={classNames("text-right")}>
								{toKGs(stats.totalInQuantity, {
									dashIfZero: true,
								})}
							</td>
							<td className={classNames("text-right")}>
								{toINR(stats.totalInAmount, {
									dashIfZero: true,
								})}
							</td>
						</tr>
						{selectedSubParty ? (
							<tr>
								<td>Out-In difference</td>
								<td className={classNames("text-right")}>
									{toKGs(
										stats.totalOutQuantity - stats.totalInQuantity,
										{
											dashIfZero: true,
										},
									)}
								</td>
								<td className={classNames("text-right")}>
									{toINR(stats.totalOutAmount - stats.totalInAmount, {
										dashIfZero: true,
									})}
								</td>
							</tr>
						) : null}
						{!selectedSubParty ? (
							<tr>
								<td>{closingTitle}</td>
								<td
									style={{ fontWeight: "bold" }}
									className={classNames("text-right")}
								>
									{toKGs(
										statement.partyMonth.stats.closing
											.receivableQuantity,
										{ dashIfZero: true },
									)}
								</td>
								<td
									style={{ fontWeight: "bold" }}
									className={classNames("text-right")}
								>
									{toINR(
										statement.partyMonth.stats.closing
											.receivableAmount,
										{
											dashIfZero: true,
										},
									)}
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const PrintableStatementSlips: FC<{ item: IJobWorkItemV2 }> = ({ item }) => {
	let toShowBagQuantityColumn = false

	for (let slip of item.slips ?? []) {
		if (slip.bagQuantity) {
			toShowBagQuantityColumn = true
			break
		}
	}

	return (
		<div className="slip-table">
			<table>
				<tbody>
					{item.slips?.map((slip, index) => (
						<>
							<tr
								style={{
									backgroundColor: index % 2 === 1 ? "#f2f2f2" : "",
								}}
							>
								<td>{formatDate(slip.issueDate)}</td>
								<td>{slip.prefix + "-" + slip.slipNo}</td>
								{toShowBagQuantityColumn ? (
									<td className={classNames("text-right")}>
										{slip.bagQuantity || "-"}
									</td>
								) : null}
								<td className={classNames("text-right")}>
									{toKGs(slip.netQuantity)}
								</td>
							</tr>
							{slip.note && (
								<tr
									style={{
										backgroundColor: index % 2 === 1 ? "#f2f2f2" : "",
									}}
								>
									<td>{slip.note}</td>
								</tr>
							)}
						</>
					))}
				</tbody>
			</table>
		</div>
	)
}
