import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { FC } from "react"
import { IconType } from "react-icons"
import { Link } from "react-router-dom"
import { getCurrentPath } from "../../domain/utils"
import { useAuthContext } from "../context/auth"
import { JobWorkLogo } from "./JobWorkLogo"

export type NavItem = {
	name: string
	link: string
	icon: IconType
}

export const SideBar: FC<{ items: NavItem[] }> = ({ items }) => (
	<Box
		display={{ base: "none", lg: "block" }}
		width="3xs"
		height="100vh"
		paddingBottom="10"
		overflowX="hidden"
		overflowY="auto"
		className="bg-gray-800"
		borderRightWidth="1px"
	>
		<SideBarTitle />
		<Flex as="div" direction="column">
			{items.map((item, i) => (
				<MenuItem item={item} key={i} />
			))}
		</Flex>
	</Box>
)

const SideBarTitle: FC = () => {
	const { company } = useAuthContext()
	return (
		<Flex px="4" py="5" direction="column">
			<Box ml={2}>
				<JobWorkLogo fontSize={"2xl"} />
			</Box>

			<Text mt={1} ml="2" color="whiteAlpha.800">
				{company?.name}
			</Text>
		</Flex>
	)
}

const MenuItem: FC<{ item: NavItem }> = ({ item: { icon, name, link } }) => {
	const isSelected = getCurrentPath() === link

	return (
		<Link to={link}>
			<Flex
				align="center"
				px="4"
				mx="2"
				rounded="md"
				py="2"
				cursor={isSelected ? "auto" : "pointer"}
				color={isSelected ? "whiteAlpha.800" : "whiteAlpha.800"}
				backgroundColor={isSelected ? "whiteAlpha.200" : "transparent"}
				_hover={
					isSelected ? {} : { bg: "blackAlpha.300", color: "whiteAlpha.900" }
				}
				role="group"
				fontWeight="semibold"
				transition=".15s ease"
				textDecoration="none"
				mb="1"
			>
				<Icon mr="2" boxSize="4" as={icon} />
				{name}
			</Flex>
		</Link>
	)
}
