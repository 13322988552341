import { useCallback, useState } from "react"
import { IProductV1 } from "src/domain/entities"
import { productListService } from "src/domain/services/product/productListService"
import { useAuth } from ".."

export function useProductListService(): [
	IProductV1[],
	boolean,
	(filter?: Parameters<typeof productListService>[0]) => void,
] {
	const { token } = useAuth()
	const [productList, setProductList] = useState<IProductV1[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchProductList = useCallback(
		async (filter: Parameters<typeof productListService>[0] = {}) => {
			setIsLoading(true)
			const data = await productListService(filter, token)
			setProductList(data)
			setIsLoading(false)
		},
		[token],
	)

	return [productList, isLoading, fetchProductList]
}
