import { ICompanyV2 } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id: string }
	update: { expiresAt: string }
}

interface ResponseDataShape {
	success: boolean
	data: ICompanyV2
}

export async function companyExpiresAtUpdateApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/company/expiresAt/update/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
