import {
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
} from "@chakra-ui/react"
import { ComponentProps, FC } from "react"

interface Props extends ComponentProps<typeof Drawer> {
	headerLabel?: string
}

export const DrawerBasic: FC<Props> = ({ children, headerLabel, ...rest }) => (
	<Drawer {...rest}>
		<DrawerOverlay />
		<DrawerContent>
			<DrawerCloseButton my="2" />
			<DrawerHeader borderBottomWidth="1px">
				{headerLabel ?? "Drawer Header"}
			</DrawerHeader>
			<DrawerBody>{children}</DrawerBody>

			<DrawerFooter borderTopWidth="1px">
				<Button variant="outline" mr={3} onClick={rest.onClose}>
					Close
				</Button>
			</DrawerFooter>
		</DrawerContent>
	</Drawer>
)
