import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	mobileNo: string
	password: string
}

interface ResponseDataShape {
	success: boolean
	data: { token: string }
}

export async function userLoginApi(payload: RequestDataShape) {
	const endpoint = "/user/login/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload)
	return response.data
}
