import { IJobWorkItemSlipV2 } from "src/domain/entities"
import { ApiRequester } from "../../ApiRequester"
import { EJobWorkItemType } from "../EJobWorkItemType"

interface RequestDataShape {
	filter: { itemType: EJobWorkItemType; id: string; jobWorkItemId: string }
	update: {
		prefix?: string
		slipNo?: number
		issueDate?: string
		grossQuantity?: number
		bagQuantity?: number
		rejectedQuantity?: number
		note?: string
	}
}

interface ResponseDataShape {
	success: boolean
	data: IJobWorkItemSlipV2
}

export async function jobWorkItemSlipUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/jobWorkItem/slip/update/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
