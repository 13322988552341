import { inwardCashReceiptUpdateApi } from "../../api/inwardCashReceipt/inwardCashReceiptUpdateApi"

type PayloadShape = Parameters<typeof inwardCashReceiptUpdateApi>[0]

export async function inwardCashReceiptUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await inwardCashReceiptUpdateApi(payload, token)
}
