import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

interface ResponseDataShape {
	success: boolean
}

export async function subPartyDeleteApi(payload: RequestDataShape, token: string) {
	const endpoint = "/subParty/delete/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	await requester.sendRequest(payload, token)
}
