import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	currentPassword: string
	newPassword: string
}

interface ResponseDataShape {
	success: boolean
	data: { token: string }
}

export async function userPasswordUpdateApi(
	payload: RequestDataShape,
	token: string | null,
) {
	const endpoint = "/user/password/update/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
