import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import { IPartyV1 } from "../../../domain/entities"
import { toINR, toKGs } from "../../../utils/helpers"
import { HeaderStatBox } from "../../shared/HeaderStatBox"

export const PartyDetailHeader: FC<{ party: IPartyV1 }> = ({ party }) => {
	const closingReceivableAmount = party.stats?.closingReceivableAmount ?? 0
	const closingReceivableQuantity = party.stats?.closingReceivableQuantity ?? 0

	const amountStatsTitle =
		closingReceivableAmount < 0 ? "Amount Payable" : "Amount Receivable"
	const quantityStatsTitle =
		closingReceivableQuantity < 0 ? "Quantity Payable" : "Quantity Receivable"

	return (
		<Box padding={2} borderBottom="1px" borderBottomColor="gray.300">
			<Flex justifyContent="space-between" alignItems="center">
				<Box>
					<Box fontSize="2xl" fontWeight={"bold"} lineHeight={"1.2"}>
						{party.name}
					</Box>
					<Box color="gray.600">{party.details?.note || "No Note"}</Box>
				</Box>
				<Flex>
					<HeaderStatBox
						headerText={quantityStatsTitle}
						value={Math.abs(closingReceivableQuantity)}
						formatter={toKGs}
						textColor={
							closingReceivableQuantity < 0 ? "red.500" : "green.500"
						}
						suffix="KG"
					/>
					<HeaderStatBox
						headerText={amountStatsTitle}
						value={Math.abs(closingReceivableAmount)}
						formatter={toINR}
						textColor={closingReceivableAmount < 0 ? "red.500" : "green.500"}
					/>
				</Flex>
			</Flex>
		</Box>
	)
}
