import { ICompanyV2 } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	update: { name?: string; defaultPrefix?: string }
}

interface ResponseDataShape {
	success: boolean
	data: ICompanyV2
}

export async function companySelfUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/company/self/update/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
