import { ICompanyV2 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	include?: { user?: boolean; userVerifiedMobile?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: ICompanyV2[]
}

export async function companyListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/company/list/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
