import { AddIcon } from "@chakra-ui/icons"
import {
	Box,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { BsPencilFill, BsThreeDots, BsTrash2Fill } from "react-icons/bs"
import { EItemType } from "src/components/entries/EItemType"
import { IJobWorkItemV2, ISubPartyV1 } from "src/domain/entities"
import { toINR, toKGs } from "src/utils/helpers"
import { ItemSlipsSection } from "."

export const ColumnItemsSection: FC<{
	items: IJobWorkItemV2[]
	subParty: ISubPartyV1
	type: EItemType
	hasSubPartySelected: boolean
	onItemSlipAddClick: (type: EItemType, item: IJobWorkItemV2) => void
	onItemSlipEditClick: ComponentProps<typeof ItemSlipsSection>["onItemSlipEditClick"]
	onItemSlipDeleteClick: ComponentProps<
		typeof ItemSlipsSection
	>["onItemSlipDeleteClick"]
}> = ({
	items,
	type,
	hasSubPartySelected,
	onItemSlipAddClick,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	return (
		<>
			{items.map((item) => (
				<Box marginY={5} rounded="lg" overflow="hidden" key={item.id}>
					<Box backgroundColor="gray.200" paddingX={3} paddingY={2}>
						<Box>
							<Flex justify="space-between" align={"center"}>
								<Flex>
									<Text fontWeight="semibold">
										{item.product?.name}
									</Text>
									{item.workRate !== 0 && (
										<Text marginLeft={1}>
											({toINR(item.workRate)})
										</Text>
									)}
								</Flex>
								<Box className="print-hidden">
									<Menu>
										<MenuButton
											as={IconButton}
											aria-label="Options"
											size="xs"
											backgroundColor="gray.200"
											icon={<BsThreeDots />}
										/>
										<MenuList fontSize={"sm"} minWidth="auto">
											<MenuItem
												icon={<AddIcon />}
												color="blue.500"
												onClick={() =>
													onItemSlipAddClick(type, item)
												}
											>
												Add Slip
											</MenuItem>
											<MenuItem
												icon={<BsPencilFill />}
												color="yellow.600"
											>
												Edit
											</MenuItem>
											<MenuItem
												icon={<BsTrash2Fill />}
												color="red.600"
											>
												Delete
											</MenuItem>
										</MenuList>
									</Menu>
								</Box>
							</Flex>
							{item.subPartyMonth?.subParty?.isDefault !== true &&
							!hasSubPartySelected ? (
								<Box>
									<Text
										fontWeight="semibold"
										fontSize={"sm"}
										color="gray.600"
									>
										Sub Party: {item.subPartyMonth?.subParty?.name}
									</Text>
								</Box>
							) : null}
						</Box>
					</Box>
					<ItemSlipsSection
						slips={item.slips}
						item={item}
						type={type}
						onItemSlipEditClick={onItemSlipEditClick}
						onItemSlipDeleteClick={onItemSlipDeleteClick}
					/>
					<Box
						backgroundColor="gray.100"
						paddingX={3}
						paddingY={2}
						fontSize="sm"
					>
						<Flex justify="space-between">
							<Box></Box>
							<Box>
								<Box>Total Quantity</Box>
								{item.lossPercentage ? (
									<Box>Burn Loss ({item.lossPercentage}%)</Box>
								) : null}
								{item.workRate ? (
									<Box>Amount ({toINR(item.workRate)})</Box>
								) : null}
							</Box>
							<Box fontWeight="semibold" textAlign={"right"}>
								<Box>{toKGs(item.quantity)}</Box>
								{item.lossPercentage ? (
									<Box>
										{toKGs(
											item.isLossInclusive
												? -item.inclusiveLossQuantity
												: item.exclusiveLossQuantity,
										)}
									</Box>
								) : null}
								{item.workRate ? (
									<Box color={"green.400"}>{toINR(item.amount)}</Box>
								) : null}
							</Box>
						</Flex>
					</Box>
				</Box>
			))}
		</>
	)
}
