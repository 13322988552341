import { ApiRequester } from "../../ApiRequester"
import { EJobWorkItemType } from "../EJobWorkItemType"

interface RequestDataShape {
	filter: {
		itemType: EJobWorkItemType
		id: string
		jobWorkItemId: string
	}
	update: {
		itemType?: EJobWorkItemType
		issueDate?: string
	}
}

interface ResponseDataShape {
	success: boolean
}

export async function jobWorkItemSlipTransferApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/jobWorkItem/slip/transfer/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	await requester.sendRequest(payload, token)
}
