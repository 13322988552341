import { IUnifiedMonthEntriesV1 } from "src/domain/entities/unifiedEntries"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { partyId?: string; subPartyId?: string; month: number; year: number }
	include?: {
		subParty?: boolean
		inJobWorkItemSlips?: boolean
		outJobWorkItemSlips?: boolean
		inJobWorkBills?: boolean
		outJobWorkBills?: boolean
		saleBills?: boolean
		purchaseBills?: boolean
		inCashReceipts?: boolean
		outCashReceipts?: boolean
	}
}

interface ResponseDataShape {
	success: boolean
	data: IUnifiedMonthEntriesV1
}

export async function unifiedMonthEntriesGetApi(
	payload: RequestDataShape,
	token: string | null,
) {
	const endpoint = "/unified/month/entries/get/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
