import { useAuthContext } from "src/components/context/auth"
import { useNavigate } from "react-router-dom"

export function useAuth() {
	const { company, token } = useAuthContext()
	const navigate = useNavigate()
	if (token && company) return { company, token }

	if (!token) navigate("/login")
	if (!company) navigate("/company")
	if (company?.isExpired) navigate("/company/expired")

	throw new Error("No auth")
}
