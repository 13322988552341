import { Box, Button, Flex, IconButton, Input, useDisclosure } from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import { FC, useCallback, useEffect, useState } from "react"
import { BsCheck, BsX } from "react-icons/bs"
import { useAuthContext } from "src/components/context/auth"
import { UserPasswordUpdateDrawerFormController } from "src/components/pages/SettingsPage/UserPasswordUpdateDrawerForm"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { companySelfUpdateService } from "src/domain/services/company/companySelfUpdateService"
import { userSelfGetService } from "src/domain/services/user/userSelfGetService"
import { useAuth } from "src/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { CompanySelfDeleteDialogController } from "./CompanySelfDeleteDialogController"

interface ICompanyUpdateFormFields {
	name?: string
	defaultPrefix?: string
}

export const SettingsPage: FC = () => {
	const updatePasswordDrawer = useDisclosure()
	const [isCompanySelfDeleteDialogOpen, setIsCompanySelfDeleteDialogOpen] =
		useState(false)

	const [showCompanyDetailsUpdateForm, setShowCompanyDetailsUpdateForm] =
		useState(false)

	const { company, token } = useAuth()

	const { setCompany } = useAuthContext()
	const fetchCompanyDetails = useCallback(async () => {
		const { company } = await userSelfGetService(token)
		setCompany(company)
	}, [token, setCompany])

	useEffect(() => {
		fetchCompanyDetails()
	}, [fetchCompanyDetails])

	const handleCompanyUpdateSubmit = useCallback(
		async (
			values: ICompanyUpdateFormFields,
			helpers: FormikHelpers<ICompanyUpdateFormFields>,
		) => {
			await companySelfUpdateService({ update: values }, token)
			await fetchCompanyDetails()
			helpers.setSubmitting(false)
			setShowCompanyDetailsUpdateForm(false)
		},
		[token, fetchCompanyDetails],
	)

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Box padding={2} borderBottom="1px" borderBottomColor="gray.300">
					<Box fontSize="2xl" fontWeight={"bold"} lineHeight={"1.2"}>
						Settings Page
					</Box>
					<Box color="gray.600">This is the settings page.</Box>
				</Box>
				<Box
					marginY={5}
					width={"fit-content"}
					borderWidth={1.5}
					borderColor="gray.300"
					borderRadius="lg"
				>
					<Box padding={4}>
						<Formik<ICompanyUpdateFormFields>
							initialValues={{
								name: company.name,
								defaultPrefix: company.defaultPrefix,
							}}
							onSubmit={handleCompanyUpdateSubmit}
							enableReinitialize={true}
						>
							{({ values, isSubmitting, handleChange }) => (
								<Form>
									<Flex
										gridGap={2}
										align="end"
										justify={"space-between"}
									>
										<Box flex={1}>
											<Box fontSize={"sm"}>Company Name</Box>
											<Box fontWeight={"semibold"}>
												{!showCompanyDetailsUpdateForm ? (
													company.name
												) : (
													<Input
														name="name"
														value={values.name}
														onChange={handleChange}
														width="auto"
														size={"sm"}
													/>
												)}
											</Box>
										</Box>
										<Box flex={1}>
											<Box fontSize={"sm"}>Default Prefix</Box>
											<Box fontWeight={"semibold"}>
												{!showCompanyDetailsUpdateForm ? (
													company.defaultPrefix
												) : (
													<Input
														name="defaultPrefix"
														value={values.defaultPrefix}
														onChange={handleChange}
														width="auto"
														size={"sm"}
													/>
												)}
											</Box>
										</Box>
										<Box>
											{!showCompanyDetailsUpdateForm ? (
												<EditIconButton
													onClick={() => {
														setShowCompanyDetailsUpdateForm(
															true,
														)
													}}
												/>
											) : (
												<>
													<IconButton
														aria-label="submit"
														variant="ghost"
														icon={<BsCheck size={24} />}
														size={"sm"}
														mx="1"
														colorScheme={"green"}
														isLoading={isSubmitting}
														type="submit"
													/>
													<IconButton
														aria-label="cancel"
														variant="ghost"
														icon={<BsX size={24} />}
														size={"sm"}
														mx="1"
														colorScheme={"red"}
														onClick={() => {
															setShowCompanyDetailsUpdateForm(
																false,
															)
														}}
													/>
												</>
											)}
										</Box>
									</Flex>
								</Form>
							)}
						</Formik>
					</Box>
					<Box padding={4} borderTopWidth={0.5}>
						<Flex gridGap={2}>
							<Button
								colorScheme="blue"
								variant="outline"
								onClick={updatePasswordDrawer.onOpen}
							>
								Update Password
							</Button>
							<Button
								colorScheme="red"
								variant="outline"
								onClick={() => setIsCompanySelfDeleteDialogOpen(true)}
							>
								Delete Company and Data
							</Button>
						</Flex>
					</Box>
				</Box>
			</Box>
			<>
				<UserPasswordUpdateDrawerFormController {...updatePasswordDrawer} />
				<CompanySelfDeleteDialogController
					isOpen={isCompanySelfDeleteDialogOpen}
					setIsOpen={setIsCompanySelfDeleteDialogOpen}
				/>
			</>
		</DashboardWrapper>
	)
}
