import { unifiedMonthEntriesGetApi } from "src/domain/api/unifiedEntries/unifiedMonthEntriesGetApi"

type PayloadShape = Parameters<typeof unifiedMonthEntriesGetApi>[0]

export async function unifiedMonthEntriesGetService(
	payload: PayloadShape,
	token: string | null,
) {
	return await unifiedMonthEntriesGetApi(payload, token)
}
