import { AddIcon } from "@chakra-ui/icons"
import { IconButton, useDisclosure } from "@chakra-ui/react"
import { FC } from "react"
import { IPartyV1 } from "src/domain/entities"
import { PartyAddDrawerFormController } from "../pages/PartyPage/PartyAddDrawerForm"

export const PartyAddButton: FC<{ onSuccess: (party: IPartyV1) => void }> = ({
	onSuccess,
}) => {
	const partyAddDrawerDisclosure = useDisclosure()

	return (
		<>
			<IconButton
				aria-label="add-party"
				icon={<AddIcon />}
				onClick={partyAddDrawerDisclosure.onOpen}
				title="Add Party"
			/>
			{partyAddDrawerDisclosure.isOpen && (
				<PartyAddDrawerFormController
					{...partyAddDrawerDisclosure}
					onSuccess={onSuccess}
				/>
			)}
		</>
	)
}

export const getPartyOption = (partyId: string, partyList?: IPartyV1[]) => {
	if (!partyList) return undefined
	const party = partyList.find((party) => party.id === partyId)
	if (!party) return undefined

	return {
		label: party.name,
		value: party.id,
	}
}
