import { companyMonthListApi } from "src/domain/api/company/companyMonthListApi"

type PayloadShape = Parameters<typeof companyMonthListApi>[0]

export async function companyMonthListService(
	payload: PayloadShape,
	token?: null | string,
) {
	return await companyMonthListApi(payload, token)
}
