import { productUpdateApi } from "../../api/product/productUpdateApi"

export async function productUpdateService(
	payload: {
		filter: {
			id: string
		}
		update: {
			name?: string
		}
	},
	token: string,
) {
	return await productUpdateApi(payload, token)
}
