import { IPartyMonthStatementV3 } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	partyMonthId: string
}

interface ResponseDataShape {
	success: boolean
	data: IPartyMonthStatementV3
}

export async function partyMonthStatementGetApi(
	payload: RequestDataShape,
	token: string | null,
) {
	const endpoint = "/party/month/statement/v3"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
