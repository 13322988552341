import { jobWorkItemSlipTransferApi } from "src/domain/api/jobWorkItem/slip/jobWorkItemSlipTransferApi"

type PayloadShape = Parameters<typeof jobWorkItemSlipTransferApi>[0]

export async function jobWorkItemSlipTransferService(
	payload: PayloadShape,
	token: string,
) {
	return await jobWorkItemSlipTransferApi(payload, token)
}
