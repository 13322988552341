import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useState } from "react"
import { BsArrowBarDown, BsArrowBarUp } from "react-icons/bs"
import { EItemType } from "src/components/entries/EItemType"
import { ItemSlipDeleteDialogController } from "src/components/entries/itemSlip/ItemSlipDeleteDialogController"
import { ItemSlipUpdateDrawerFormController } from "src/components/entries/itemSlip/ItemSlipUpdateDrawerForm"
import { JobWorkItemSlipAddDrawerFormController } from "src/components/entries/itemSlip/JobWorkItemSlipAddDrawerForm/JobWorkItemSlipAddDrawerFormController"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IJobWorkItemSlipV2, IJobWorkItemV2 } from "src/domain/entities"
import { ColumnItemsSection } from "."
import { usePartyDetailsPageContext } from "../PartyDetailsPageContext"

export const PartyMonthColumnView: FC<{}> = () => {
	const { selectedSubParty, statement, isStatementLoading, refreshPageData } =
		usePartyDetailsPageContext()

	const [selectedItemType, setSelectedItemType] = useState<EItemType | null>(null)
	const itemSlipEditDisclosure = useDisclosure()
	const [selectedItem, setSelectedItem] = useState<IJobWorkItemV2 | null>(null)
	const [selectedItemSlip, setSelectedItemSlip] = useState<IJobWorkItemSlipV2 | null>(
		null,
	)
	const [isItemSlipDeleteDialogOpen, setIsItemSlipDeleteDialogOpen] = useState(false)

	const handleItemSlipAddClick = (type: EItemType, item: typeof selectedItem) => {
		setSelectedItemType(type)
		setSelectedItem(item)
		itemSlipAddDisclosure.onOpen()
	}
	const handleItemSlipEditClick = (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		item: typeof selectedItem,
	) => {
		itemSlipEditDisclosure.onOpen()
		setSelectedItemSlip(slip)
		setSelectedItemType(type)
		setSelectedItem(item)
	}

	const handleItemSlipDeleteClick = (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		item: typeof selectedItem,
	) => {
		setSelectedItemSlip(slip)
		setSelectedItemType(type)
		setSelectedItem(item)
		setIsItemSlipDeleteDialogOpen(true)
	}

	const itemSlipAddDisclosure = useDisclosure()

	if (isStatementLoading) return <CenteredSpinner />

	if (!statement) return null
	let subPartyMonthStatementList = statement.subPartyMonthStatementList

	if (selectedSubParty) {
		subPartyMonthStatementList = subPartyMonthStatementList.filter(
			(el) => el.subParty.id === selectedSubParty.id,
		)
	}

	return (
		<Box>
			<Flex>
				<Box flex={1} marginRight={2}>
					<Flex align={"center"}>
						<Text fontSize="lg" fontWeight="bold">
							Outgoing
						</Text>
						<Box marginLeft={2}>
							<BsArrowBarUp size={22} />
						</Box>
					</Flex>
					{subPartyMonthStatementList.map((subPartyMonthStatement) => {
						// Filling sub party in items
						subPartyMonthStatement.outJobWorkItemList.map(
							(outJobWorkItem) => {
								outJobWorkItem.subPartyMonth =
									subPartyMonthStatement.subPartyMonth
								outJobWorkItem.subPartyMonth.subParty =
									subPartyMonthStatement.subParty

								return outJobWorkItem
							},
						)

						return (
							<Box key={subPartyMonthStatement.subParty.id}>
								<ColumnItemsSection
									items={
										subPartyMonthStatement.outJobWorkItemList ?? []
									}
									type={EItemType.OUTWARD}
									subParty={subPartyMonthStatement.subParty}
									hasSubPartySelected={selectedSubParty !== null}
									onItemSlipAddClick={handleItemSlipAddClick}
									onItemSlipEditClick={handleItemSlipEditClick}
									onItemSlipDeleteClick={handleItemSlipDeleteClick}
								/>
								{/* <SaleItemsSection
										items={subPartyMonthStatement.sale.items ?? []}
										subParty={subPartyMonthStatement.subParty}
										onItemSlipEditClick={handleItemSlipEditClick}
										onItemSlipDeleteClick={handleItemSlipDeleteClick}
									/> */}
							</Box>
						)
					})}
				</Box>
				<Box flex={1} marginLeft={2}>
					<Flex align={"center"}>
						<Text fontSize="lg" fontWeight="bold">
							Incoming
						</Text>
						<Box marginLeft={2}>
							<BsArrowBarDown size={22} />
						</Box>
					</Flex>
					{subPartyMonthStatementList.map((subPartyMonthStatement) => {
						// Filling sub party in items
						subPartyMonthStatement.inJobWorkItemList.map((inJobWorkItem) => {
							inJobWorkItem.subPartyMonth =
								subPartyMonthStatement.subPartyMonth
							inJobWorkItem.subPartyMonth.subParty =
								subPartyMonthStatement.subParty

							return inJobWorkItem
						})

						return (
							<Box key={subPartyMonthStatement.subParty.id}>
								<ColumnItemsSection
									items={subPartyMonthStatement.inJobWorkItemList ?? []}
									type={EItemType.INWARD}
									hasSubPartySelected={selectedSubParty != null}
									subParty={subPartyMonthStatement.subParty}
									onItemSlipAddClick={handleItemSlipAddClick}
									onItemSlipEditClick={handleItemSlipEditClick}
									onItemSlipDeleteClick={handleItemSlipDeleteClick}
								/>
								{/* <PurchaseItemsSection
										items={
											subPartyMonthStatement.purchase.items ?? []
										}
										subParty={subPartyMonthStatement.subParty}
										onItemSlipEditClick={handleItemSlipEditClick}
										onItemSlipDeleteClick={handleItemSlipDeleteClick}
									/> */}
							</Box>
						)
					})}
				</Box>
			</Flex>
			{(selectedItemType === EItemType.INWARD ||
				selectedItemType === EItemType.OUTWARD) && (
				<>
					{selectedItemSlip && selectedItem && itemSlipEditDisclosure.isOpen && (
						<ItemSlipUpdateDrawerFormController
							{...itemSlipEditDisclosure}
							type={selectedItemType}
							jobWorkItem={selectedItem}
							slip={selectedItemSlip}
							onSuccess={() => {
								itemSlipEditDisclosure.onClose()
								refreshPageData()
								setSelectedItemSlip(null)
							}}
						/>
					)}
					{selectedItemSlip && selectedItem && isItemSlipDeleteDialogOpen && (
						<ItemSlipDeleteDialogController
							isOpen={isItemSlipDeleteDialogOpen}
							setIsOpen={setIsItemSlipDeleteDialogOpen}
							type={selectedItemType}
							slip={selectedItemSlip}
							onSuccess={() => {
								refreshPageData()
								setSelectedItemSlip(null)
							}}
						/>
					)}
					{itemSlipAddDisclosure.isOpen && (
						<JobWorkItemSlipAddDrawerFormController
							{...itemSlipAddDisclosure}
							onSuccess={() => {
								itemSlipAddDisclosure.onClose()
								refreshPageData()
							}}
							type={selectedItemType}
							prefillData={{
								productId: selectedItem?.product?.id,
								subPartyId: selectedItem?.subPartyMonth?.subParty?.id,
								workRate: selectedItem?.workRate,
								lossPercentage: selectedItem?.lossPercentage,
								marketRate: selectedItem?.marketRate,
							}}
						/>
					)}
				</>
			)}
		</Box>
	)
}
