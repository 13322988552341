import { Text } from "@chakra-ui/react"
import { FC } from "react"

export const JobWorkLogo: FC<{ fontSize?: string }> = ({ fontSize }) => {
	return (
		<Text
			as="span"
			width={"fit-content"}
			bgGradient="linear(to-br, #00E8FF, #FF00F7)"
			bgClip="text"
			fontSize={fontSize ?? { base: "5xl", lg: "6xl" }}
			fontWeight={"bold"}
			lineHeight={"shorter"}
		>
			21 JobWork
		</Text>
	)
}
