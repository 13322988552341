import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "src/components/context/auth"
import { userAuthService } from "src/domain/services/user/userAuthService"
import { FormikOnSubmit } from "src/utils/types"
import { ILoginFormFields, LoginFormView } from "."

export const LoginFormContainer: FC = () => {
	const { setToken, setUser, setCompany } = useAuthContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback<FormikOnSubmit<ILoginFormFields>>(
		async (values, { setErrors }) => {
			try {
				const { token, user, company } = await userAuthService(values)
				setToken(token)
				setUser(user)
				setCompany(company)

				if (company) {
					if (company?.isExpired) {
						navigate("/company/expired")
					} else {
						navigate("/")
					}
				} else navigate("/company")
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ password: message })
			}
		},
		[navigate, setToken, setUser, setCompany],
	)

	return <LoginFormView handleSubmit={handleSubmit} />
}
