import { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import {
	IJobWorkItemV2,
	IPartyMonthStatementV3,
	IPartyMonthV1,
	IPartyV1,
	ISubPartyV1,
} from "src/domain/entities"
import {
	usePartyGetService,
	usePartyMonthListService,
	usePartyMonthStatementService,
	useSubPartyListService,
} from "src/hooks"
import { EItemType } from "../../entries/EItemType"

interface IPartyDetailsPageState {
	partyId?: string
	party?: IPartyV1
	isPartyLoading: boolean
	subPartyList: ISubPartyV1[]
	isSubPartyListLoading: boolean
	selectedSubParty?: ISubPartyV1
	setSelectedSubParty: (subParty?: ISubPartyV1) => void
	partyMonthList: IPartyMonthV1[]
	isPartyMonthListLoading: boolean
	selectedPartyMonth?: IPartyMonthV1
	setSelectedPartyMonth: (partyMonth?: IPartyMonthV1) => void
	refreshPageData: () => void
	selectedItemType?: EItemType
	setSelectedItemType: (itemType?: EItemType) => void
	selectedItem?: IJobWorkItemV2
	setSelectedItem: (type: EItemType, item: this["selectedItem"]) => void
	statement?: IPartyMonthStatementV3
	fetchStatement: () => void
	isStatementLoading: boolean
	isFirstLoad: boolean
	setIsFirstLoad: (isFirstLoad: boolean) => void
}

const PartyDetailsPageContext = createContext<IPartyDetailsPageState>({
	partyId: undefined,
	party: undefined,
	isPartyLoading: false,
	subPartyList: [],
	isSubPartyListLoading: false,
	selectedSubParty: undefined,
	setSelectedSubParty: () => {},
	partyMonthList: [],
	isPartyMonthListLoading: false,
	selectedPartyMonth: undefined,
	setSelectedPartyMonth: () => {},
	refreshPageData: () => {},
	selectedItemType: undefined,
	setSelectedItemType: () => {},
	selectedItem: undefined,
	setSelectedItem: () => {},
	statement: undefined,
	fetchStatement: () => {},
	isStatementLoading: false,
	isFirstLoad: true,
	setIsFirstLoad: () => {},
})

export const PartyDetailsPageContextProvider: FC<{ partyId: string }> = ({
	partyId,
	children,
	...rest
}) => {
	const [party, isPartyLoading, fetchParty] = usePartyGetService()
	const [subPartyList, isSubPartyListLoading, fetchSubPartyList] =
		useSubPartyListService()
	const [partyMonthList, isPartyMonthListLoading, fetchPartyMonthList] =
		usePartyMonthListService()

	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
	const [selectedSubParty, setSelectedSubParty] = useState<ISubPartyV1>()
	const [selectedPartyMonth, setSelectedPartyMonth] = useState<IPartyMonthV1>()
	const [selectedItemType, setSelectedItemType] = useState<EItemType>()
	const [selectedItem, setSelectedItem] =
		useState<IPartyDetailsPageState["selectedItem"]>()

	const [statement, isStatementLoading, fetchStatementCore] =
		usePartyMonthStatementService()

	const fetchStatement = useCallback(async () => {
		if (selectedPartyMonth) {
			fetchStatementCore(selectedPartyMonth.id)
		}
	}, [selectedPartyMonth, fetchStatementCore])

	const refreshPageData = useCallback(() => {
		if (partyId) {
			fetchParty(partyId)
			fetchSubPartyList({ partyId })
			fetchPartyMonthList({ filter: { partyId } })
			fetchStatement()
		}
	}, [partyId, fetchParty, fetchSubPartyList, fetchPartyMonthList, fetchStatement])

	const handleSetSelectedItem = useCallback(
		(type: EItemType, item: IPartyDetailsPageState["selectedItem"]) => {
			setSelectedItem(item)
			setSelectedItemType(type)
		},
		[setSelectedItem, setSelectedItemType],
	)

	useEffect(() => {
		if (!selectedPartyMonth && partyMonthList.length > 0) {
			setSelectedPartyMonth(partyMonthList[0])
		}
		if (isFirstLoad) {
			setIsFirstLoad(false)
		}
	}, [selectedPartyMonth, partyMonthList, isFirstLoad, setIsFirstLoad])

	return (
		<PartyDetailsPageContext.Provider
			value={{
				partyId,
				party,
				isPartyLoading,
				subPartyList,
				isSubPartyListLoading,
				selectedSubParty,
				setSelectedSubParty,
				partyMonthList,
				isPartyMonthListLoading,
				selectedPartyMonth,
				setSelectedPartyMonth,
				refreshPageData,
				selectedItemType,
				setSelectedItemType,
				selectedItem,
				setSelectedItem: handleSetSelectedItem,
				statement,
				fetchStatement,
				isStatementLoading,
				isFirstLoad,
				setIsFirstLoad,
			}}
			{...rest}
		>
			{children}
		</PartyDetailsPageContext.Provider>
	)
}

export function usePartyDetailsPageContext() {
	return useContext(PartyDetailsPageContext)
}
