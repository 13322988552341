import { AddIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	FormControl,
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { DrawerBasic } from "src/components/ui"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { InputLabel } from "src/components/ui/InputLabel"
import { IJobWorkItemSlipV2, IJobWorkItemV2 } from "src/domain/entities"
import { formatDate, toKGs } from "src/utils/helpers"
import { EItemType } from "../../../../../entries/EItemType"
import { IItemUpdateFormFields } from "./IItemUpdateFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerBasic>, "children"> {
	type: EItemType
	item: IJobWorkItemV2
	onAddClick?: () => void
	onItemSlipClick?: (slip: IJobWorkItemSlipV2) => void
	onItemUpdateSubmit?: (values: IItemUpdateFormFields) => void
	onEditClick?: (slip: IJobWorkItemSlipV2, jobWorkItem: IJobWorkItemV2) => void
	onDeleteClick?: (slip: IJobWorkItemSlipV2, jobWorkItem: IJobWorkItemV2) => void
}

export const ItemSlipListDrawer: FC<Props> = (props) => {
	return (
		<DrawerBasic
			{...props}
			headerLabel={props.item.product?.name ?? "Item Slip list"}
			size="xl"
		>
			<Box>
				<Box>
					<Formik<IItemUpdateFormFields>
						initialValues={{
							workRate: props.item.workRate,
							lossPercentage: props.item.lossPercentage,
							marketRate: props.item.marketRate,
							isLossInclusive: props.item.isLossInclusive,
						}}
						onSubmit={(values) => props.onItemUpdateSubmit?.(values)}
						enableReinitialize
					>
						{({ values, isSubmitting, handleChange }) => (
							<Form>
								<Flex alignItems="end" gridGap={2}>
									{/* Work Rate */}
									<FormControl flex={2}>
										<InputLabel label="Rate" />
										<Input
											name="workRate"
											autoFocus
											value={values.workRate}
											onChange={handleChange}
										/>
									</FormControl>
									{/* Loss Percentage */}
									{values.lossPercentage !== undefined && (
										<FormControl flex={2}>
											<InputLabel label="Loss Percentage" />
											<Input
												name="lossPercentage"
												value={values.lossPercentage}
												onChange={handleChange}
											/>
										</FormControl>
									)}
									{/* Market Rate */}
									{values.marketRate !== undefined && (
										<FormControl flex={2}>
											<InputLabel label="Market Rate" />
											<Input
												name="marketRate"
												value={values.marketRate}
												onChange={handleChange}
											/>
										</FormControl>
									)}
									{/* Actions */}
									<Box flex={1} textAlign="center">
										<Button
											colorScheme="blue"
											type="submit"
											disabled={isSubmitting}
											isLoading={isSubmitting}
										>
											Update
										</Button>
									</Box>
								</Flex>
							</Form>
						)}
					</Formik>
				</Box>
				<Box
					borderBottom="1px"
					borderBottomColor="gray.200"
					marginTop={30}
					paddingBottom={1}
				>
					<Flex justify="space-between">
						<Box fontSize="xl" fontWeight="semibold">
							Slips
						</Box>
						<Box>
							<Button
								colorScheme="blue"
								variant="ghost"
								leftIcon={<AddIcon />}
								onClick={props.onAddClick}
								size="sm"
							>
								Add
							</Button>
						</Box>
					</Flex>
				</Box>
				<Table variant="simple" size="sm" margin={0}>
					<Thead>
						<Tr>
							<Th paddingY="2">Date</Th>
							<Th paddingY="2">#</Th>
							<Th paddingY="2" isNumeric>
								Gross
								<br />
								Quantity
							</Th>
							<Th paddingY="2" isNumeric>
								Bag
								<br />
								Weight
							</Th>
							<Th paddingY="2" isNumeric>
								Rejection
							</Th>
							<Th paddingY="2" isNumeric>
								Net
								<br />
								Quantity
							</Th>
							<Th paddingY="2"></Th>
						</Tr>
					</Thead>
					<Tbody>
						{/* Slips */}
						{props.item.slips?.map((slip, i) => (
							<ItemSlipRow
								key={i}
								slip={slip}
								item={props.item}
								onEditClick={() => {
									if (props.onEditClick) {
										props.onEditClick(slip, props.item)
									}
								}}
								onDeleteClick={() => {
									if (props.onDeleteClick) {
										props.onDeleteClick(slip, props.item)
									}
								}}
							/>
						))}
						{/* Total */}
						{/* <TotalRow stats={stats} /> */}
					</Tbody>
				</Table>
			</Box>
		</DrawerBasic>
	)
}

interface ItemSlipRowProps {
	slip: IJobWorkItemSlipV2
	item: IJobWorkItemV2
	action?: JSX.Element | string
	onClick?: Props["onItemSlipClick"]
	onEditClick?: Props["onEditClick"]
	onDeleteClick?: Props["onDeleteClick"]
}

const ItemSlipRow: FC<ItemSlipRowProps> = ({
	slip,
	item,
	onEditClick,
	onDeleteClick,
	...rest
}) => (
	<Tr
		onClick={() => {
			rest.onClick && rest.onClick(slip)
		}}
		cursor="pointer"
		_hover={{ backgroundColor: "gray.100" }}
	>
		<Td>{formatDate(slip.issueDate)}</Td>
		<Td>
			<Box>{slip.prefix + "-" + slip.slipNo}</Box>
			<Box>{slip.note}</Box>
		</Td>
		<Td isNumeric>{toKGs(slip.grossQuantity, { dashIfZero: true }) ?? "-"}</Td>
		<Td isNumeric>{toKGs(slip.bagQuantity, { dashIfZero: true }) ?? "-"}</Td>
		<Td isNumeric>{toKGs(slip.rejectedQuantity, { dashIfZero: true }) ?? "-"}</Td>
		<Td isNumeric>
			{toKGs(slip.netQuantity, { dashIfZero: true }) ?? "-"}
			{item.lossPercentage && (
				<Box color={"red.600"} fontSize={"xs"}>
					{item.isLossInclusive
						? `-${toKGs((slip.netQuantity * item.lossPercentage) / 100)}`
						: `+${toKGs((slip.netQuantity * item.lossPercentage) / 100)}`}
				</Box>
			)}
		</Td>
		<Td isNumeric>
			<EditIconButton onClick={onEditClick} />
			<DeleteIconButton onClick={onDeleteClick} />
		</Td>
	</Tr>
)
