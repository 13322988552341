import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import {
	CompanyPage,
	HomePage,
	LoginPage,
	LogoutPage,
	NotFoundPage,
	PartyDetailPage,
	PartyPage,
	ProductPage,
	RegisterPage,
	SettingsPage,
} from "src/components/pages"
import { AdminAuthContextProvider } from "./components/context/adminAuth"
import { AuthContextProvider } from "./components/context/auth"
import { AdminLoginPage } from "./components/pages/AdminLoginPage"
import { CompaniesPage } from "./components/pages/CompaniesPage"
import { CompanyExpiredPage } from "./components/pages/CompanyPage/CompanyExpiredPage"
import { EntriesPage } from "./components/pages/EntriesPage/EntriesPage"
import { ForgotPasswordPage } from "./components/pages/ForgotPasswordPage/ForgotPasswordPage"
import { AdminPrivateRoute } from "./components/shared/AdminPrivateRoute"
import { PrivateRoute } from "./components/shared/PrivateRoute"

export const App: React.FC = () => (
	<ChakraProvider>
		<AuthContextProvider>
			<AdminAuthContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/admin/login" element={<AdminLoginPage />} />
						<Route
							path="/admin/companies"
							element={<AdminPrivateRoute element={<CompaniesPage />} />}
						/>
						<Route path="/company/expired" element={<CompanyExpiredPage />} />
						<Route path="/register" element={<RegisterPage />} />
						<Route path="/forgot-password" element={<ForgotPasswordPage />} />
						<Route path="/logout" element={<LogoutPage />} />
						<Route path="/company" element={<CompanyPage />} />
						<Route
							path="/"
							element={<PrivateRoute element={<HomePage />} />}
						/>
						<Route
							path="/party"
							element={<PrivateRoute element={<PartyPage />} />}
						/>
						<Route
							element={<PrivateRoute element={<PartyDetailPage />} />}
							path="/party/:id"
						/>
						<Route
							path="/product"
							element={<PrivateRoute element={<ProductPage />} />}
						/>
						<Route
							path="/entries"
							element={<PrivateRoute element={<EntriesPage />} />}
						/>
						<Route
							path="/settings"
							element={<PrivateRoute element={<SettingsPage />} />}
						/>

						<Route element={<NotFoundPage />} />
					</Routes>
				</BrowserRouter>
			</AdminAuthContextProvider>
		</AuthContextProvider>
	</ChakraProvider>
)
