import { subPartyUpdateApi } from "../../api/subParty/subPartyUpdateApi"

export async function subPartyUpdateService(
	payload: {
		filter: {
			id: string
		}
		update: {
			name?: string
			note?: string
		}
	},
	token: string,
) {
	if (!payload.update.note) {
		payload.update.note = undefined
	}
	return await subPartyUpdateApi(payload, token)
}
