import { partyUpdateApi } from "../../api/party/partyUpdateApi"

export async function partyUpdateService(
	payload: {
		filter: {
			id: string
		}
		update: {
			name?: string
			note?: string
		}
	},
	token: string,
) {
	return await partyUpdateApi(payload, token)
}
