import {
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IJobWorkBillV2 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IJobWorkBillUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	type: EItemType
	bill: IJobWorkBillV2
	headerLabel: string
	handleSubmit: FormikOnSubmit<IJobWorkBillUpdateFormFields["update"]>
}

export const JobWorkBillUpdateDrawerFormView: FC<Props> = ({
	bill,
	headerLabel,
	handleSubmit,
	...rest
}) => (
	<Formik<IJobWorkBillUpdateFormFields["update"]>
		initialValues={{
			note: bill.note,
			fullBillNo: bill.fullBillNo,
			quantity: bill.quantity,
			workRate: bill.workRate,
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel={headerLabel}
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Full Bill no */}
					<FormControl flex={2}>
						<InputLabel label="Bill No" />
						<Input
							name="fullBillNo"
							placeholder="Bill No."
							maxLength={20}
							required
							value={values.fullBillNo}
							onChange={handleChange}
						/>
					</FormControl>
					<Flex>
						{/* Labour Rate */}
						<FormControl flex={1}>
							<InputLabel label="Labour Rate" suffixLabel="(per KG)" />
							<InputGroup>
								<InputLeftAddon children="₹" />
								<Input
									name="workRate"
									required
									value={values.workRate}
									onChange={handleChange}
								/>
							</InputGroup>
						</FormControl>
						{/* Quantity */}
						<FormControl flex={1} marginLeft={2}>
							<InputLabel label="Quantity" />
							<InputGroup>
								<Input
									name="quantity"
									required
									value={values.quantity}
									onChange={handleChange}
								/>
								<InputRightAddon children="KG" />
							</InputGroup>
						</FormControl>
					</Flex>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							onChange={handleChange}
							value={values.note ?? ""}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)
