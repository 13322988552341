import { useDisclosure } from "@chakra-ui/react"
import { FC, useEffect } from "react"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { EItemType } from "../../../entries/EItemType"
import { usePartyDetailsPageContext } from "../PartyDetailsPageContext"
import { SubPartyStatementPrintView } from "../Print/SubPartyStatementPrintView"
import { CashReceiptListController } from "./CashReceipt/CashReceiptListDrawer"
import { ItemSlipListController } from "./ItemSlip/ItemSlipListDrawer"
import { PartyMonthStatementView } from "./PartyMonthStatementView"

export const PartyMonthStatementController: FC<{ printable?: boolean }> = ({
	printable = false,
}) => {
	const {
		selectedPartyMonth,
		selectedSubParty,
		selectedItemType,
		selectedItem,
		setSelectedItem,
		setSelectedItemType,
	} = usePartyDetailsPageContext()
	const { statement, isStatementLoading } = usePartyDetailsPageContext()
	const itemSlipListDisclosure = useDisclosure()
	const cashReceiptListDisclosure = useDisclosure()

	useEffect(() => {
		if (!statement) return
		statement.subPartyMonthStatementList = statement.subPartyMonthStatementList.sort(
			(a, b) => {
				if (a.subParty.isDefault) return -1
				if (b.subParty.isDefault) return 1
				return 0
			},
		)
	}, [statement])

	if (isStatementLoading) return <CenteredSpinner />

	if (!statement || !selectedPartyMonth) return null

	const toShowSlipListController =
		selectedItemType &&
		[EItemType.INWARD, EItemType.OUTWARD].includes(selectedItemType) &&
		selectedItem &&
		itemSlipListDisclosure.isOpen

	if (printable) {
		return (
			<SubPartyStatementPrintView
				selectedSubParty={selectedSubParty}
				partyMonth={selectedPartyMonth}
				statement={statement}
			/>
		)
	}

	return (
		<>
			<PartyMonthStatementView
				selectedSubParty={selectedSubParty}
				partyMonth={selectedPartyMonth}
				statement={statement}
				onItemClick={(type, item, subPartyMonth) => {
					if (item && subPartyMonth) {
						if (!subPartyMonth.subParty) {
							subPartyMonth.subParty =
								statement.subPartyMonthStatementList[0].subParty
						}
						setSelectedItem(type, { ...item, subPartyMonth })
						itemSlipListDisclosure.onOpen()
					} else if (type) {
						setSelectedItemType(type)
						cashReceiptListDisclosure.onOpen()
					}
				}}
			/>
			{toShowSlipListController && (
				<ItemSlipListController
					{...itemSlipListDisclosure}
					type={selectedItemType}
					item={selectedItem}
				/>
			)}
			{selectedItemType &&
				[EItemType.INWARD_CASH, EItemType.OUTWARD_CASH].includes(
					selectedItemType,
				) && (
					<CashReceiptListController
						{...cashReceiptListDisclosure}
						type={selectedItemType}
						receipts={
							selectedItemType === EItemType.INWARD_CASH
								? statement.inCashList ?? []
								: statement.outCashList ?? []
						}
					/>
				)}
		</>
	)
}
