import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IProductV1 } from "src/domain/entities"

export const ProductListView: FC<{
	list: IProductV1[]
	onUpdate: (product: IProductV1) => void
	onDelete: (product: IProductV1) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((product, i) => (
				<Tr key={i}>
					<Td>{product.name}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(product)} />
						<DeleteIconButton onClick={() => onDelete(product)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
