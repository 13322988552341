import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	prefix: string
	issueDate: string
}

interface ResponseDataShape {
	success: boolean
	data: { nextSlipNo: number }
}

export async function itemSlipNextNoGetApi(
	payload: RequestDataShape,
	token: string | null,
) {
	const endpoint = "/itemSlip/nextNo/get/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
