import { Box, Text } from "@chakra-ui/react"
import { FC } from "react"

interface Props {
	value?: number
	formatter?: (val: number) => string
	headerText: string
	textColor?: string
	suffix?: string
}

export const HeaderStatBox: FC<Props> = ({
	value = 0,
	formatter,
	headerText,
	suffix,
	textColor,
}) => {
	const formattedValue = formatter ? formatter(value) : value
	return (
		<Box paddingX={4} textAlign="right">
			<Box>
				<Text fontSize="medium" fontWeight="semibold">
					{headerText}
				</Text>
			</Box>
			<Box color={textColor}>
				<Text as="span" fontSize="xl" lineHeight={1} fontWeight={"semibold"}>
					{formattedValue}
				</Text>
				{suffix && <Text as="span"> {suffix}</Text>}
			</Box>
		</Box>
	)
}
