import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useProductListService } from "src/hooks"
import { IProductV1 } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ProductAddDrawerFormController } from "./ProductAddDrawerForm"
import { ProductDeleteDialogController } from "./ProductDeleteDialogController"
import { ProductListController } from "./ProductList"
import { ProductUpdateDrawerFormController } from "./ProductUpdateDrawerForm"

export const ProductPage: FC = () => {
	const [productList, isLoading, fetchProductList] = useProductListService()
	const [isProductDeleteDialogOpen, setIsProductDeleteDialogOpen] = useState(false)
	const productAddDrawerDisclosure = useDisclosure()
	const productUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchProductList()
	}, [fetchProductList])

	const updateProductRef = useRef<IProductV1>()
	const deleteProductRef = useRef<IProductV1>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Products
					</Text>
					<Button
						size="sm"
						onClick={productAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Product
					</Button>
				</Flex>
			</Box>
			<ProductListController
				list={productList}
				isLoading={isLoading}
				onUpdate={(product: IProductV1) => {
					updateProductRef.current = product
					productUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(product: IProductV1) => {
					deleteProductRef.current = product
					setIsProductDeleteDialogOpen(true)
				}}
				onAddClick={productAddDrawerDisclosure.onOpen}
			/>
			<ProductAddDrawerFormController
				{...productAddDrawerDisclosure}
				onSuccess={() => fetchProductList()}
			/>
			{updateProductRef.current && (
				<ProductUpdateDrawerFormController
					{...productUpdateDrawerDisclosure}
					product={updateProductRef.current}
					onSuccess={() => fetchProductList()}
				/>
			)}
			{deleteProductRef.current && (
				<ProductDeleteDialogController
					isOpen={isProductDeleteDialogOpen}
					setIsOpen={setIsProductDeleteDialogOpen}
					product={deleteProductRef.current}
					onSuccess={() => fetchProductList()}
				/>
			)}
		</DashboardWrapper>
	)
}
