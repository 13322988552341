import { partyListApi } from "../../api/party/partyListApi"
import { IPagination, IPartyV1 } from "../../entities"

export async function partyListService(
	filter: { id?: string } & IPagination,
	token?: null | string,
) {
	const party = await partyListApi(
		{ filter, include: { details: true, stats: true } },
		token,
	)
	return party as NonNullable<IPartyV1[]>
}
