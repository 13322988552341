import { inwardJobWorkBillDeleteApi } from "../../api/inwardJobWorkBill/inwardJobWorkBillDeleteApi"

type PayloadShape = Parameters<typeof inwardJobWorkBillDeleteApi>[0]

export async function inwardJobWorkBillDeleteService(
	payload: PayloadShape,
	token: string,
) {
	return await inwardJobWorkBillDeleteApi(payload, token)
}
