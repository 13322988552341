import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import { AiFillHome } from "react-icons/ai"
import { FaBoxOpen } from "react-icons/fa"
import { IoLogOut } from "react-icons/io5"
import { MdBusinessCenter, MdReceipt, MdSettings } from "react-icons/md"
import { Header } from "../shared/Header"
import { NavItem, SideBar } from "../shared/SideBar"

const items: NavItem[] = [
	{ name: "Dashboard", link: "/", icon: AiFillHome },
	{ name: "Products", link: "/product", icon: FaBoxOpen },
	{ name: "Parties", link: "/party", icon: MdBusinessCenter },
	{ name: "Entries", link: "/entries", icon: MdReceipt },
	{ name: "Settings", link: "/settings", icon: MdSettings },
	{ name: "Logout", link: "/logout", icon: IoLogOut },
]

export const DashboardWrapper: FC = ({ children }) => {
	return (
		<Flex height="100vh" overflowY="hidden">
			<Header />
			<SideBar items={items} />
			<Box width="100%" overflowY="auto">
				{children}
			</Box>
		</Flex>
	)
}
