import { ApiRequester } from "../../ApiRequester"
import { EJobWorkItemType } from "../EJobWorkItemType"

interface RequestDataShape {
	itemType: EJobWorkItemType
	id: string
	jobWorkItemId: string
}

interface ResponseDataShape {
	success: boolean
}

export async function jobWorkItemSlipDeleteApi(payload: RequestDataShape, token: string) {
	const endpoint = "/jobWorkItem/slip/delete/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	await requester.sendRequest(payload, token)
}
