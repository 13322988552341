import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Input,
	InputGroup,
	InputRightAddon,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC, useMemo, useState } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { toINR, toKGs } from "src/utils/helpers"
import { IPartyV1 } from "../../../domain/entities/party"
import { DeleteIconButton } from "../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../ui/iconButtons/EditIconButton"

interface Props {
	list: IPartyV1[]
	onUpdate: (party: IPartyV1) => void
	onDelete: (party: IPartyV1) => void
	onSelect: (party: IPartyV1) => void
	onAddClick: () => void
}

export const PartyListView: FC<Props> = ({
	list,
	onUpdate,
	onDelete,
	onSelect,
	onAddClick,
}) => {
	const [searchText, setSearchText] = useState("")

	const filteredList = useMemo(() => {
		return list.filter((party) =>
			party.name.toLowerCase().includes(searchText.toLowerCase()),
		)
	}, [list, searchText])

	if (list.length === 0) {
		return <BlankStateSection message="No parties found." onAddClick={onAddClick} />
	}
	return (
		<>
			<Box px={2}>
				<InputGroup width={"fit-content"}>
					<Input
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						type="text"
						placeholder="Search"
					/>
					<InputRightAddon>
						<SearchIcon />
					</InputRightAddon>
				</InputGroup>
			</Box>
			<Table size="sm" fontSize={"lg"} mt={2}>
				<Thead>
					<Tr>
						<Th>Party Name</Th>
						<Th>Note</Th>
						<Th isNumeric>
							Quantity (KG)
							<br />
							to Receive
						</Th>
						<Th isNumeric>
							Amount
							<br />
							to Receive
						</Th>
						<Th isNumeric>
							Quantity (KG)
							<br />
							to Give
						</Th>
						<Th isNumeric>
							Amount
							<br />
							to Pay
						</Th>
						<Th isNumeric></Th>
					</Tr>
				</Thead>
				<Tbody>
					{filteredList.map((party) => {
						let note = party.details?.note ?? "-"
						if (note === "") note = "-"

						const closingReceivableAmount =
							party.stats?.closingReceivableAmount ?? 0
						const amountReceivable =
							closingReceivableAmount > 0
								? toINR(Math.abs(closingReceivableAmount))
								: "-"
						const amountPayable =
							closingReceivableAmount < 0
								? toINR(Math.abs(closingReceivableAmount))
								: "-"

						const closingReceivableQuantity =
							party.stats?.closingReceivableQuantity ?? 0
						const quantityReceivable =
							closingReceivableQuantity > 0
								? toKGs(Math.abs(closingReceivableQuantity))
								: "-"
						const quantityPayable =
							closingReceivableQuantity < 0
								? toKGs(Math.abs(closingReceivableQuantity))
								: "-"

						return (
							<Tr
								key={party.id}
								onClick={() => onSelect(party)}
								cursor="pointer"
								_hover={{ backgroundColor: "gray.100" }}
							>
								<Td fontWeight={"semibold"}>{party.name}</Td>
								<Td>{party.details?.note ?? ""}</Td>
								<Td isNumeric>{quantityReceivable}</Td>
								<Td isNumeric>{amountReceivable}</Td>
								<Td isNumeric>{quantityPayable}</Td>
								<Td isNumeric>{amountPayable}</Td>
								<Td isNumeric onClick={(e) => e.stopPropagation()}>
									<EditIconButton onClick={() => onUpdate(party)} />
									<DeleteIconButton onClick={() => onDelete(party)} />
								</Td>
							</Tr>
						)
					})}
					<PartyListTotal list={filteredList} />
				</Tbody>
			</Table>
		</>
	)
}

export const PartyListTotal: FC<{ list: IPartyV1[] }> = ({ list }) => {
	const stats: {
		totalAmountReceivable: number
		totalAmountPayable: number
		totalQuantityReceivable: number
		totalQuantityPayable: number
	} = {
		totalAmountReceivable: 0,
		totalAmountPayable: 0,
		totalQuantityReceivable: 0,
		totalQuantityPayable: 0,
	}

	list.forEach((party) => {
		let note = party.details?.note ?? "-"
		if (note === "") note = "-"

		const closingReceivableAmount = party.stats?.closingReceivableAmount ?? 0
		stats.totalAmountReceivable +=
			closingReceivableAmount > 0 ? Math.abs(closingReceivableAmount) : 0
		stats.totalAmountPayable +=
			closingReceivableAmount < 0 ? Math.abs(closingReceivableAmount) : 0

		const closingReceivableQuantity = party.stats?.closingReceivableQuantity ?? 0
		stats.totalQuantityReceivable +=
			closingReceivableQuantity > 0 ? Math.abs(closingReceivableQuantity) : 0
		stats.totalQuantityPayable +=
			closingReceivableQuantity < 0 ? Math.abs(closingReceivableQuantity) : 0
	})

	return (
		<Tr backgroundColor={"gray.100"}>
			<Td fontWeight={"semibold"}>Total</Td>
			<Td></Td>
			<Td isNumeric fontWeight={"semibold"}>
				{toKGs(stats.totalQuantityReceivable)}
			</Td>
			<Td isNumeric fontWeight={"semibold"}>
				{toINR(stats.totalAmountReceivable)}
			</Td>
			<Td isNumeric fontWeight={"semibold"}>
				{toKGs(stats.totalQuantityPayable)}
			</Td>
			<Td isNumeric fontWeight={"semibold"}>
				{toINR(stats.totalAmountPayable)}
			</Td>
			<Td></Td>
		</Tr>
	)
}
