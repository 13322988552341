import { ICompanyV2 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	include?: { audits?: boolean; stats?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: ICompanyV2
}

export async function companyCreateApi(payload: RequestDataShape, token: string | null) {
	const endpoint = "/company/create/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
