import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	companyId?: string
}

export async function companyStatsRefreshApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/company/stats/refresh/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	const response = await requester.sendRequest(payload, token)
	console.log("response", response)
}
