import { ApiRequester } from "../ApiRequester"
import { IBillV2 } from "./../../entities/bill"

interface RequestDataShape {
	filter: { id: string }
	update: {
		fullBillNo?: string
		saleRate?: number
		workRate?: number
		quantity?: number
		note?: null | string
	}
}

interface ResponseDataShape {
	success: boolean
	data: IBillV2
}

export async function purchaseBillUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/purchaseBill/update/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
