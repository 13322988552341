import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useAdminAuthContext } from "src/components/context/adminAuth"
import { adminAuthService } from "src/domain/services/admin/adminAuthService"
import { FormikOnSubmit } from "src/utils/types"
import { AdminLoginFormView, IAdminLoginFormFields } from "."

export const AdminLoginFormContainer: FC = () => {
	const { setToken } = useAdminAuthContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback<FormikOnSubmit<IAdminLoginFormFields>>(
		async (values, { setErrors }) => {
			try {
				const { token } = await adminAuthService(values)
				setToken(token)

				if (token) {
					navigate("/admin/companies")
				} else {
					throw new Error("Invalid Credentials")
				}
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ password: message })
			}
		},
		[setToken, navigate],
	)

	return <AdminLoginFormView handleSubmit={handleSubmit} />
}
