import { useAdminAuthContext } from "src/components/context/adminAuth"
import { useNavigate } from "react-router-dom"

export function useAdminAuth(): { token: string } {
	const { token } = useAdminAuthContext()
	const navigate = useNavigate()
	if (token) return { token }

	navigate("/admin/login")
	throw new Error("No auth")
}
