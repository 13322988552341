import { useCallback, useState } from "react"
import { ISubPartyV1 } from "src/domain/entities"
import { subPartyListService } from "src/domain/services/subParty/subPartyListService"
import { useAuth } from ".."

export function useSubPartyListService(): [
	ISubPartyV1[],
	boolean,
	(filter: Parameters<typeof subPartyListService>[0]) => void,
] {
	const { token } = useAuth()
	const [subPartyList, setSubPartyList] = useState<ISubPartyV1[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchSubPartyList = useCallback(
		async (filter: Parameters<typeof subPartyListService>[0]) => {
			setIsLoading(true)
			let data = await subPartyListService(filter, token)
			data = sortSubPartiesByPartyName(data)
			setSubPartyList(data)
			setIsLoading(false)
		},
		[token],
	)

	const sortSubPartiesByPartyName = (subParties: ISubPartyV1[]) => {
		return subParties.sort((a, b) => {
			if (!a.party || !b.party) {
				return 0
			}
			if (a.party.name < b.party.name) {
				return -1
			}
			if (a.party.name > b.party.name) {
				return 1
			}
			if (a.isDefault) return -1
			return 0
		})
	}

	return [subPartyList, isLoading, fetchSubPartyList]
}
