import { useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { PartySubPartySectionView } from "."
import { ISubPartyV1 } from "../../../../domain/entities"
import { usePartyDetailsPageContext } from "../PartyDetailsPageContext"
import { SubPartyAddDrawerFormController } from "./SubPartyAddDrawerForm"
import { SubPartyDeleteDialogController } from "./SubPartyDeleteDialogController"
import { SubPartyUpdateDrawerFormController } from "./SubPartyUpdateDrawerForm"

interface Props {
	partyId: string
	subPartyId?: string | null
}

export const PartySubPartySectionController: FC<Props> = ({ partyId, subPartyId }) => {
	const {
		subPartyList,
		isSubPartyListLoading,
		selectedSubParty,
		setSelectedSubParty,
		refreshPageData,
		isFirstLoad,
	} = usePartyDetailsPageContext()

	const subPartyAddDrawerDisclosure = useDisclosure()
	const subPartyUpdateDrawerDisclosure = useDisclosure()
	const [isPartyDeleteDialogOpen, setIsPartyDeleteDialogOpen] = useState(false)

	const updateSubPartyRef = useRef<ISubPartyV1>()
	const deleteSubPartyRef = useRef<ISubPartyV1>()

	useEffect(() => {
		if (subPartyId) {
			const selectedSubParty = subPartyList.find(
				(subParty) => subParty.id === subPartyId,
			)
			if (selectedSubParty) {
				setSelectedSubParty(selectedSubParty)
			}
		}

		// if (!selectedSubParty && subPartyList.length === 1) {
		// 	setSelectedSubParty(subPartyList[0])
		// }
	}, [subPartyId, selectedSubParty, subPartyList, setSelectedSubParty])

	if (isSubPartyListLoading && isFirstLoad) return <CenteredSpinner />

	return (
		<>
			<PartySubPartySectionView
				list={subPartyList}
				selectedSubParty={selectedSubParty}
				setSelectedSubParty={setSelectedSubParty}
				onCreate={() => subPartyAddDrawerDisclosure.onOpen()}
				onDelete={(subParty) => {
					deleteSubPartyRef.current = subParty
					setIsPartyDeleteDialogOpen(true)
				}}
				onUpdate={(subParty) => {
					updateSubPartyRef.current = subParty
					subPartyUpdateDrawerDisclosure.onOpen()
				}}
			/>

			<SubPartyAddDrawerFormController
				partyId={partyId}
				{...subPartyAddDrawerDisclosure}
				onSuccess={() => {
					subPartyAddDrawerDisclosure.onClose()
					refreshPageData()
				}}
			/>

			{updateSubPartyRef.current && (
				<SubPartyUpdateDrawerFormController
					subParty={updateSubPartyRef.current}
					{...subPartyUpdateDrawerDisclosure}
					onSuccess={async () => {
						updateSubPartyRef.current = undefined
						refreshPageData()
					}}
				/>
			)}

			{deleteSubPartyRef.current && (
				<SubPartyDeleteDialogController
					isOpen={isPartyDeleteDialogOpen}
					setIsOpen={setIsPartyDeleteDialogOpen}
					subParty={deleteSubPartyRef.current}
					onSuccess={async () => {
						if (
							selectedSubParty &&
							deleteSubPartyRef.current &&
							selectedSubParty.id === deleteSubPartyRef.current.id
						) {
							setSelectedSubParty()
						}
						deleteSubPartyRef.current = undefined
						refreshPageData()
					}}
				/>
			)}
		</>
	)
}
