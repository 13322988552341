import { ISubPartyMonthV2 } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id: string }
	update: { note?: string | null }
}

interface ResponseDataShape {
	success: boolean
	data: ISubPartyMonthV2
}

export async function subPartyMonthUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/subParty/month/update/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
