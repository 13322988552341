import { useCallback, useState } from "react"
import { IPartyV1 } from "src/domain/entities"
import { partyGetService } from "src/domain/services/party/partyGetService"
import { useAuth } from ".."

export function usePartyGetService(): [
	IPartyV1 | undefined,
	boolean,
	(id: string) => void,
] {
	const { token } = useAuth()
	const [party, setParty] = useState<IPartyV1>()
	const [isLoading, setIsLoading] = useState(false)

	const fetchParty = useCallback(
		async (id: string) => {
			setIsLoading(true)
			const data = await partyGetService(id, token)
			setParty(data)
			setIsLoading(false)
		},
		[token],
	)

	return [party, isLoading, fetchParty]
}
