import { ICompanyMonthV2, IPagination } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter?: {
		id?: string
		companyId?: string
		userId?: string
		userSessionId?: string
		year?: number
		month?: number
	} & IPagination
}

interface ResponseDataShape {
	success: boolean
	data: ICompanyMonthV2[]
}

export async function companyMonthListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/company/month/list/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
