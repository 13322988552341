import { Center } from "@chakra-ui/react"
import { FC } from "react"
import { ContainerWrapper } from "../../wrappers/ContainerWrapper"
import { ForgotPasswordFormContainer } from "./ForgotPasswordForm/ForgotPasswordFormContainer"

export const ForgotPasswordPage: FC = () => {
	return (
		<ContainerWrapper>
			<Center height={"100vh"}>
				<ForgotPasswordFormContainer />
			</Center>
		</ContainerWrapper>
	)
}
