import { IPartyV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	note?: string
	openingReceivableAmount?: number
	openingReceivableQuantity?: number
	include?: { details?: boolean; stats?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: IPartyV1
}

export async function partyAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/party/add/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
