import { outwardJobWorkBillDeleteApi } from "../../api/outwardJobWorkBill/outwardJobWorkBillDeleteApi"

type PayloadShape = Parameters<typeof outwardJobWorkBillDeleteApi>[0]

export async function outwardJobWorkBillDeleteService(
	payload: PayloadShape,
	token: string,
) {
	return await outwardJobWorkBillDeleteApi(payload, token)
}
