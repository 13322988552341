import { inwardCashReceiptDeleteApi } from "../../api/inwardCashReceipt/inwardCashReceiptDeleteApi"

type PayloadShape = Parameters<typeof inwardCashReceiptDeleteApi>[0]

export async function inwardCashReceiptDeleteService(
	payload: PayloadShape,
	token: string,
) {
	return await inwardCashReceiptDeleteApi(payload, token)
}
