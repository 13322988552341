import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { ICashReceiptV2 } from "src/domain/entities"
import { inwardCashReceiptDeleteService } from "src/domain/services/inwardCashReceipt/inwardCashReceiptDeleteService"
import { outwardCashReceiptDeleteService } from "src/domain/services/outwardCashReceipt/outwardCashReceiptDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { EItemType } from "../EItemType"

interface Props {
	type: EItemType
	slip: ICashReceiptV2
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const CashReceiptDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	type,
	slip: { id: slipId },
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleCashReceiptDelete = useCallback(async () => {
		try {
			let itemSlipDeleteService: typeof inwardCashReceiptDeleteService | undefined

			switch (type) {
				case EItemType.INWARD_CASH:
					itemSlipDeleteService = inwardCashReceiptDeleteService
					break
				case EItemType.OUTWARD_CASH:
					itemSlipDeleteService = outwardCashReceiptDeleteService
					break
			}

			if (!itemSlipDeleteService) {
				throw new Error("Invalid item type")
			}

			setIsLoading(true)
			await itemSlipDeleteService({ id: slipId }, token)
			setIsOpen(false)
			onSuccess?.()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [setIsOpen, token, onSuccess, slipId, type])

	return (
		<DeleteItemDialog
			title={getHeaderLabel(type)}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleCashReceiptDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}

function getHeaderLabel(type: EItemType) {
	switch (type) {
		case EItemType.INWARD_CASH:
			return "Delete Inward Cash Slip"
		case EItemType.OUTWARD_CASH:
			return "Delete Outward Cash Slip"
	}
	return "Delete Cash Slip"
}
