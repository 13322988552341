import { ComponentProps, FC, useCallback } from "react"
import { ISubPartyV1 } from "src/domain/entities"
import { subPartyUpdateService } from "src/domain/services/subParty/subPartyUpdateService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyUpdateFormFields, SubPartyUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof SubPartyUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (subParty: ISubPartyV1) => void
}

export const SubPartyUpdateDrawerFormController: FC<Props> = ({
	subParty,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISubPartyUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedSubParty = await subPartyUpdateService(
					{ filter: { id: subParty.id }, update: values },
					token,
				)
				onSuccess && onSuccess(updatedSubParty)
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token, subParty.id],
	)

	return (
		<SubPartyUpdateDrawerFormView
			subParty={subParty}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
