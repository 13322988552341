import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { DrawerBasic } from "src/components/ui"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { ICashReceiptV2 } from "src/domain/entities"
import { formatDate, toKGs } from "src/utils/helpers"
import { EItemType } from "../../../../../entries/EItemType"

interface Props extends Omit<ComponentProps<typeof DrawerBasic>, "children"> {
	type: EItemType
	receipts: ICashReceiptV2[]
	onItemClick?: (slip: ICashReceiptV2) => void
	onEditClick?: (slip: ICashReceiptV2) => void
	onDeleteClick?: (slip: ICashReceiptV2) => void
}

export const CashReceiptListDrawer: FC<Props> = (props) => {
	return (
		<DrawerBasic
			{...props}
			headerLabel={
				props.type === EItemType.INWARD_CASH
					? "Inward Cash Receipts"
					: "Outward Cash Receipts"
			}
			size="xl"
		>
			<Box border="1px" borderColor="gray.300" borderRadius="lg">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<Th paddingY="2">Date</Th>
							<Th paddingY="2">Particulars</Th>
							<Th paddingY="2" isNumeric>
								Amount
							</Th>
							<Th paddingY="2"></Th>
						</Tr>
					</Thead>
					<Tbody>
						{/* Slips */}
						{props.receipts?.map((receipt, i) => (
							<CashReceiptRow
								key={i}
								slip={receipt}
								onEditClick={() => {
									if (props.onEditClick) {
										props.onEditClick(receipt)
									}
								}}
								onDeleteClick={() => {
									if (props.onDeleteClick) {
										props.onDeleteClick(receipt)
									}
								}}
							/>
						))}
						{/* Total */}
						{/* <TotalRow stats={stats} /> */}
					</Tbody>
				</Table>
			</Box>
		</DrawerBasic>
	)
}

interface CashReceiptRowProps {
	slip: ICashReceiptV2
	action?: JSX.Element | string
	onClick?: Props["onItemClick"]
	onEditClick?: Props["onEditClick"]
	onDeleteClick?: Props["onDeleteClick"]
}

const CashReceiptRow: FC<CashReceiptRowProps> = ({
	slip,
	onEditClick,
	onDeleteClick,
	...rest
}) => (
	<Tr
		onClick={() => {
			rest.onClick && rest.onClick(slip)
		}}
		cursor="pointer"
		_hover={{ backgroundColor: "gray.100" }}
	>
		<Td>{formatDate(slip.issueDate)}</Td>
		<Td>
			<Box>{slip.note}</Box>
		</Td>
		<Td isNumeric>{toKGs(slip.amount, { dashIfZero: true }) ?? "-"}</Td>
		<Td isNumeric>
			<EditIconButton onClick={onEditClick} />
			<DeleteIconButton onClick={onDeleteClick} />
		</Td>
	</Tr>
)
