import { Center } from "@chakra-ui/react"
import { FC } from "react"
import { ContainerWrapper } from "../../wrappers/ContainerWrapper"
import { AdminLoginFormContainer } from "./AdminLoginForm"

export const AdminLoginPage: FC = () => {
	return (
		<ContainerWrapper>
			<Center height={"100vh"}>
				<AdminLoginFormContainer />
			</Center>
		</ContainerWrapper>
	)
}
