import { Box, FormControl, Input, ListItem, UnorderedList } from "@chakra-ui/react"
import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { InputLabel } from "src/components/ui/InputLabel"
import { companySelfDeleteService } from "src/domain/services/company/companySelfDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"

interface Props {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const CompanySelfDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [inputPassword, setInputPassword] = useState("")

	const handleCompanySelfDelete = useCallback(async () => {
		try {
			if (!inputPassword) {
				throw new Error("Password is required.")
			}
			setIsLoading(true)
			await companySelfDeleteService({ password: inputPassword }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
			window.location.reload()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [setIsOpen, token, onSuccess, inputPassword])

	const PasswordInput = (
		<Box marginTop={4}>
			<FormControl>
				<InputLabel label="Password" />
				<Input
					value={inputPassword}
					type="password"
					onKeyPress={(e) => {
						e.key === "Enter" && handleCompanySelfDelete()
					}}
					onChange={(e) => {
						setErrorMessage(null)
						setInputPassword(e.target.value)
					}}
				/>
			</FormControl>
		</Box>
	)

	return (
		<DeleteItemDialog
			title={`Delete Company and Data`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			subtitle={
				<Box color="red.500">
					<Box fontWeight="semibold">
						Read the following carefully before you delete company and data.
					</Box>
					<UnorderedList>
						<ListItem>
							This will delete all the related company data.
						</ListItem>
						<ListItem>The data is non-recoverable.</ListItem>
					</UnorderedList>
				</Box>
			}
			extendedBody={PasswordInput}
			onDelete={handleCompanySelfDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
