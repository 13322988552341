import { ComponentProps, FC, useCallback } from "react"
import { IPartyV1 } from "src/domain/entities"
import { partyAddService } from "src/domain/services/party/partyAddService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IPartyAddFormFields, PartyAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof PartyAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (party: IPartyV1) => void
}

export const PartyAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IPartyAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const party = await partyAddService(values, token)
				onSuccess(party)
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token],
	)

	return <PartyAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
