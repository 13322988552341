import { outwardCashReceiptUpdateApi } from "../../api/outwardCashReceipt/outwardCashReceiptUpdateApi"

type PayloadShape = Parameters<typeof outwardCashReceiptUpdateApi>[0]

export async function outwardCashReceiptUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await outwardCashReceiptUpdateApi(payload, token)
}
