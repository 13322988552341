import { IBillV2 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	subPartyId: string
	productId: string
	issueDate: string
	fullBillNo: string
	saleRate: number
	workRate: number
	quantity: number
	note: string | null
}

interface ResponseDataShape {
	success: boolean
	data: IBillV2
}

export async function saleBillAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/saleBill/add/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
