import { IJobWorkItemV2 } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"
import { EJobWorkItemType } from "./EJobWorkItemType"

interface RequestDataShape {
	filter: { itemType: EJobWorkItemType; id: string }
	update: {
		marketRate?: number
		workRate?: number
		lossPercentage?: number
		isLossInclusive?: boolean
	}
}

interface ResponseDataShape {
	success: boolean
	data: IJobWorkItemV2
}

export async function jobWorkItemUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/jobWorkItem/update/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
