import { useCallback, useState } from "react"
import { IPartyMonthStatementV3 } from "src/domain/entities"
import { partyMonthStatementGetService } from "src/domain/services/partyMonth/partyMonthStatementGetService"
import { useAuth } from ".."

export function usePartyMonthStatementService(): [
	IPartyMonthStatementV3 | undefined,
	boolean,
	(partyMonthId: string) => void,
] {
	const { token } = useAuth()
	const [statement, setStatement] = useState<IPartyMonthStatementV3>()
	const [isLoading, setIsLoading] = useState(false)

	const fetchPartyMonthStatement = useCallback(
		async (partyMonthId: string) => {
			setIsLoading(true)
			const data = await partyMonthStatementGetService(partyMonthId, token)
			setStatement(data)
			setIsLoading(false)
		},
		[token],
	)

	return [statement, isLoading, fetchPartyMonthStatement]
}
