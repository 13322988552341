import { outwardCashReceiptDeleteApi } from "../../api/outwardCashReceipt/outwardCashReceiptDeleteApi"

type PayloadShape = Parameters<typeof outwardCashReceiptDeleteApi>[0]

export async function outwardCashReceiptDeleteService(
	payload: PayloadShape,
	token: string,
) {
	return await outwardCashReceiptDeleteApi(payload, token)
}
