import { productListApi } from "../../api/product/productListApi"
import { IPagination, IProductV1 } from "../../entities"

export async function productListService(
	filter: { id?: string } & IPagination,
	token?: null | string,
) {
	const product = await productListApi({ filter, include: { audits: true } }, token)
	return product as NonNullable<IProductV1[]>
}
