import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "src/components/context/auth"
import { userVerifyService } from "src/domain/services/user/userVerifyService"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IVerifyFormFields } from "./IVerifyFormFields"
import { VerifyFormView } from "./VerifyFormView"

interface Props {
	mobileNo: string
	otpValidTill: number
	verifyService: typeof userVerifyService
}

export const VerifyFormContainer: FC<Props> = ({ mobileNo, verifyService }) => {
	const { setToken, setUser, setCompany } = useAuthContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback<FormikOnSubmit<IVerifyFormFields>>(
		async ({ otp }, { setErrors }) => {
			try {
				const { token, company, user } = await verifyService({
					mobileNo,
					otp: +otp,
				})
				setToken(token)
				setUser(user)
				setCompany(company)

				if (company) navigate("/")
				else navigate("/company")
			} catch (err) {
				setErrors({ otp: getErrorMessage(err) })
			}
		},
		[mobileNo, setToken, setUser, setCompany, navigate, verifyService],
	)

	return <VerifyFormView handleSubmit={handleSubmit} />
}
