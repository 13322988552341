import { Box, Text } from "@chakra-ui/react"
import { FC, useCallback } from "react"

export const CompanyExpiredPage: FC = () => {
	const handleLogOut = useCallback(() => {
		window.location.href = "/login"
	}, [])

	return (
		<Box textAlign={"center"} marginTop={20}>
			<Text fontSize={"3xl"}>Your plan is not active, please contact support.</Text>
			<Text fontSize={"xl"} fontWeight={"semibold"} align={"center"} marginTop={10}>
				Contact
			</Text>
			<Text fontSize={"sm"} align={"center"} _hover={{ color: "blue.700" }}>
				<a
					href="mailto:hello@hkbyte.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					hello@hkbyte.com
				</a>
			</Text>
			<Text fontSize={"sm"} align={"center"} _hover={{ color: "blue.700" }}>
				<a href="tel:+919327699561" target="_blank" rel="noopener noreferrer">
					+91 93276 99561
				</a>
			</Text>
			<Text
				decoration={"underline"}
				marginTop={10}
				fontSize={"sm"}
				align={"center"}
				color={"red.800"}
				_hover={{ color: "red.600" }}
				onClick={handleLogOut}
				cursor={"pointer"}
			>
				Log out
			</Text>
		</Box>
	)
}
