import { ICompanyV2, IUserV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	include?: { audits?: boolean; company?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: { user: IUserV1; company?: ICompanyV2 | null }
}

export async function userSelfGetApi(payload: RequestDataShape, token: string) {
	const endpoint = "/user/self/get/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
