import {
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { IPartyV1 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IPartyUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	party: IPartyV1
	handleSubmit: FormikOnSubmit<IPartyUpdateFormFields>
}

export const PartyUpdateDrawerFormView: FC<Props> = ({
	party,
	handleSubmit,
	...rest
}) => {
	return (
		<Formik<IPartyUpdateFormFields>
			initialValues={{
				name: party.name,
				note: party.details?.note ?? "",
				openingReceivableAmount: party.details?.openingReceivableAmount ?? 0,
				openingReceivableQuantity: party.details?.openingReceivableQuantity ?? 0,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange }) => (
				<DrawerForm
					size="sm"
					headerLabel="Update Party"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"sm"} marginX={"auto"}>
						{/* Name */}
						<FormControl>
							<InputLabel label="Party Name" />
							<Input
								name="name"
								maxLength={50}
								isRequired
								autoFocus
								value={values.name}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="name" />
							<InputDescription>
								Reference name of any individual, company or any entity.
								<br />
								Party name should be unique across an account.
							</InputDescription>
						</FormControl>
						<Flex gridGap={3}>
							{/* Opening Quantity */}
							<FormControl flex={1}>
								<InputLabel label={"Opening Quantity"} />
								<InputGroup>
									<Input
										name="openingReceivableQuantity"
										value={values.openingReceivableQuantity}
										onChange={handleChange}
									/>
									<InputRightAddon children="KG" />
								</InputGroup>
							</FormControl>
							{/* Opening Amount */}
							<FormControl flex={1}>
								<InputLabel label="Opening Amount" />
								<InputGroup>
									<InputLeftAddon children="₹" />
									<Input
										name="openingReceivableAmount"
										value={values.openingReceivableAmount}
										onChange={handleChange}
									/>
								</InputGroup>
							</FormControl>
						</Flex>
						{/* Note */}
						<FormControl>
							<InputLabel label="Note" suffixLabel="(Optional)" />
							<Textarea
								name="note"
								onChange={handleChange}
								value={values.note ?? ""}
								maxLength={1000}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
