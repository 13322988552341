import { ComponentProps, FC, useCallback } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { IBillV2 } from "src/domain/entities"
import { purchaseBillUpdateService } from "src/domain/services/purchaseBill/purchaseBillUpdateService"
import { saleBillUpdateService } from "src/domain/services/saleBill/saleBillUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { BillUpdateDrawerFormView, IBillUpdateFormFields } from "."

type Props = Omit<ComponentProps<typeof BillUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (bill: IBillV2) => void
}

export const BillUpdateDrawerFormController: FC<Props> = ({
	onSuccess,
	bill,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IBillUpdateFormFields["update"]>>(
		async (values, { setErrors }) => {
			try {
				if (rest.type === EItemType.PURCHASE_BILL) {
					const updatedBill = await purchaseBillUpdateService(
						{
							filter: { id: bill.id },
							update: values,
						},
						token,
					)
					onSuccess?.(updatedBill)
				} else if (rest.type === EItemType.SALE_BILL) {
					const updatedBill = await saleBillUpdateService(
						{
							filter: { id: bill.id },
							update: values,
						},
						token,
					)
					onSuccess?.(updatedBill)
				}

				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, bill],
	)

	let headerLabel = "Bill Update"
	if (rest.type === EItemType.SALE_BILL) {
		headerLabel = "Sale Bill Update"
	} else if (rest.type === EItemType.PURCHASE_BILL) {
		headerLabel = "Purchase Bill Update"
	}

	return (
		<BillUpdateDrawerFormView
			bill={bill}
			handleSubmit={handleSubmit}
			{...rest}
			headerLabel={headerLabel}
		/>
	)
}
