import { inwardJobWorkBillUpdateApi } from "../../api/inwardJobWorkBill/inwardJobWorkBillUpdateApi"

type PayloadShape = Parameters<typeof inwardJobWorkBillUpdateApi>[0]

export async function inwardJobWorkBillUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await inwardJobWorkBillUpdateApi(payload, token)
}
