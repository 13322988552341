import { userPasswordResetApi } from "src/domain/api/user/userPasswordResetApi"

export async function userPasswordResetService(payload: {
	mobileNo: string
	newPassword: string
}) {
	return await userPasswordResetApi({
		mobileNo: payload.mobileNo,
		newPassword: payload.newPassword,
	})
}
