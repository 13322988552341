import { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAuthContext } from "../context/auth"

export const LogoutPage: FC = () => {
	const { setToken, setUser, setCompany } = useAuthContext()
	setToken(null)
	setUser(null)
	setCompany(null)

	return <Navigate to="/" />
}
