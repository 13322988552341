import { Box, Button, FormControl, Heading, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { ErrorMessageField } from "src/components/ui/ErrorMessageField"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { IVerifyFormFields } from "./IVerifyFormFields"

interface Props {
	handleSubmit: FormikOnSubmit<IVerifyFormFields>
}

export const VerifyFormView: FC<Props> = ({ handleSubmit }) => (
	<Formik<IVerifyFormFields>
		initialValues={{ otp: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ handleChange, isSubmitting }) => (
			<Stack as={Form} maxWidth={"sm"}>
				<Box>
					<Heading as="h1" size="lg">
						Verification
					</Heading>
				</Box>
				<Box>
					{/* Otp */}
					<FormControl>
						<InputLabel label="Enter OTP" />
						<Input
							type="tel"
							name="otp"
							maxLength={4}
							isRequired
							onChange={handleChange}
						/>
						<InputDescription py="2">
							An 4 digit code has been sent to your mobile number.
						</InputDescription>
						<ErrorMessage component={ErrorMessageField} name="mobileNo" />
					</FormControl>
				</Box>
				<Box>
					{/* Submit Button */}
					<Button
						colorScheme={"blue"}
						type="submit"
						disabled={isSubmitting}
						isLoading={isSubmitting}
						width="full"
					>
						Verify
					</Button>
				</Box>
			</Stack>
		)}
	</Formik>
)
