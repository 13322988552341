import { ISubPartyV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id: string }
	update: { name?: string; note?: null | string; include?: { details?: boolean } }
}

interface ResponseDataShape {
	success: boolean
	data: ISubPartyV1
}

export async function subPartyUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/subParty/update/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
