import { ComponentProps, FC, useCallback } from "react"
import { ISubPartyV1 } from "src/domain/entities"
import { subPartyAddService } from "src/domain/services/subParty/subPartyAddService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyAddFormFields, SubPartyAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof SubPartyAddDrawerFormView>, "handleSubmit"> {
	onSuccess?: (party: ISubPartyV1) => void
}

export const SubPartyAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISubPartyAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const subParty = await subPartyAddService(values, token)
				onSuccess && onSuccess(subParty)
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token],
	)

	return <SubPartyAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
