import { CheckIcon } from "@chakra-ui/icons"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FC, useState } from "react"
import { BsTrash2Fill } from "react-icons/bs"
import { IoAddCircle, IoPencil } from "react-icons/io5"
import { ISubPartyV1 } from "../../../../domain/entities"

interface Props {
	selectedSubParty: ISubPartyV1 | undefined
	setSelectedSubParty: (subParty?: ISubPartyV1) => void
	list: ISubPartyV1[]
	onUpdate?: (subParty: ISubPartyV1) => void
	onDelete?: (subParty: ISubPartyV1) => void
	onCreate?: () => void
}

export const PartySubPartySectionView: FC<Props> = ({
	selectedSubParty,
	setSelectedSubParty,
	list,
	onUpdate,
	onDelete,
	onCreate,
}) => {
	const [isEditModeOpen, setIsEditModeOpen] = useState(false)

	const isDefaultSelected =
		selectedSubParty?.id === list.find((subParty) => subParty.isDefault)?.id

	return (
		<Box margin={3}>
			<SectionHeader
				isEditModeOpen={isEditModeOpen}
				setIsEditModeOpen={setIsEditModeOpen}
				showEditButton={list.length > 1}
			/>
			<Box backgroundColor="gray.200" rounded="md" overflow="hidden">
				{list.length > 1 ? (
					<AllSubPartyListItem
						isSelected={!isEditModeOpen && !selectedSubParty}
						setSelectedSubParty={setSelectedSubParty}
					/>
				) : null}

				{list.length === 1 ? (
					<Box fontSize={"xs"} padding={2}>
						No Sub-parties
					</Box>
				) : (
					<DefaultSubPartyListItem
						isSelected={!isEditModeOpen && isDefaultSelected}
						setSelectedSubParty={setSelectedSubParty}
						list={list}
					/>
				)}
				{list
					.filter((el) => !el.isDefault)
					.map((subParty) => {
						const isSelected = subParty.id === selectedSubParty?.id
						return (
							<Box
								key={subParty.id}
								borderTop="1px"
								borderTopColor="gray.300"
								onClick={() => {
									setSelectedSubParty(subParty)
								}}
							>
								<ListItem
									key={subParty.id}
									subParty={subParty}
									title={subParty.name}
									isSelected={isEditModeOpen ? false : isSelected}
									isEditModeOpen={isEditModeOpen}
									onUpdate={onUpdate}
									onDelete={onDelete}
								/>
							</Box>
						)
					})}
			</Box>
			<SubPartyAddButton onCreate={onCreate} />
		</Box>
	)
}

const SectionHeader: FC<{
	isEditModeOpen: boolean
	setIsEditModeOpen: (val: boolean) => void
	showEditButton?: boolean
}> = ({ isEditModeOpen, setIsEditModeOpen, showEditButton = true }) => (
	<Flex justify="space-between" align="baseline" paddingX={1}>
		<Text fontWeight="semibold">Sub Parties</Text>
		{showEditButton ? (
			<Text
				color={isEditModeOpen ? "red.600" : "blue.600"}
				fontWeight="semibold"
				cursor="pointer"
				fontSize="sm"
				onClick={() => setIsEditModeOpen(!isEditModeOpen)}
			>
				{isEditModeOpen ? "Close" : "Edit"}
			</Text>
		) : null}
	</Flex>
)

const ListItem: FC<{
	subParty?: ISubPartyV1
	isSelected: boolean
	title: string
	isEditModeOpen: boolean
	onUpdate?: (subParty: ISubPartyV1) => void
	onDelete?: (subParty: ISubPartyV1) => void
}> = ({ subParty, title, isSelected, isEditModeOpen, onUpdate, onDelete }) => {
	const checkIcon = isSelected ? (
		<Box paddingLeft={2} fontSize="x-small" color="green.400">
			<CheckIcon />
		</Box>
	) : null

	const actionBox = subParty ? (
		<Flex alignItems="center">
			<Box color="yellow.600">
				<IoPencil onClick={() => onUpdate && onUpdate(subParty)} />
			</Box>
			<Box color="red.600" marginLeft={1}>
				<BsTrash2Fill onClick={() => onDelete && onDelete(subParty)} />
			</Box>
		</Flex>
	) : null

	return (
		<Box
			paddingY={1.5}
			paddingX={3}
			cursor="pointer"
			_hover={isSelected ? {} : { backgroundColor: "gray.300" }}
			backgroundColor={isSelected ? "gray.700" : "transparent"}
			color={isSelected ? "white" : "gray.900"}
			fontSize="sm"
		>
			<Flex alignItems="center" justify="space-between">
				<Box>{title}</Box>
				{isEditModeOpen ? actionBox : checkIcon}
			</Flex>
		</Box>
	)
}

const AllSubPartyListItem: FC<{
	isSelected: boolean
	setSelectedSubParty: (val: ISubPartyV1 | undefined) => void
}> = ({ isSelected, setSelectedSubParty }) => (
	<Box
		borderBottom="1px"
		borderBottomColor="gray.300"
		onClick={() => setSelectedSubParty(undefined)}
	>
		<ListItem isEditModeOpen={false} key={0} isSelected={isSelected} title="All" />
	</Box>
)

const DefaultSubPartyListItem: FC<{
	isSelected: boolean
	setSelectedSubParty: (val: ISubPartyV1 | undefined) => void
	list: ISubPartyV1[]
}> = ({ isSelected, setSelectedSubParty, list }) => (
	<Box onClick={() => setSelectedSubParty(list[0])}>
		<ListItem
			isEditModeOpen={false}
			key={0}
			isSelected={isSelected}
			title="Default"
		/>
	</Box>
)

const SubPartyAddButton: FC<{ onCreate?: () => void }> = ({ onCreate }) => (
	<Box
		color="blue.600"
		my={0.5}
		cursor="pointer"
		_hover={{ textDecoration: "underline" }}
		onClick={onCreate}
	>
		<Flex align="center" paddingX={1}>
			<IoAddCircle />
			<Text pl={1} fontWeight="semibold" fontSize="small">
				Add Sub-Party
			</Text>
		</Flex>
	</Box>
)
