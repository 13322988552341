import { Box, Button, Flex, FormControl, Heading, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { JobWorkLogo } from "src/components/shared/JobWorkLogo"
import { ErrorMessageField } from "src/components/ui/ErrorMessageField"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { IAdminLoginFormFields } from "."

interface Props {
	handleSubmit: FormikOnSubmit<IAdminLoginFormFields>
}

export const AdminLoginFormView: FC<Props> = ({ handleSubmit }) => (
	<Formik<IAdminLoginFormFields>
		initialValues={{ username: "", password: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ handleChange, isSubmitting }) => (
			<Stack as={Form} maxWidth={"sm"}>
				<Box textAlign={"center"}>
					<JobWorkLogo />
					<Heading as="h1" size="md" color={"gray.600"}>
						Admin Login
					</Heading>

					<br />
				</Box>
				<Flex direction={"column"} gridGap={"2"}>
					{/* Username */}
					<FormControl>
						<InputLabel label="Username" />
						<Input
							name="username"
							onChange={handleChange}
							isRequired
							autoFocus
							maxLength={10}
						/>
						<ErrorMessage component={ErrorMessageField} name="username" />
					</FormControl>
					{/* Password */}
					<FormControl>
						<InputLabel label="Password" />
						<Input
							type="password"
							name="password"
							isRequired
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="password" />
					</FormControl>
				</Flex>
				<Box py="2">
					{/* AdminLogin Button */}
					<Button
						colorScheme={"blue"}
						type="submit"
						disabled={isSubmitting}
						isLoading={isSubmitting}
						width={"full"}
					>
						Login
					</Button>
				</Box>
			</Stack>
		)}
	</Formik>
)
