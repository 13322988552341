import { ComponentProps, FC, useCallback } from "react"
import { Navigate } from "react-router-dom"
import { EItemType } from "src/components/entries/EItemType"
import { useGlobalContext } from "src/components/pages/GlobalContext"
import { outwardCashReceiptAddService } from "src/domain/services/outwardCashReceipt/outwardCashReceiptAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { CashReceiptAddDrawerFormView } from "."
import { ICashReceiptV2 } from "../../../../domain/entities"
import { inwardCashReceiptAddService } from "../../../../domain/services/inwardCashReceipt/inwardCashReceiptAddService"
import { ICashReceiptAddFormFields } from "./ICashReceiptAddFormFields"

interface Props
	extends Omit<
		ComponentProps<typeof CashReceiptAddDrawerFormView>,
		"children" | "handleSubmit" | "partyList"
	> {
	type: EItemType
	onSuccess: (cashReceipt: ICashReceiptV2) => void
}

export const CashReceiptAddFormController: FC<Props> = ({
	onSuccess,
	type,
	defaultSelectedParty,
	...rest
}) => {
	const { token } = useAuth()

	const { partyList } = useGlobalContext()

	const handleOnSubmit = useCallback<FormikOnSubmit<ICashReceiptAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (type === EItemType.INWARD_CASH) {
					const cashReceipt = await inwardCashReceiptAddService(values, token)
					onSuccess(cashReceipt)
				} else if (type === EItemType.OUTWARD_CASH) {
					const cashReceipt = await outwardCashReceiptAddService(values, token)
					onSuccess(cashReceipt)
				} else {
					throw new Error("Invalid Item type")
				}
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, type],
	)

	if (!token) return <Navigate to="login" />

	return (
		<CashReceiptAddDrawerFormView
			type={type}
			defaultSelectedParty={defaultSelectedParty}
			partyList={partyList}
			{...rest}
			handleSubmit={handleOnSubmit}
		/>
	)
}
