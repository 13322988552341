import { createContext, FC, useContext, useState } from "react"

interface IAdminAuthState {
	token: string | null
	setToken: React.Dispatch<React.SetStateAction<string | null>>
}

const AdminAuthContext = createContext<IAdminAuthState>({
	token: null,
	setToken: () => {},
})

export const AdminAuthContextProvider: FC = ({ children, ...rest }) => {
	const [token, setToken] = useState<IAdminAuthState["token"]>(null)

	return (
		<AdminAuthContext.Provider value={{ token, setToken }} {...rest}>
			{children}
		</AdminAuthContext.Provider>
	)
}

export function useAdminAuthContext() {
	return useContext(AdminAuthContext)
}
