import { FC, useCallback, useState } from "react"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IForgotPasswordFormFields } from "./IForgotPasswordFormFields"
import { ForgotPasswordFormView } from "./ForgotPasswordFormView"
import { VerifyFormContainer } from "../../RegisterPage/VerifyForm/VerifyFormContainer"
import { userPasswordResetService } from "src/domain/services/user/userPasswordResetService"
import { userPasswordResetVerifyService } from "src/domain/services/user/userPasswordResetVerifyService"

export const ForgotPasswordFormContainer: FC = () => {
	const [mobileNo, setMobileNo] = useState<string>()
	const [otpValidTill, setOtpValidTill] = useState<number>()

	const handleSubmit = useCallback<FormikOnSubmit<IForgotPasswordFormFields>>(
		async ({ confirmPassword, mobileNo, password }, { setErrors }) => {
			try {
				if (confirmPassword !== password) {
					setErrors({
						confirmPassword: "Password and Confirm Password should be same",
					})
					return
				}
				const { otpValidTill } = await userPasswordResetService({
					mobileNo,
					newPassword: password,
				})
				setMobileNo(mobileNo)
				setOtpValidTill(otpValidTill)
			} catch (err) {
				setErrors({ confirmPassword: getErrorMessage(err) })
			}
		},
		[],
	)

	if (otpValidTill && mobileNo) {
		return (
			<VerifyFormContainer
				mobileNo={mobileNo}
				otpValidTill={otpValidTill}
				verifyService={userPasswordResetVerifyService}
			/>
		)
	}

	return <ForgotPasswordFormView handleSubmit={handleSubmit} />
}
