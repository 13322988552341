import {
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPencilFill, BsThreeDotsVertical, BsTrash2Fill } from "react-icons/bs"
import { EItemType } from "src/components/entries/EItemType"
import { IJobWorkItemSlipV2, IJobWorkItemV2 } from "src/domain/entities"
import { formatDate, toKGs } from "src/utils/helpers"

interface Props {
	slips?: IJobWorkItemSlipV2[]
	item: IJobWorkItemV2
	type: EItemType
	onItemSlipEditClick: (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		item: IJobWorkItemV2,
	) => void
	onItemSlipDeleteClick: (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		item: IJobWorkItemV2,
	) => void
}

export const ItemSlipsSection: FC<Props> = ({
	slips,
	type,
	item,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	const hasNoSlips = slips?.length === 0

	slips?.map((slip) => {
		slip.jobWorkItem = item
		return slip
	})

	return (
		<Table size="sm">
			<Thead>
				{!hasNoSlips && (
					<Tr backgroundColor="gray.100">
						<Th
							borderBottomWidth={1}
							borderBottomColor="gray.300"
							paddingY={2}
							paddingX={3}
						>
							Date
						</Th>
						<Th
							borderBottomWidth={1}
							borderBottomColor="gray.300"
							paddingY={2}
							paddingX={3}
						>
							Slip No.
						</Th>
						<Th
							borderBottomWidth={1}
							borderBottomColor="gray.300"
							paddingY={2}
							paddingX={3}
							isNumeric
						>
							Quantity
						</Th>
						<Th
							borderBottomWidth={1}
							borderBottomColor="gray.300"
							paddingY={2}
							paddingX={3}
							isNumeric
							className="print-hidden"
						></Th>
					</Tr>
				)}
			</Thead>
			<Tbody>
				{hasNoSlips && <ItemSlipsRow hasNoRows />}
				{slips?.map((slip, index) => (
					<ItemSlipsRow
						key={index}
						date={formatDate(slip.issueDate)}
						slipNo={slip.slipNo}
						quantity={toKGs(slip.netQuantity)}
						onItemSlipEditClick={() => onItemSlipEditClick(slip, type, item)}
						onItemSlipDeleteClick={() =>
							onItemSlipDeleteClick(slip, type, item)
						}
					/>
				))}
			</Tbody>
		</Table>
	)
}

const ItemSlipsRow: FC<{
	date?: JSX.Element | string | number
	slipNo?: JSX.Element | string | number
	quantity?: JSX.Element | string | number
	hasNoRows?: boolean
	onItemSlipEditClick?: Function
	onItemSlipDeleteClick?: Function
}> = ({
	date,
	slipNo,
	quantity,
	hasNoRows,
	onItemSlipEditClick,
	onItemSlipDeleteClick,
}) => {
	if (hasNoRows) {
		return (
			<Tr backgroundColor="gray.50">
				<Td colSpan={5}>No Slips</Td>
			</Tr>
		)
	}

	const tableColumns: JSX.Element[] = []

	if (date !== undefined) {
		tableColumns.push(
			<Td
				key="date"
				borderBottomWidth={1}
				borderBottomColor="gray.300"
				paddingY={1}
				paddingX={3}
			>
				{date}
			</Td>,
		)
	}

	if (slipNo !== undefined) {
		tableColumns.push(
			<Td
				key="slipNo"
				borderBottomWidth={1}
				borderBottomColor="gray.300"
				paddingY={1}
				paddingX={3}
			>
				{slipNo}
			</Td>,
		)
	}

	if (quantity !== undefined) {
		tableColumns.push(
			<Td
				key="quantity"
				borderBottomWidth={1}
				borderBottomColor="gray.300"
				isNumeric
				paddingY={1}
				paddingX={3}
			>
				{quantity}
			</Td>,
		)
	}

	tableColumns.push(
		<Td
			key="actions"
			borderBottomWidth={1}
			borderBottomColor="gray.300"
			isNumeric
			paddingY={1}
			paddingX={3}
			className="print-hidden"
		>
			<Menu>
				<MenuButton
					as={IconButton}
					aria-label="Options"
					size="xs"
					backgroundColor="transparent"
					icon={<BsThreeDotsVertical />}
				/>
				<MenuList fontSize={"sm"} minWidth="auto">
					<MenuItem
						icon={<BsPencilFill />}
						color="yellow.600"
						onClick={() => onItemSlipEditClick?.()}
					>
						Edit
					</MenuItem>
					<MenuItem
						icon={<BsTrash2Fill />}
						color="red.600"
						onClick={() => onItemSlipDeleteClick?.()}
					>
						Delete
					</MenuItem>
				</MenuList>
			</Menu>
		</Td>,
	)

	return <Tr backgroundColor="gray.50">{tableColumns}</Tr>
}
