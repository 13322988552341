import { Box, Button, Flex, Heading, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { DashboardWrapper } from "src/components/wrappers"
import { IPartyV1 } from "src/domain/entities"
import { usePartyListService } from "src/hooks"
import { PartyAddDrawerFormController } from "./PartyAddDrawerForm"
import { PartyDeleteDialogController } from "./PartyDeleteDialogController"
import { PartyListView } from "./PartyListView"
import { PartyUpdateDrawerFormController } from "./PartyUpdateDrawerForm"

export const PartyPage: FC = () => {
	const [partyList, isLoading, fetchPartyList] = usePartyListService()

	useEffect(() => {
		fetchPartyList()
	}, [fetchPartyList])

	const partyAddDrawerDisclosure = useDisclosure()
	const partyUpdateDrawerDisclosure = useDisclosure()
	const [isPartyDeleteDialogOpen, setIsPartyDeleteDialogOpen] = useState(false)
	const updatePartyRef = useRef<IPartyV1>()
	const deletePartyRef = useRef<IPartyV1>()

	const navigate = useNavigate()
	const handleOnSelect = useCallback(
		(party: IPartyV1) => navigate(`/party/${party.id}`),
		[navigate],
	)

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Heading as="h1" size="lg">
						Parties
					</Heading>
					<Button
						onClick={partyAddDrawerDisclosure.onOpen}
						colorScheme="blue"
						size="sm"
					>
						Add Party
					</Button>
				</Flex>
			</Box>
			{isLoading ? (
				<CenteredSpinner />
			) : (
				<Box marginBottom={10}>
					<PartyListView
						list={partyList}
						onUpdate={(party: IPartyV1) => {
							updatePartyRef.current = party
							partyUpdateDrawerDisclosure.onOpen()
						}}
						onDelete={(party: IPartyV1) => {
							deletePartyRef.current = party
							setIsPartyDeleteDialogOpen(true)
						}}
						onSelect={handleOnSelect}
						onAddClick={partyAddDrawerDisclosure.onOpen}
					/>
				</Box>
			)}

			<PartyAddDrawerFormController
				{...partyAddDrawerDisclosure}
				onSuccess={() => fetchPartyList()}
			/>
			{updatePartyRef.current && (
				<PartyUpdateDrawerFormController
					{...partyUpdateDrawerDisclosure}
					onSuccess={() => {
						updatePartyRef.current = undefined
						fetchPartyList()
					}}
					party={updatePartyRef.current}
				/>
			)}
			{deletePartyRef.current && (
				<PartyDeleteDialogController
					isOpen={isPartyDeleteDialogOpen}
					setIsOpen={setIsPartyDeleteDialogOpen}
					party={deletePartyRef.current}
					onSuccess={() => {
						deletePartyRef.current = undefined
						fetchPartyList()
					}}
				/>
			)}
		</DashboardWrapper>
	)
}
