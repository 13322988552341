import { useCallback, useState } from "react"
import { ICompanyV2 } from "src/domain/entities"
import { companyListService } from "src/domain/services/company/companyListService"
import { useAdminAuth } from "../useAdminAuth"

export function useCompanyListService(): [
	ICompanyV2[],
	boolean,
	(filter?: Parameters<typeof companyListService>[0]) => void,
] {
	const { token } = useAdminAuth()
	const [companyList, setCompanyList] = useState<ICompanyV2[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchCompanyList = useCallback(
		async (filter: Parameters<typeof companyListService>[0] = {}) => {
			setIsLoading(true)
			const data = await companyListService(filter, token)
			setCompanyList(data)
			setIsLoading(false)
		},
		[token],
	)

	return [companyList, isLoading, fetchCompanyList]
}
