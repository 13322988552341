import { partyMonthStatementGetApi } from "src/domain/api/partyMonth/partyMonthStatementGetApi"

export async function partyMonthStatementGetService(
	partyMonthId: string,
	token: string | null,
) {
	return await partyMonthStatementGetApi(
		{
			partyMonthId,
		},
		token,
	)
}
