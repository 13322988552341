import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	mobileNo: string
	password: string
}

interface ResponseDataShape {
	success: boolean
	data: { otpValidTill: number }
}

export async function userRegisterApi(payload: RequestDataShape) {
	const endpoint = "/user/register/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload)
	return response.data
}
