export enum EItemType {
	INWARD = "INWARD",
	OUTWARD = "OUTWARD",
	INWARD_CASH = "INWARD_CASH",
	OUTWARD_CASH = "OUTWARD_CASH",
	INWARD_JOBWORK_BILL = "INWARD_JOBWORK_BILL",
	OUTWARD_JOBWORK_BILL = "OUTWARD_JOBWORK_BILL",
	SALE_BILL = "SALE_BILL",
	PURCHASE_BILL = "PURCHASE_BILL",
}
