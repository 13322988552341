import { FC, useCallback, useState } from "react"
import { userRegisterService } from "src/domain/services/user/userRegisterService"
import { userVerifyService } from "src/domain/services/user/userVerifyService"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { VerifyFormContainer } from "../VerifyForm/VerifyFormContainer"
import { IRegisterFormFields } from "./IRegisterFormFields"
import { RegisterFormView } from "./RegisterFormView"

export const RegisterFormContainer: FC = () => {
	const [mobileNo, setMobileNo] = useState<string>()
	const [otpValidTill, setOtpValidTill] = useState<number>()

	const handleSubmit = useCallback<FormikOnSubmit<IRegisterFormFields>>(
		async ({ confirmPassword, mobileNo, password }, { setErrors }) => {
			try {
				if (confirmPassword !== password) {
					setErrors({
						confirmPassword: "Password and Confirm Password should be same",
					})
					return
				}
				const { otpValidTill } = await userRegisterService({ mobileNo, password })
				setMobileNo(mobileNo)
				setOtpValidTill(otpValidTill)
			} catch (err) {
				setErrors({ confirmPassword: getErrorMessage(err) })
			}
		},
		[],
	)

	if (otpValidTill && mobileNo) {
		return (
			<VerifyFormContainer
				mobileNo={mobileNo}
				otpValidTill={otpValidTill}
				verifyService={userVerifyService}
			/>
		)
	}

	return <RegisterFormView handleSubmit={handleSubmit} />
}
