import { userPasswordResetVerifyApi } from "src/domain/api/user/userPasswordResetVerifyApi"
import { userSelfGetService } from "./userSelfGetService"

export async function userPasswordResetVerifyService(payload: {
	mobileNo: string
	otp: number
}) {
	const { token } = await userPasswordResetVerifyApi(payload)
	const { user, company } = await userSelfGetService(token)
	return { token, user, company }
}
