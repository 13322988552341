import { ComponentProps, FC, useCallback } from "react"
import { ISubPartyMonthV2 } from "src/domain/entities"
import { subPartyMonthUpdateService } from "src/domain/services/subPartyMonth/subPartyMonthUpdateService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyMonthUpdateFormFields, SubPartyMonthUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof SubPartyMonthUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: (subPartyMonth: ISubPartyMonthV2) => void
}

export const SubPartyMonthUpdateDrawerFormController: FC<Props> = ({
	subPartyMonth,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISubPartyMonthUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedSubParty = await subPartyMonthUpdateService(
					{ filter: { id: subPartyMonth.id }, update: values },
					token,
				)
				onSuccess && onSuccess(updatedSubParty)
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token, subPartyMonth.id],
	)

	return (
		<SubPartyMonthUpdateDrawerFormView
			subPartyMonth={subPartyMonth}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
