import { userPasswordUpdateApi } from "src/domain/api/user/userPasswordUpdateApi"

export async function userPasswordUpdateService(
	payload: {
		currentPassword: string
		newPassword: string
		newPasswordConfirm: string
	},
	token: string | null,
) {
	return await userPasswordUpdateApi(
		{ currentPassword: payload.currentPassword, newPassword: payload.newPassword },
		token,
	)
}
