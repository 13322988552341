import {
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { IPartyAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IPartyAddFormFields>
}

export const PartyAddDrawerFormView: FC<Props> = ({ handleSubmit, ...rest }) => (
	<Formik<IPartyAddFormFields>
		initialValues={{ name: "", note: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel="Add New Party"
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Name */}
					<FormControl>
						<InputLabel label="Party Name" />
						<Input
							name="name"
							maxLength={50}
							required
							autoFocus
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="name" />
						<InputDescription>
							Reference name of any individual, company or entity.
							<br />
							Party name should be unique across an account.
						</InputDescription>
					</FormControl>
					<Flex gridGap={3}>
						{/* Opening Quantity */}
						<FormControl flex={1}>
							<InputLabel label={"Opening Quantity"} />
							<InputGroup>
								<Input
									name="openingReceivableQuantity"
									onChange={handleChange}
								/>
								<InputRightAddon children="KG" />
							</InputGroup>
						</FormControl>
						{/* Opening Amount */}
						<FormControl flex={1}>
							<InputLabel label="Opening Amount" />
							<InputGroup>
								<InputLeftAddon children="₹" />
								<Input
									name="openingReceivableAmount"
									onChange={handleChange}
								/>
							</InputGroup>
						</FormControl>
					</Flex>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea name="note" onChange={handleChange} maxLength={1000} />
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)
