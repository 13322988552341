import { FC, useCallback } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useAuthContext } from "src/components/context/auth"
import { companyAddService } from "src/domain/services/company/companyAddService"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { CompanyCreateFormView, ICompanyCreateFormFields } from "."

export const CompanyCreateFormContainer: FC = () => {
	const { token, setCompany } = useAuthContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback<FormikOnSubmit<ICompanyCreateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const company = await companyAddService(values, token)
				setCompany(company)
				navigate("/")
			} catch (err) {
				setErrors({ name: getErrorMessage(err) })
			}
		},
		[setCompany, token, navigate],
	)

	if (!token) return <Navigate to="/login" />
	return <CompanyCreateFormView handleSubmit={handleSubmit} />
}
