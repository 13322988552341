import { FormControl, Input, Stack, Textarea } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	partyId: string
	handleSubmit: FormikOnSubmit<ISubPartyAddFormFields>
}

export const SubPartyAddDrawerFormView: FC<Props> = ({
	partyId,
	handleSubmit,
	...rest
}) => (
	<Formik<ISubPartyAddFormFields>
		initialValues={{ partyId, name: "", note: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel="Add Sub-Party"
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Name */}
					<FormControl>
						<InputLabel label="Sub-party Name" />
						<Input
							name="name"
							placeholder="Name"
							maxLength={50}
							required
							autoFocus
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="name" />
					</FormControl>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							placeholder="Note"
							onChange={handleChange}
							maxLength={1000}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)
