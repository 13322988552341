import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

interface ResponseDataShape {
	success: boolean
}

export async function outwardJobWorkBillDeleteApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/outwardJobWorkBill/delete/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	await requester.sendRequest(payload, token)
}
