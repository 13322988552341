import { FC, useCallback, useState } from "react"
import { EItemType } from "src/components/entries/EItemType"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { IJobWorkBillV2 } from "src/domain/entities"
import { inwardJobWorkBillDeleteService } from "src/domain/services/inwardJobWorkBill/inwardJobWorkBillDeleteService"
import { outwardJobWorkBillDeleteService } from "src/domain/services/outwardJobWorkBill/outwardJobWorkBillDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"

interface Props {
	type: EItemType
	bill: IJobWorkBillV2
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const JobWorkBillDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	type,
	bill: { id: billId },
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleJobWorkBillDelete = useCallback(async () => {
		try {
			if (type === EItemType.INWARD_JOBWORK_BILL) {
				await inwardJobWorkBillDeleteService({ id: billId }, token)
				setIsOpen(false)
				onSuccess?.()
			} else if (type === EItemType.OUTWARD_JOBWORK_BILL) {
				await outwardJobWorkBillDeleteService({ id: billId }, token)
				setIsOpen(false)
				onSuccess?.()
			} else {
				throw new Error("Invalid item type")
			}
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [setIsOpen, token, onSuccess, billId, type])

	return (
		<DeleteItemDialog
			title={getHeaderLabel(type)}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleJobWorkBillDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}

function getHeaderLabel(type: EItemType) {
	switch (type) {
		case EItemType.INWARD_JOBWORK_BILL:
			return "Delete Delivery Challan"
		case EItemType.OUTWARD_JOBWORK_BILL:
			return "Delete Received Challan"
	}
	return "Delete Bill"
}
