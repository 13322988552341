import { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAuthContext } from "../context/auth"
import { GlobalContextProvider } from "../pages/GlobalContext"

export const PrivateRoute: FC<{ element: JSX.Element }> = ({ element }) => {
	const { token, company } = useAuthContext()
	if (!token) return <Navigate to="/login" />
	if (!company) return <Navigate to="/company" />
	if (company.isExpired) return <Navigate to="/company/expired" />

	return <GlobalContextProvider>{element}</GlobalContextProvider>
}
