import {
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { ICashReceiptV2 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { ICashReceiptUpdateFormFields } from "."
import { EItemType } from "../../EItemType"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	slip: ICashReceiptV2
	type: EItemType
	handleSubmit: FormikOnSubmit<ICashReceiptUpdateFormFields["update"]>
}

export const CashReceiptUpdateDrawerFormView: FC<Props> = ({
	slip,
	type,
	handleSubmit,
	...rest
}) => (
	<Formik<ICashReceiptUpdateFormFields["update"]>
		initialValues={{
			amount: slip.amount,
			note: slip.note,
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel={getHeaderLabel(type)}
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Amount */}
					<FormControl flex={1} marginLeft={2}>
						<InputLabel label="Amount" />
						<InputGroup>
							<InputLeftAddon children="₹" />
							<Input
								name="amount"
								required
								value={values.amount}
								onChange={handleChange}
							/>
						</InputGroup>
					</FormControl>

					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							placeholder="Note"
							onChange={handleChange}
							value={values.note ?? ""}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)

function getHeaderLabel(type: EItemType) {
	switch (type) {
		case EItemType.INWARD_CASH:
			return "Update Inward Cash Slip"
		case EItemType.OUTWARD_CASH:
			return "Update Outward Cash Slip"
	}
	return "Update Cash Slip"
}
