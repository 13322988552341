import { CheckIcon, SearchIcon } from "@chakra-ui/icons"
import {
	Badge,
	Box,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Table,
	Tag,
	TagLabel,
	TagRightIcon,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { FC, useState } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SlipAddButton } from "src/components/shared/SlipAddButton"
import { ICompanyMonthV2, IPartyV1, ISubPartyV1 } from "src/domain/entities"
import { IUnifiedMonthEntriesV1 } from "src/domain/entities/unifiedEntries"
import { formatDate, fullMonthAndYear, toINR, toKGs } from "src/utils/helpers"
import {
	getParsedInCashEntries,
	getParsedInJobWorkBillEntries,
	getParsedInwardEntries,
	getParsedOutCashEntries,
	getParsedOutJobWorkBillEntries,
	getParsedOutwardEntries,
	getParsedPurchaseBillEntries,
	getParsedSaleBillEntries,
} from "./EntryParsers"

export interface EntryRowProps {
	id: string | number
	issueDate: number
	slipNo: string | JSX.Element
	type: string | JSX.Element
	particulars: string | JSX.Element
	grossQuantity: number
	bagQuantity: number
	burnLossQuantity: number
	rejectedQuantity: number
	netQuantity: number
	amount: number
	searchTerm: string
}

export const EntryList: FC<{
	entries: IUnifiedMonthEntriesV1
	selectedEntryTypes: EItemType[]
	isLoading: boolean
	companyMonthList: ICompanyMonthV2[]
	selectedCompanyMonth?: ICompanyMonthV2
	// party
	selectedParty?: IPartyV1
	partyList: IPartyV1[]
	isPartyListLoading: boolean
	handleUpdateSelectedParty: (party?: IPartyV1) => void
	// sub party
	selectedSubParty?: ISubPartyV1
	subPartyList: ISubPartyV1[]
	isSubPartyListLoading: boolean
	handleUpdateSelectedSubParty: (subParty?: ISubPartyV1) => void
	// company month
	handleUpdateSelectedCompanyMonth: (companyMonth: ICompanyMonthV2) => void
	// entry types
	handleUpdateSelectedEntryTypes: (entryTypes: EItemType[]) => void
}> = ({
	entries,
	selectedEntryTypes,
	handleUpdateSelectedEntryTypes,
	isLoading,
	companyMonthList,
	selectedCompanyMonth,
	handleUpdateSelectedCompanyMonth,
	selectedParty,
	partyList,
	isPartyListLoading,
	handleUpdateSelectedParty,
	selectedSubParty,
	subPartyList,
	isSubPartyListLoading,
	handleUpdateSelectedSubParty,
}) => {
	let rows: EntryRowProps[] = []

	rows.push(...getParsedInwardEntries(entries.inJobWorkItemSlipList))
	rows.push(...getParsedOutwardEntries(entries.outJobWorkItemSlipList))
	rows.push(...getParsedSaleBillEntries(entries.saleBillList))
	rows.push(...getParsedPurchaseBillEntries(entries.purchaseBillList))
	rows.push(...getParsedInJobWorkBillEntries(entries.inJobWorkBillList))
	rows.push(...getParsedOutJobWorkBillEntries(entries.outJobWorkBillList))
	rows.push(...getParsedInCashEntries(entries.inCashReceiptList))
	rows.push(...getParsedOutCashEntries(entries.outCashReceiptList))

	const [searchText, setSearchText] = useState("")

	if (searchText) {
		rows = rows.filter((row) => {
			return row.searchTerm
				.trim()
				.toLowerCase()
				.includes(searchText.toLowerCase().trim())
		})
	}

	rows.sort((a, b) => {
		if (formatDate(a.issueDate) === formatDate(b.issueDate)) {
			return a.searchTerm.toString().localeCompare(b.searchTerm.toString())
		}
		return a.issueDate - b.issueDate
	})

	const totalStats: {
		grossQuantity: number
		bagQuantity: number
		burnLossQuantity: number
		rejectedQuantity: number
		netQuantity: number
		amount: number
	} = {
		grossQuantity: 0,
		bagQuantity: 0,
		burnLossQuantity: 0,
		rejectedQuantity: 0,
		netQuantity: 0,
		amount: 0,
	}

	rows.forEach((row) => {
		totalStats.grossQuantity += row.grossQuantity
		totalStats.bagQuantity += row.bagQuantity
		totalStats.burnLossQuantity += row.burnLossQuantity
		totalStats.rejectedQuantity += row.rejectedQuantity
		totalStats.netQuantity += row.netQuantity
		totalStats.amount += row.amount
	})

	return (
		<Box paddingX={4}>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Entries
					</Text>
					<Box>
						<SlipAddButton />
					</Box>
				</Flex>
			</Box>
			<Box padding={2}>
				<Flex gridGap={4}>
					<Box>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
					</Box>
					<Box flex={1}>
						<Select<{ label: string; value?: IPartyV1 }, false>
							value={
								selectedParty
									? {
											label: selectedParty.name,
											value: selectedParty,
									  }
									: undefined
							}
							placeholder="Select Party"
							isLoading={isPartyListLoading}
							isClearable
							onChange={(selected) => {
								handleUpdateSelectedParty(selected?.value)
							}}
							options={partyList.map((el) => ({
								label: el.name,
								value: el,
							}))}
						/>
					</Box>
					<Box flex={1}>
						{selectedParty &&
							subPartyList.length > 1 &&
							!isSubPartyListLoading && (
								<Select<{ label: string; value?: ISubPartyV1 }, false>
									value={
										selectedSubParty
											? {
													label: selectedSubParty.name,
													value: selectedSubParty,
											  }
											: undefined
									}
									placeholder="Select Sub party"
									isLoading={isSubPartyListLoading}
									isClearable
									onChange={(selected) => {
										handleUpdateSelectedSubParty(selected?.value)
									}}
									options={subPartyList.map((el) => ({
										label: el.name,
										value: el,
									}))}
								/>
							)}
					</Box>
					<Box flex={1}>
						<Select<{ label: string; value?: ICompanyMonthV2 }, false>
							value={{
								label: fullMonthAndYear({
									month: selectedCompanyMonth?.month ?? 1,
									year: selectedCompanyMonth?.year ?? 2022,
								}),
								value: selectedCompanyMonth,
							}}
							isClearable={false}
							onChange={(selected) => {
								if (selected?.value) {
									handleUpdateSelectedCompanyMonth(selected.value)
								}
							}}
							options={companyMonthList.map((el) => ({
								label: fullMonthAndYear({
									month: el?.month ?? 1,
									year: el?.year ?? 2022,
								}),
								value: el,
							}))}
						/>
					</Box>
				</Flex>
				<Box mt={2}>
					{Object.values(EItemType).map((itemType, i) => (
						<Tag
							key={i}
							variant="outline"
							colorScheme={
								selectedEntryTypes.includes(itemType) ? "green" : "gray"
							}
							backgroundColor={
								selectedEntryTypes.includes(itemType)
									? "green.50"
									: "gray.50"
							}
							cursor="pointer"
							_hover={{
								backgroundColor: selectedEntryTypes.includes(itemType)
									? "green.100"
									: "gray.100",
							}}
							mx={1}
							onClick={() => {
								if (selectedEntryTypes.includes(itemType)) {
									if (selectedEntryTypes.length === 1) return
									handleUpdateSelectedEntryTypes(
										selectedEntryTypes.filter(
											(el) => el !== itemType,
										),
									)
								} else {
									handleUpdateSelectedEntryTypes([
										...selectedEntryTypes,
										itemType,
									])
								}
							}}
						>
							<TagLabel>{getEntryLabel(itemType)}</TagLabel>
							{selectedEntryTypes.includes(itemType) && (
								<TagRightIcon boxSize="12px" as={CheckIcon} />
							)}
						</Tag>
					))}
				</Box>
			</Box>
			{isLoading ? (
				<CenteredSpinner />
			) : (
				<Box
					border="1px"
					borderColor="gray.300"
					borderRadius="lg"
					overflow={"hidden"}
					marginBottom={10}
				>
					<Table size={"sm"}>
						<Thead>
							<Tr>
								<Th backgroundColor={"gray.100"} py="3">
									Date
								</Th>
								<Th backgroundColor={"gray.100"} whiteSpace={"nowrap"}>
									Slip No.
								</Th>
								<Th backgroundColor={"gray.100"} textAlign={"center"}>
									Type
								</Th>
								<Th backgroundColor={"gray.100"}>Particulars</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Gross Quantity
								</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Bag Quantity
								</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Burn loss Quantity
								</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Rejected Quantity
								</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Net Quantity
								</Th>
								<Th backgroundColor={"gray.100"} isNumeric>
									Amount
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{rows.map((entry) => (
								<EntryListRow key={entry.id} entry={entry} />
							))}
						</Tbody>
						<Tfoot>
							<Tr>
								<Td colSpan={4} fontWeight={"bold"}>
									Count: {rows.length}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toKGs(totalStats.grossQuantity, {
										dashIfZero: true,
									})}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toKGs(totalStats.bagQuantity, {
										dashIfZero: true,
									})}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toKGs(totalStats.burnLossQuantity, {
										dashIfZero: true,
									})}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toKGs(totalStats.rejectedQuantity, {
										dashIfZero: true,
									})}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toKGs(totalStats.netQuantity, {
										dashIfZero: true,
									})}
								</Td>
								<Td isNumeric fontWeight={"bold"}>
									{toINR(totalStats.amount, { dashIfZero: true })}
								</Td>
							</Tr>
						</Tfoot>
					</Table>
				</Box>
			)}
		</Box>
	)
}

const EntryListRow: React.FC<{ entry: EntryRowProps }> = ({ entry }) => {
	let label: JSX.Element | null = null

	switch (entry.type) {
		case EItemType.INWARD:
			label = <Badge colorScheme="green">IN</Badge>
			break
		case EItemType.OUTWARD:
			label = <Badge colorScheme="red">OUT</Badge>
			break
		case EItemType.SALE_BILL:
			label = <Badge colorScheme="green">Sale Bill</Badge>
			break
		case EItemType.PURCHASE_BILL:
			label = <Badge colorScheme="red">Purchase Bill</Badge>
			break
		case EItemType.INWARD_JOBWORK_BILL:
			label = (
				<Badge colorScheme="blue">
					Delivery <br /> Challan
				</Badge>
			)
			break
		case EItemType.OUTWARD_JOBWORK_BILL:
			label = (
				<Badge colorScheme="blue">
					Received <br />
					Challan
				</Badge>
			)
			break
		case EItemType.INWARD_CASH:
			label = <Badge colorScheme="green">Cash Received</Badge>
			break
		case EItemType.OUTWARD_CASH:
			label = <Badge colorScheme="red">Cash Paid</Badge>
			break
	}

	return (
		<Tr>
			<Td whiteSpace={"nowrap"}>{formatDate(entry.issueDate)}</Td>
			<Td whiteSpace={"nowrap"}>{entry.slipNo}</Td>
			<Td textAlign={"center"}>{label}</Td>
			<Td>{entry.particulars}</Td>
			<Td isNumeric>{toKGs(entry.grossQuantity, { dashIfZero: true })}</Td>
			<Td isNumeric>{toKGs(entry.bagQuantity ?? 0, { dashIfZero: true })}</Td>
			<Td isNumeric>
				{toKGs(entry.burnLossQuantity ?? 0, {
					dashIfZero: true,
				})}
			</Td>
			<Td isNumeric>
				{toKGs(entry.rejectedQuantity ?? 0, {
					dashIfZero: true,
				})}
			</Td>
			<Td isNumeric>{toKGs(entry.netQuantity ?? 0, { dashIfZero: true })}</Td>
			<Td isNumeric>{toINR(entry.amount, { dashIfZero: true })}</Td>
		</Tr>
	)
}

const getEntryLabel = (type: EItemType): string => {
	switch (type) {
		case EItemType.INWARD:
			return "IN"
		case EItemType.OUTWARD:
			return "OUT"
		case EItemType.SALE_BILL:
			return "Sale Bill"
		case EItemType.PURCHASE_BILL:
			return "Purchase Bill"
		case EItemType.INWARD_JOBWORK_BILL:
			return "Delivery Challan"
		case EItemType.OUTWARD_JOBWORK_BILL:
			return "Received Challan"
		case EItemType.INWARD_CASH:
			return "In Cash"
		case EItemType.OUTWARD_CASH:
			return "Out Cash"
		default:
			return "Unknown"
	}
}
