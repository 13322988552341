import { outwardJobWorkBillUpdateApi } from "../../api/outwardJobWorkBill/outwardJobWorkBillUpdateApi"

type PayloadShape = Parameters<typeof outwardJobWorkBillUpdateApi>[0]

export async function outwardJobWorkBillUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await outwardJobWorkBillUpdateApi(payload, token)
}
