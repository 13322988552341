import _ from "lodash"
import { productAddApi } from "../../api/product/productAddApi"

export async function productAddService(
	payload: { name: string; note?: string },
	token: string,
) {
	if (_.isEmpty(payload.note)) payload.note = undefined
	return await productAddApi(payload, token)
}
