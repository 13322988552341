import moment from "moment"

export function toINR(amount: number, { dashIfZero = false } = {}) {
	if (dashIfZero && amount === 0) return "-"
	const formatted = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: "INR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount)
	return formatted
}

export function toKGs(quantity: number, { dashIfZero = false } = {}) {
	if (dashIfZero && quantity === 0) return "-"
	const formatted = new Intl.NumberFormat("en-IN", {
		style: "decimal",
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	}).format(quantity)
	return formatted
}

export function fullMonthAndYear({ month, year }: { year: number; month: number }) {
	return monthName(month) + " " + year
}

export function monthName(month: number) {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]
	return months[month - 1]
}

export function disclosureDefaultValue() {
	return {
		isOpen: false,
		onOpen: () => {},
		onClose: () => {},
		onToggle: () => {},
		getButtonProps: () => {},
		getDisclosureProps: () => {},
		isControlled: false,
	}
}

export function getErrorMessage(err: unknown) {
	if (err instanceof Error) return err.message
	if (typeof err === "string") return err
	return "Unknown Error"
}

export function formatDate(date: Date | number | string) {
	return moment(date).format("DD-MM-YYYY")
}
