import { ComponentProps, FC, useCallback } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { IJobWorkBillV2 } from "src/domain/entities"
import { inwardJobWorkBillUpdateService } from "src/domain/services/inwardJobWorkBill/inwardJobWorkBillUpdateService"
import { outwardJobWorkBillUpdateService } from "src/domain/services/outwardJobWorkBill/outwardJobWorkBillUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IJobWorkBillUpdateFormFields, JobWorkBillUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof JobWorkBillUpdateDrawerFormView>,
	"handleSubmit" | "headerLabel"
> & {
	onSuccess?: (itemSlip: IJobWorkBillV2) => void
}

export const JobWorkBillUpdateDrawerFormController: FC<Props> = ({
	onSuccess,
	bill,
	type,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<
		FormikOnSubmit<IJobWorkBillUpdateFormFields["update"]>
	>(
		async (values, { setErrors }) => {
			try {
				if (type === EItemType.OUTWARD_JOBWORK_BILL) {
					const updatedJobWorkBill = await outwardJobWorkBillUpdateService(
						{
							filter: { id: bill.id },
							update: values,
						},
						token,
					)
					onSuccess?.(updatedJobWorkBill)
				} else if (type === EItemType.INWARD_JOBWORK_BILL) {
					const updatedJobWorkBill = await inwardJobWorkBillUpdateService(
						{
							filter: { id: bill.id },
							update: values,
						},
						token,
					)
					onSuccess?.(updatedJobWorkBill)
				} else {
					throw new Error("Invalid JobWork Bill type")
				}
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, bill, type],
	)

	let headerLabel = "Challan Update"
	if (type === EItemType.INWARD_JOBWORK_BILL) {
		headerLabel = "Delivery Challan Update"
	} else if (type === EItemType.OUTWARD_JOBWORK_BILL) {
		headerLabel = "Received Challan Update"
	}

	return (
		<JobWorkBillUpdateDrawerFormView
			bill={bill}
			handleSubmit={handleSubmit}
			type={type}
			{...rest}
			headerLabel={headerLabel}
		/>
	)
}
