import {
	Badge,
	Box,
	Flex,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import {
	IBillV2,
	ICashReceiptV2,
	IJobWorkBillV2,
	IJobWorkItemV2,
	IPartyMonthStatementV3,
	IPartyMonthV1,
	ISubPartyMonthV2,
	ISubPartyV1,
} from "src/domain/entities"
import { formatDate, fullMonthAndYear, toINR, toKGs } from "src/utils/helpers"
import { EItemType } from "../../../entries/EItemType"

interface Props {
	selectedSubParty?: ISubPartyV1
	partyMonth: IPartyMonthV1
	statement: IPartyMonthStatementV3
	onItemClick?: (
		type: EItemType,
		item?: IJobWorkItemV2,
		subPartyMonth?: ISubPartyMonthV2,
	) => void
}

interface PartyMonthStatementStats {
	totalInQuantity: number
	totalInAmount: number
	totalOutQuantity: number
	totalOutAmount: number
}

export const PartyMonthStatementView: FC<Props> = ({
	selectedSubParty,
	partyMonth,
	statement,
	onItemClick,
}) => {
	let toShowTable = true
	let toShowCashEntries = true
	let subPartyMonthStatementList = statement.subPartyMonthStatementList
	let title = "Party Statement"

	let stats: PartyMonthStatementStats = {
		totalInQuantity: statement.partyMonth.stats.in.totalQuantity,
		totalOutQuantity: statement.partyMonth.stats.out.totalQuantity,
		totalInAmount: statement.partyMonth.stats.in.totalAmount,
		totalOutAmount: statement.partyMonth.stats.out.totalAmount,
	}

	let statementNote = ""

	if (selectedSubParty) {
		toShowCashEntries = false
		subPartyMonthStatementList = subPartyMonthStatementList.filter(
			(el) => el.subParty.id === selectedSubParty.id,
		)
		title = `${selectedSubParty.name} Sub-Party Statement`
		if (subPartyMonthStatementList[0]) {
			statementNote = subPartyMonthStatementList[0].subPartyMonth.note ?? ""
			stats = {
				totalOutQuantity:
					subPartyMonthStatementList[0].subPartyMonth.stats.out.totalQuantity,
				totalOutAmount:
					subPartyMonthStatementList[0].subPartyMonth.stats.out.totalAmount,
				totalInQuantity:
					subPartyMonthStatementList[0].subPartyMonth.stats.in.totalQuantity,
				totalInAmount:
					subPartyMonthStatementList[0].subPartyMonth.stats.in.totalAmount,
			}
		} else {
			toShowTable = false
		}
	} else {
		if (statement.subPartyMonthStatementList[0]) {
			statementNote =
				statement.subPartyMonthStatementList[0].subPartyMonth.note ?? ""
		}
	}

	return (
		<Box>
			{/* Heading */}
			<Box mb="2">
				<Heading size="md" width="full">
					<span>{title}</span>
					<span className="print-only"> of {statement.party.name} </span>
					<span> ({fullMonthAndYear(partyMonth)})</span>
				</Heading>
			</Box>
			{/* Statement Table */}
			{toShowTable ? (
				<Box>
					<Flex direction={"column"} gridGap={2}>
						<Box flex={1}>
							<Box
								border="1px"
								borderColor="gray.300"
								borderRadius="lg"
								overflow={"hidden"}
							>
								<Table variant="simple" size="sm">
									<TableHead title="Outward" />
									<Tbody>
										{subPartyMonthStatementList.map(
											(spmStatement) => {
												return spmStatement.outJobWorkItemList.map(
													(item) => (
														<JobWorkItemRow
															key={item.id}
															item={item}
															subPartyName={
																!selectedSubParty &&
																!spmStatement.subParty
																	.isDefault
																	? spmStatement
																			.subParty.name
																	: undefined
															}
															onClick={() =>
																onItemClick?.(
																	EItemType.OUTWARD,
																	item,
																	{
																		...spmStatement.subPartyMonth,
																		subParty:
																			spmStatement.subParty,
																	},
																)
															}
														/>
													),
												)
											},
										)}
										{subPartyMonthStatementList.map((spmStatement) =>
											spmStatement.outJobWorkBillList.map(
												(item) => (
													<JobWorkBillRow
														key={item.id}
														item={item}
														subPartyName={
															!selectedSubParty &&
															!spmStatement.subParty
																.isDefault
																? spmStatement.subParty
																		.name
																: undefined
														}
													/>
												),
											),
										)}
										{subPartyMonthStatementList.map((spmStatement) =>
											spmStatement.purchaseBillList.map((item) => (
												<BillRow
													key={item.id}
													item={item}
													subPartyName={
														!selectedSubParty &&
														!spmStatement.subParty.isDefault
															? spmStatement.subParty.name
															: undefined
													}
													badgeLabel="PURCHASE"
												/>
											)),
										)}
										{toShowCashEntries
											? statement.outCashList.map((item) => (
													<CashRow
														key={item.id}
														item={item}
														badgeLabel="CASH OUT"
													/>
											  ))
											: null}
									</Tbody>
									<TableTotal
										totalQuantity={stats.totalOutQuantity}
										totalAmount={stats.totalOutAmount}
									/>
								</Table>
							</Box>
						</Box>
						<Box flex={1}>
							<Box
								border="1px"
								borderColor="gray.300"
								borderRadius="lg"
								overflow={"hidden"}
							>
								<Table variant="simple" size="sm">
									<TableHead title="Inward" />
									<Tbody>
										{subPartyMonthStatementList.map(
											(spmStatement) => {
												return spmStatement.inJobWorkItemList.map(
													(item) => (
														<JobWorkItemRow
															key={item.id}
															item={item}
															subPartyName={
																!selectedSubParty &&
																!spmStatement.subParty
																	.isDefault
																	? spmStatement
																			.subParty.name
																	: undefined
															}
															onClick={() =>
																onItemClick?.(
																	EItemType.INWARD,
																	item,
																	{
																		...spmStatement.subPartyMonth,
																		subParty:
																			spmStatement.subParty,
																	},
																)
															}
														/>
													),
												)
											},
										)}

										{subPartyMonthStatementList.map((spmStatement) =>
											spmStatement.inJobWorkBillList.map((item) => (
												<JobWorkBillRow
													key={item.id}
													item={item}
													subPartyName={
														!selectedSubParty &&
														!spmStatement.subParty.isDefault
															? spmStatement.subParty.name
															: undefined
													}
												/>
											)),
										)}
										{subPartyMonthStatementList.map((spmStatement) =>
											spmStatement.saleBillList.map((item) => (
												<BillRow
													key={item.id}
													item={item}
													subPartyName={
														!selectedSubParty &&
														!spmStatement.subParty.isDefault
															? spmStatement.subParty.name
															: undefined
													}
													badgeLabel="SALE"
												/>
											)),
										)}
										{toShowCashEntries
											? statement.inCashList.map((item) => (
													<CashRow
														key={item.id}
														item={item}
														badgeLabel="CASH IN"
													/>
											  ))
											: null}
									</Tbody>
									<TableTotal
										totalQuantity={stats.totalInQuantity}
										totalAmount={stats.totalInAmount}
									/>
								</Table>
							</Box>
						</Box>
					</Flex>
					<Box marginY={6}>
						<SummaryTable
							stats={stats}
							selectedSubParty={selectedSubParty}
							statement={statement}
						/>
					</Box>

					{statementNote ? (
						<Box width={"100%"}>
							<Text fontWeight={"bold"}>Note:</Text>
							<pre style={{ fontFamily: "inherit", fontSize: "0.9rem" }}>
								{statementNote}
							</pre>
						</Box>
					) : null}
				</Box>
			) : (
				<>Nothing to show</>
			)}
		</Box>
	)
}

const TableHead: FC<{ title: string }> = ({ title }) => (
	<Thead>
		<Tr>
			<Th
				fontSize={"sm"}
				color={"gray.800"}
				paddingY="2"
				colSpan={6}
				textAlign={"center"}
				backgroundColor={"gray.100"}
			>
				{title}
			</Th>
		</Tr>
		<Tr>
			<Th paddingY="2">Particulars</Th>
			<Th paddingY="2" isNumeric>
				Market Rate
			</Th>
			<Th paddingY="2" isNumeric>
				Labour Rate
			</Th>
			<Th paddingY="2" isNumeric>
				Quantity
			</Th>
			<Th paddingY="2" isNumeric>
				Rejection
			</Th>
			<Th paddingY="2" isNumeric>
				Amount
			</Th>
		</Tr>
	</Thead>
)

const JobWorkItemRow: FC<{
	item: IJobWorkItemV2
	subPartyName?: string
	onClick: () => void
}> = ({ item, subPartyName, onClick }) => {
	const itemRejectedQuantity: number =
		item.slips
			?.map((slip) => slip.rejectedQuantity)
			?.reduce((a, b) => {
				return a + b
			}, 0) ?? 0

	return (
		<>
			<Tr key={item.id} onClick={onClick}>
				<Td>
					<Text as="span">{item.product?.name}</Text>
					{subPartyName ? (
						<Text as="span" marginLeft={1} color={"gray.600"}>
							({subPartyName})
						</Text>
					) : null}
					<Box margin={1} className="printable">
						<PrintableStatementSlips item={item} />
					</Box>
				</Td>
				<Td fontSize={"sm"} isNumeric>
					{toINR(item.marketRate, { dashIfZero: true })}
				</Td>
				<Td fontSize={"sm"} isNumeric>
					{toINR(item.workRate, { dashIfZero: true })}
				</Td>
				<Td fontSize={"sm"} isNumeric>
					{item.includeMarketRate
						? "N/A"
						: toKGs(item.quantity, { dashIfZero: true })}
					{item.lossPercentage ? (
						<Box color={"red.600"} fontSize={"xs"}>
							{item.isLossInclusive
								? `-${toKGs(item.inclusiveLossQuantity)}`
								: `+${toKGs(item.exclusiveLossQuantity)}`}
						</Box>
					) : null}
				</Td>
				<Td fontSize={"sm"} isNumeric>
					{toKGs(itemRejectedQuantity, { dashIfZero: true })}
				</Td>
				<Td fontSize={"sm"} isNumeric>
					{toINR(item.amount, { dashIfZero: true })}
				</Td>
			</Tr>
		</>
	)
}

const JobWorkBillRow: FC<{ item: IJobWorkBillV2; subPartyName?: string }> = ({
	item,
	subPartyName,
}) => {
	return (
		<Tr key={item.id}>
			<Td>
				{item.product?.name}
				{subPartyName ? (
					<Text as="span" marginLeft={1} color={"gray.600"}>
						({subPartyName})
					</Text>
				) : null}
				<Badge
					size={"xs"}
					marginLeft={2}
					fontWeight={"semibold"}
					variant={"outline"}
				>
					JW Bill
				</Badge>
			</Td>
			<Td fontSize={"sm"} isNumeric>
				-
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.workRate, { dashIfZero: true })}
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toKGs(item.quantity, { dashIfZero: true })}
			</Td>
			<Td fontSize={"sm"} isNumeric>
				-
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.amount, { dashIfZero: true })}
			</Td>
		</Tr>
	)
}

const BillRow: FC<{ item: IBillV2; badgeLabel: string; subPartyName?: string }> = ({
	item,
	badgeLabel,
	subPartyName,
}) => {
	return (
		<Tr key={item.id}>
			<Td>
				{item.product?.name}
				{subPartyName ? (
					<Text as="span" marginLeft={1} color={"gray.600"}>
						({subPartyName})
					</Text>
				) : null}
				<Badge
					size={"xs"}
					marginLeft={2}
					fontWeight={"semibold"}
					variant={"outline"}
				>
					{badgeLabel}
				</Badge>
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.saleRate, { dashIfZero: true })}
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.workRate, { dashIfZero: true })}
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toKGs(item.quantity, { dashIfZero: true })}
			</Td>
			<Td fontSize={"sm"} isNumeric>
				-
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.amount, { dashIfZero: true })}
			</Td>
		</Tr>
	)
}

const CashRow: FC<{ item: ICashReceiptV2; badgeLabel: string }> = ({
	item,
	badgeLabel,
}) => {
	return (
		<Tr key={item.id}>
			<Td colSpan={5}>
				<Flex>
					<Badge size={"xs"} fontWeight={"semibold"} variant={"outline"}>
						{badgeLabel}
					</Badge>
					<Flex ml={2} fontSize={"sm"}>
						<Box fontWeight={"semibold"} color={"gray.700"}>
							Note:{" "}
						</Box>
						<Box>{item.note || ""}</Box>
					</Flex>
				</Flex>
			</Td>
			<Td fontSize={"sm"} isNumeric>
				{toINR(item.amount, { dashIfZero: true })}
			</Td>
		</Tr>
	)
}

const TableTotal: FC<{ totalQuantity: number; totalAmount: number }> = ({
	totalQuantity,
	totalAmount,
}) => (
	<Tfoot>
		<Tr>
			<Th paddingY="2" color={"gray.900"} colSpan={3}>
				Total
			</Th>
			<Td paddingY="2" color={"gray.900"} isNumeric fontWeight={"bold"}>
				{toKGs(totalQuantity)}
			</Td>
			<Td fontSize={"sm"} isNumeric></Td>
			<Td paddingY="2" color={"gray.900"} isNumeric fontWeight={"bold"}>
				{toINR(totalAmount)}
			</Td>
		</Tr>
	</Tfoot>
)

const previousMonthName = ({ month, year }: { month: number; year: number }) => {
	const date = new Date(year, month - 1, 0)
	return date.toLocaleString("default", { month: "long", year: "numeric" })
}

const nextMonthName = ({ month, year }: { month: number; year: number }) => {
	const date = new Date(year, month + 1, 0)
	return date.toLocaleString("default", { month: "long", year: "numeric" })
}

const SummaryTable: FC<{
	selectedSubParty?: ISubPartyV1
	stats: PartyMonthStatementStats
	statement: IPartyMonthStatementV3
}> = ({ selectedSubParty, stats, statement }) => {
	let openingTitle = `Carried from ${previousMonthName(statement.partyMonth)}`
	let closingTitle = `Forward to ${nextMonthName(statement.partyMonth)}`

	return (
		<Box
			border="1px"
			borderColor="gray.300"
			borderRadius="lg"
			overflow={"hidden"}
			width={"fit-content"}
			marginLeft={"auto"}
		>
			<Table variant="simple" size="sm">
				<Thead>
					<Tr>
						<Th
							fontSize={"sm"}
							color={"gray.800"}
							paddingY="2"
							colSpan={5}
							textAlign={"center"}
							backgroundColor={"gray.200"}
						>
							Summary
						</Th>
					</Tr>
					<Tr>
						<Th></Th>
						<Th isNumeric>Quantity</Th>
						<Th isNumeric>Amount</Th>
					</Tr>
				</Thead>
				<Tbody>
					{!selectedSubParty ? (
						<Tr>
							<Td>{openingTitle}</Td>
							<Td isNumeric>
								{toKGs(
									statement.partyMonth.stats.opening.receivableQuantity,
									{ dashIfZero: true },
								)}
							</Td>
							<Td isNumeric>
								{toINR(
									statement.partyMonth.stats.opening.receivableAmount,
									{
										dashIfZero: true,
									},
								)}
							</Td>
						</Tr>
					) : null}
					<Tr>
						<Td>Total Out</Td>
						<Td isNumeric>
							{toKGs(stats.totalOutQuantity, {
								dashIfZero: true,
							})}
						</Td>
						<Td isNumeric>
							{toINR(stats.totalOutAmount, {
								dashIfZero: true,
							})}
						</Td>
					</Tr>
					<Tr>
						<Td>Total In</Td>
						<Td isNumeric>
							{toKGs(stats.totalInQuantity, {
								dashIfZero: true,
							})}
						</Td>
						<Td isNumeric>
							{toINR(stats.totalInAmount, {
								dashIfZero: true,
							})}
						</Td>
					</Tr>
					{selectedSubParty ? (
						<Tr>
							<Td>Out-In difference</Td>
							<Td isNumeric fontWeight={"bold"}>
								{toKGs(stats.totalOutQuantity - stats.totalInQuantity, {
									dashIfZero: true,
								})}
							</Td>
							<Td isNumeric fontWeight={"bold"}>
								{toINR(stats.totalOutAmount - stats.totalInAmount, {
									dashIfZero: true,
								})}
							</Td>
						</Tr>
					) : null}
					{!selectedSubParty ? (
						<Tr>
							<Td>{closingTitle}</Td>
							<Td isNumeric fontWeight={"bold"}>
								{toKGs(
									statement.partyMonth.stats.closing.receivableQuantity,
									{ dashIfZero: true },
								)}
							</Td>
							<Td isNumeric fontWeight={"bold"}>
								{toINR(
									statement.partyMonth.stats.closing.receivableAmount,
									{
										dashIfZero: true,
									},
								)}
							</Td>
						</Tr>
					) : null}
				</Tbody>
			</Table>
		</Box>
	)
}

const PrintableStatementSlips: FC<{ item: IJobWorkItemV2 }> = ({ item }) => {
	let toShowBagQuantityColumn = false

	for (let slip of item.slips ?? []) {
		if (slip.bagQuantity) {
			toShowBagQuantityColumn = true
			break
		}
	}

	return (
		<Table>
			<Thead>
				<Tr>
					<Th padding={0} fontSize={8}>
						Date
					</Th>
					<Th padding={0} fontSize={8}>
						Slip No.
					</Th>
					{toShowBagQuantityColumn ? (
						<Th padding={0} fontSize={8} isNumeric>
							Bags
						</Th>
					) : null}
					<Th padding={0} fontSize={8} isNumeric>
						Quantity
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				{item.slips?.map((slip, index) => (
					<>
						<Tr backgroundColor={index % 2 === 1 ? "#f2f2f2" : ""}>
							<Td
								paddingX={0.5}
								paddingY={0}
								fontSize={10}
								border="1px solid #9A9A9A"
								whiteSpace="nowrap"
							>
								{formatDate(slip.issueDate)}
							</Td>
							<Td
								paddingX={0.5}
								paddingY={0}
								fontSize={10}
								border="1px solid #9A9A9A"
								whiteSpace="nowrap"
							>
								{slip.prefix + "-" + slip.slipNo}
							</Td>
							{toShowBagQuantityColumn ? (
								<Td
									paddingX={0.5}
									paddingY={0}
									fontSize={10}
									border="1px solid #9A9A9A"
									whiteSpace="nowrap"
									isNumeric
								>
									{slip.bagQuantity || "-"}
								</Td>
							) : null}
							<Td
								paddingX={0.5}
								paddingY={0}
								fontSize={10}
								border="1px solid #9A9A9A"
								whiteSpace="nowrap"
								isNumeric
							>
								{toKGs(slip.netQuantity)}
							</Td>
						</Tr>
						{slip.note ? (
							<Tr backgroundColor={index % 2 === 1 ? "#f2f2f2" : ""}>
								<Td
									colSpan={toShowBagQuantityColumn ? 4 : 3}
									paddingX={0.5}
									paddingY={0}
									fontSize={8}
									border="1px solid #9A9A9A"
								>
									{slip.note}
								</Td>
							</Tr>
						) : null}
					</>
				))}
			</Tbody>
		</Table>
	)
}
