import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
	Stack,
} from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { Link } from "react-router-dom"
import { JobWorkLogo } from "src/components/shared/JobWorkLogo"
import { ErrorMessageField } from "src/components/ui/ErrorMessageField"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { ILoginFormFields } from "."

interface Props {
	handleSubmit: FormikOnSubmit<ILoginFormFields>
}

export const LoginFormView: FC<Props> = ({ handleSubmit }) => (
	<Formik<ILoginFormFields>
		initialValues={{ mobileNo: "", password: "" }}
		onSubmit={handleSubmit}
		enableReinitialize
	>
		{({ handleChange, isSubmitting }) => (
			<Stack as={Form} maxWidth={"sm"}>
				<Box textAlign={"center"}>
					<JobWorkLogo />
					<Heading as="h1" size="md" color={"gray.600"}>
						Login to continue
					</Heading>

					<br />
				</Box>
				<Flex direction={"column"} gridGap={"2"}>
					{/* Mobile No */}
					<FormControl>
						<InputLabel label="Mobile No" />
						<InputGroup>
							<InputLeftAddon children="+91" />
							<Input
								type="tel"
								name="mobileNo"
								onChange={handleChange}
								isRequired
								autoFocus
								maxLength={10}
							/>
						</InputGroup>
						<ErrorMessage component={ErrorMessageField} name="mobileNo" />
					</FormControl>
					{/* Password */}
					<FormControl>
						<InputLabel label="Password" />
						<Input
							type="password"
							name="password"
							isRequired
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="password" />
					</FormControl>
				</Flex>
				<Box py="2">
					{/* Login Button */}
					<Button
						colorScheme={"blue"}
						type="submit"
						disabled={isSubmitting}
						isLoading={isSubmitting}
						width={"full"}
					>
						Login
					</Button>
				</Box>
				<Box my="0" textAlign={"right"}>
					<Link to="/forgot-password">
						<Box
							as="span"
							color={"blue.600"}
							fontSize="sm"
							textDecoration={"underline"}
						>
							Forgot Password ?
						</Box>
					</Link>
				</Box>
				<Box pt="10" textAlign={"center"}>
					Don't have an account?{" "}
					<Link to="/register">
						<Box
							as="span"
							fontWeight={"semibold"}
							color={"blue.600"}
							textDecoration={"underline"}
						>
							Register Now
						</Box>
					</Link>
				</Box>
			</Stack>
		)}
	</Formik>
)
