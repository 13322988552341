import { companyCreateApi } from "../../api/company/companyCreateApi"
import { ICompanyV2 } from "../../entities"

export async function companyAddService(
	{ name }: { name: string },
	token: string | null,
) {
	const company = await companyCreateApi({ name }, token)
	return company as Omit<ICompanyV2, "audits" | "user">
}
