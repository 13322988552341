import { FormControl, Input, Stack, Textarea } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { ISubPartyV1 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { ISubPartyUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	subParty: ISubPartyV1
	handleSubmit: FormikOnSubmit<ISubPartyUpdateFormFields>
}

export const SubPartyUpdateDrawerFormView: FC<Props> = ({
	subParty,
	handleSubmit,
	...rest
}) => (
	<Formik<ISubPartyUpdateFormFields>
		initialValues={{ name: subParty.name, note: subParty.details?.note ?? "" }}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel="Update SubParty"
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					{/* Name */}
					<FormControl>
						<InputLabel label="Sub-party Name" />
						<Input
							name="name"
							placeholder="Name"
							maxLength={50}
							required
							autoFocus
							value={values.name}
							onChange={handleChange}
						/>
						<ErrorMessage component={ErrorMessageField} name="name" />
					</FormControl>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							placeholder="Note"
							onChange={handleChange}
							value={values.note ?? ""}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)
