import {
	Box,
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, FC, MutableRefObject } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { ProductAddButton } from "src/components/shared/ProductAddButton"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { IProductV1, ISubPartyV1 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IJobWorkBillAddFormFields } from "./IJobWorkBillAddFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	type: EItemType
	subPartyList: ISubPartyV1[]
	productList: IProductV1[]
	isProductListLoading?: boolean
	defaultSelectedSubParty?: MutableRefObject<ISubPartyV1 | undefined>
	defaultSelectedProduct?: MutableRefObject<IProductV1 | undefined>
	onProductAddSuccess?: Function
	handleSubmit: FormikOnSubmit<IJobWorkBillAddFormFields>
}

export const JobWorkBillAddDrawerFormView: FC<Props> = ({
	type,
	subPartyList,
	defaultSelectedSubParty,
	defaultSelectedProduct,
	productList,
	isProductListLoading,
	handleSubmit,
	onProductAddSuccess,
	...rest
}) => {
	const issueDate = moment().format("YYYY-MM-DD")
	const headerLabel =
		type === EItemType.INWARD_JOBWORK_BILL
			? "Add Delivery Challan"
			: "Add Received Challan"
	const subPartyLabel =
		type === EItemType.INWARD_JOBWORK_BILL ? "Select Supplier" : "Select Recipient"
	const subPartyDescription = `Party / Sub-Party ${
		type === EItemType.INWARD_JOBWORK_BILL
			? "to whom goods going out"
			: "from where goods came in"
	}`

	function getSubPartyLabel(subPartyId: string) {
		const subParty = subPartyList.find((subParty) => subParty.id === subPartyId)
		if (!subParty) return "unknown"

		let label = subParty.name
		if (!subParty.isDefault) label += ` (${subParty.party?.name})`
		return label
	}

	return (
		<Formik<IJobWorkBillAddFormFields>
			initialValues={{
				subPartyId: defaultSelectedSubParty?.current?.id || "",
				productId: defaultSelectedProduct?.current?.id || "",
				issueDate: issueDate,
				fullBillNo: "",
				quantity: 0,
				workRate: 0,
				note: "",
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const subPartyOptions = subPartyList.map((subParty) => ({
					value: subParty.id,
					label: getSubPartyLabel(subParty.id),
				}))

				const productOptions = productList.map((product) => ({
					label: product.name,
					value: product.id,
				}))

				return (
					<DrawerForm
						size="sm"
						headerLabel={headerLabel}
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Issue date */}
							<FormControl>
								<InputLabel label="Issue Date" />
								<Input
									name="issueDate"
									type="date"
									autoFocus
									value={values.issueDate}
									onChange={handleChange}
									defaultValue={issueDate}
								/>
							</FormControl>

							{/* Full Bill no */}
							<FormControl flex={2}>
								<InputLabel label="Bill No" />
								<Input
									name="fullBillNo"
									placeholder="Bill No."
									maxLength={20}
									required
									value={values.fullBillNo}
									onChange={handleChange}
								/>
							</FormControl>

							{/* Sub Party */}
							<FormControl>
								<InputLabel label={subPartyLabel} />
								<ReactSelect
									name="subPartyId"
									onChange={(newValue) => {
										const val = (newValue as SelectOption).value
										setFieldValue("subPartyId", val)

										if (defaultSelectedSubParty) {
											defaultSelectedSubParty.current =
												subPartyList.find((el) => el.id === val)
										}
									}}
									options={subPartyOptions}
									isSearchable
									value={subPartyOptions.find(
										(el) => el.value === values.subPartyId,
									)}
								/>
								<InputDescription>{subPartyDescription}</InputDescription>
							</FormControl>

							<Flex alignItems="flex-end">
								{/* Product */}
								<FormControl flex={1}>
									<InputLabel label="Select Product" />
									<ReactSelect
										name="productId"
										onChange={(newValue) => {
											const val = (newValue as SelectOption).value
											setFieldValue("productId", val)

											if (defaultSelectedProduct) {
												defaultSelectedProduct.current =
													productList.find(
														(el) => el.id === val,
													)
											}
										}}
										value={productOptions.find(
											(el) => el.value === values.productId,
										)}
										isLoading={isProductListLoading}
										options={productOptions}
										isSearchable
									/>
								</FormControl>
								<Box marginLeft={2}>
									<ProductAddButton
										onSuccess={async (product) => {
											await onProductAddSuccess?.()
											setFieldValue("productId", product.id)
											if (defaultSelectedProduct)
												defaultSelectedProduct.current = product
										}}
									/>
								</Box>
							</Flex>

							<Flex gridGap={2}>
								{/* Quantity */}
								<FormControl flex={1}>
									<InputLabel label="Quantity" />
									<InputGroup>
										<Input
											name="quantity"
											required
											value={values.quantity}
											onChange={handleChange}
										/>
										<InputRightAddon children="KG" />
									</InputGroup>
								</FormControl>
								{/* Labour Rate */}
								<FormControl flex={1}>
									<InputLabel
										label="Labour Rate"
										suffixLabel="(per KG)"
									/>
									<InputGroup>
										<InputLeftAddon children="₹" />
										<Input
											name="workRate"
											required
											value={values.workRate}
											onChange={handleChange}
										/>
									</InputGroup>
								</FormControl>
							</Flex>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" suffixLabel="(Optional)" />
								<Textarea
									name="note"
									onChange={handleChange}
									value={values.note ?? ""}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
