import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { EJobWorkItemType } from "src/domain/api/jobWorkItem/EJobWorkItemType"
import { IJobWorkItemSlipV2 } from "src/domain/entities"
import { jobWorkItemSlipDeleteService } from "src/domain/services/jobWorkItem/slip/jobWorkItemSlipDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { EItemType } from "../EItemType"

interface Props {
	type: EItemType
	slip: IJobWorkItemSlipV2
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const ItemSlipDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	type,
	slip: { id: slipId, jobWorkItem },
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleItemSlipDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await jobWorkItemSlipDeleteService(
				{
					id: slipId,
					jobWorkItemId: jobWorkItem?.id ?? "",
					itemType:
						type === EItemType.INWARD
							? EJobWorkItemType.IN
							: EJobWorkItemType.OUT,
				},
				token,
			)
			setIsOpen(false)
			onSuccess?.()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [setIsOpen, token, onSuccess, slipId, type, jobWorkItem])

	return (
		<DeleteItemDialog
			title={getHeaderLabel(type)}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleItemSlipDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}

function getHeaderLabel(type: EItemType) {
	switch (type) {
		case EItemType.INWARD:
			return "Delete In Slip"
		case EItemType.OUTWARD:
			return "Delete Out Slip"
	}
	return "Delete Item Slip"
}
