import { FC, useCallback, useEffect, useRef, useState } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { SlipAddDrawers } from "src/components/shared/SlipAddDrawers"
import { DashboardWrapper } from "src/components/wrappers"
import { ICompanyMonthV2, IPartyV1, ISubPartyV1 } from "src/domain/entities"
import { IUnifiedMonthEntriesV1 } from "src/domain/entities/unifiedEntries"
import { companyMonthListService } from "src/domain/services/company/companyMonthListServie"
import { unifiedMonthEntriesGetService } from "src/domain/services/unifiedEntries/unifiedMonthEntriesGetService"
import { useAuth, usePartyListService, useSubPartyListService } from "src/hooks"
import { EntryList } from "./EntryList"

export const EntriesPage: FC = () => {
	const [entries, setEntries] = useState<IUnifiedMonthEntriesV1>({
		inCashReceiptList: [],
		inJobWorkBillList: [],
		inJobWorkItemSlipList: [],
		outCashReceiptList: [],
		outJobWorkBillList: [],
		outJobWorkItemSlipList: [],
		purchaseBillList: [],
		saleBillList: [],
	})
	const { token } = useAuth()

	const selectedEntryTypes = useRef<EItemType[]>([EItemType.INWARD, EItemType.OUTWARD])
	const [companyMonthList, setCompanyMonthList] = useState<ICompanyMonthV2[]>([])
	const [selectedCompanyMonth, setSelectedCompanyMonth] = useState<ICompanyMonthV2>()

	const selectedParty = useRef<IPartyV1>()
	const [partyList, isPartyListLoading, fetchPartyList] = usePartyListService()

	const selectedSubParty = useRef<ISubPartyV1>()
	const [subPartyList, isSubPartyListLoading, fetchSubPartyList] =
		useSubPartyListService()

	const [isLoading, setIsLoading] = useState(true)

	const fetchEntries = useCallback(
		async (month: ICompanyMonthV2) => {
			setIsLoading(true)
			const entryTypes: Parameters<
				typeof unifiedMonthEntriesGetService
			>["0"]["include"] = {}

			selectedEntryTypes.current.forEach((entryType) => {
				switch (entryType) {
					case EItemType.INWARD:
						entryTypes.inJobWorkItemSlips = true
						break
					case EItemType.OUTWARD:
						entryTypes.outJobWorkItemSlips = true
						break
					case EItemType.SALE_BILL:
						entryTypes.saleBills = true
						break
					case EItemType.PURCHASE_BILL:
						entryTypes.purchaseBills = true
						break
					case EItemType.INWARD_JOBWORK_BILL:
						entryTypes.inJobWorkBills = true
						break
					case EItemType.OUTWARD_JOBWORK_BILL:
						entryTypes.outJobWorkBills = true
						break
					case EItemType.INWARD_CASH:
						entryTypes.inCashReceipts = true
						break
					case EItemType.OUTWARD_CASH:
						entryTypes.outCashReceipts = true
						break
				}
			})

			const entries = await unifiedMonthEntriesGetService(
				{
					filter: {
						year: month.year,
						month: month.month,
						partyId: selectedParty.current?.id,
						subPartyId: selectedSubParty.current?.id,
					},
					include: entryTypes,
				},
				token,
			)
			setEntries(entries)
			setIsLoading(false)
		},
		[token, selectedEntryTypes, selectedParty, selectedSubParty],
	)

	const handleUpdateSelectedEntryTypes = useCallback(
		(types: EItemType[]) => {
			if (!selectedCompanyMonth) return
			selectedEntryTypes.current = types

			fetchEntries(selectedCompanyMonth)
		},
		[fetchEntries, selectedCompanyMonth],
	)

	const handleUpdateSelectedCompanyMonth = useCallback(
		async (month: ICompanyMonthV2) => {
			setSelectedCompanyMonth(month)
			fetchEntries(month)
		},
		[fetchEntries],
	)

	const handleUpdateSelectedParty = useCallback(
		(party?: IPartyV1) => {
			if (!selectedCompanyMonth) return

			selectedParty.current = party
			selectedSubParty.current = undefined

			if (party) {
				fetchSubPartyList({ partyId: party.id })
			}
			fetchEntries(selectedCompanyMonth)
		},
		[fetchEntries, selectedParty, selectedCompanyMonth, fetchSubPartyList],
	)

	const handleUpdateSelectedSubParty = useCallback(
		(subParty?: ISubPartyV1) => {
			if (!selectedCompanyMonth) return

			selectedSubParty.current = subParty
			fetchEntries(selectedCompanyMonth)
		},
		[fetchEntries, selectedCompanyMonth],
	)

	const fetchCompanyMonths = useCallback(async () => {
		const months = await companyMonthListService({}, token)
		setCompanyMonthList(months)
		if (months.length && !selectedCompanyMonth) {
			handleUpdateSelectedCompanyMonth(months[0])
		} else {
			setIsLoading(false)
		}
	}, [
		token,
		setCompanyMonthList,
		handleUpdateSelectedCompanyMonth,
		selectedCompanyMonth,
	])

	useEffect(() => {
		fetchCompanyMonths()
		fetchPartyList()
	}, [fetchCompanyMonths, fetchPartyList])

	return (
		<DashboardWrapper>
			{entries && (
				<>
					<EntryList
						entries={entries}
						selectedEntryTypes={selectedEntryTypes.current}
						handleUpdateSelectedEntryTypes={handleUpdateSelectedEntryTypes}
						isLoading={isLoading}
						companyMonthList={companyMonthList}
						selectedCompanyMonth={selectedCompanyMonth}
						selectedParty={selectedParty.current}
						partyList={partyList}
						isPartyListLoading={isPartyListLoading}
						handleUpdateSelectedParty={handleUpdateSelectedParty}
						selectedSubParty={selectedSubParty.current}
						subPartyList={subPartyList}
						isSubPartyListLoading={isSubPartyListLoading}
						handleUpdateSelectedSubParty={handleUpdateSelectedSubParty}
						handleUpdateSelectedCompanyMonth={
							handleUpdateSelectedCompanyMonth
						}
					/>

					<SlipAddDrawers />
				</>
			)}
		</DashboardWrapper>
	)
}
