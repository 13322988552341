import { ComponentProps, FC, useCallback } from "react"
import { ICashReceiptV2 } from "src/domain/entities"
import { inwardCashReceiptUpdateService } from "src/domain/services/inwardCashReceipt/inwardCashReceiptUpdateService"
import { outwardCashReceiptUpdateService } from "src/domain/services/outwardCashReceipt/outwardCashReceiptUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { CashReceiptUpdateDrawerFormView, ICashReceiptUpdateFormFields } from "."
import { EItemType } from "../../EItemType"

type Props = Omit<
	ComponentProps<typeof CashReceiptUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: (itemSlip: ICashReceiptV2) => void
}

export const CashReceiptUpdateDrawerFormController: FC<Props> = ({
	onSuccess,
	type,
	slip,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<
		FormikOnSubmit<ICashReceiptUpdateFormFields["update"]>
	>(
		async (values, { setErrors }) => {
			try {
				if (type === EItemType.INWARD_CASH) {
					const cashReceipt = await inwardCashReceiptUpdateService(
						{
							filter: { id: slip.id },
							update: values,
						},
						token,
					)
					onSuccess?.(cashReceipt)
				} else if (type === EItemType.OUTWARD_CASH) {
					const cashReceipt = await outwardCashReceiptUpdateService(
						{
							filter: { id: slip.id },
							update: values,
						},
						token,
					)
					onSuccess?.(cashReceipt)
				} else {
					throw new Error("Invalid Item type")
				}
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, type, slip],
	)

	return (
		<CashReceiptUpdateDrawerFormView
			type={type}
			slip={slip}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
