import { Box, Button, Flex, FormControl, Heading, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { ErrorMessageField } from "src/components/ui"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { ICompanyCreateFormFields } from "."

interface Props {
	handleSubmit: FormikOnSubmit<ICompanyCreateFormFields>
}

export const CompanyCreateFormView: FC<Props> = ({ handleSubmit }) => {
	return (
		<Formik<ICompanyCreateFormFields>
			initialValues={{ name: "" }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ handleChange, isSubmitting }) => (
				<Flex height={"100vh"} alignItems={"center"} justifyContent={"center"}>
					<Stack as={Form} maxWidth={"sm"}>
						<Box>
							<Heading as="h1" size="lg">
								Setup Account
							</Heading>
							<br />
						</Box>
						<Box>
							{/* Company Name */}
							<FormControl>
								<InputLabel label="Account Name" />
								<Input
									name="name"
									maxLength={50}
									isRequired
									onChange={handleChange}
								/>
								<InputDescription>
									This will be the name of your company/account
									throughout this software.
								</InputDescription>
								<ErrorMessage component={ErrorMessageField} name="name" />
							</FormControl>
						</Box>
						<Box py="2">
							{/* Continue Button (Submit) */}
							<Button
								colorScheme={"blue"}
								type="submit"
								disabled={isSubmitting}
								isLoading={isSubmitting}
								width={"full"}
							>
								Continue
							</Button>
						</Box>
					</Stack>
				</Flex>
			)}
		</Formik>
	)
}
