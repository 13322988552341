import { IInwardCashReceiptV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	filter: { id: string }
	update: { amount?: number; note?: string | null }
}

interface ResponseDataShape {
	success: boolean
	data: IInwardCashReceiptV1
}

export async function inwardCashReceiptUpdateApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/inwardCashReceipt/update/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
