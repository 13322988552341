import { Box, Flex } from "@chakra-ui/react"

export interface TabItem {
	child: JSX.Element | string
	value: string
}

interface TabMenuProps {
	tabs: TabItem[]
	selectedTab: string
	onTabChange: (value: string) => void
}

export const TabMenu: React.FC<TabMenuProps> = ({ selectedTab, tabs, onTabChange }) => {
	return (
		<Box>
			<Box rounded="md" backgroundColor="gray.100" overflow="hidden" padding={1}>
				<Flex>
					{tabs.map(({ child, value }) => {
						const childComponent =
							typeof child === "string" ? (
								<DefaultTabItem label={child} />
							) : (
								child
							)

						const isSelected = value === selectedTab

						return (
							<Box
								key={value}
								value={value}
								onClick={() => {
									onTabChange(value)
								}}
								backgroundColor={isSelected ? "white" : "transparent"}
								marginX="0.5"
								paddingX={3}
								paddingY={0.5}
								className={isSelected ? "shadow-md" : ""}
								_hover={{
									backgroundColor: "whiteAlpha.700",
								}}
								borderRadius="base"
								cursor="pointer"
							>
								{childComponent}
							</Box>
						)
					})}
				</Flex>
			</Box>
		</Box>
	)
}

const DefaultTabItem: React.FC<{ label: string }> = ({ label }) => {
	return (
		<Box fontSize="small" textTransform="capitalize">
			{label}
		</Box>
	)
}
