import { subPartyListApi } from "../../api/subParty/subPartyListApi"
import { IPagination, ISubPartyV1 } from "../../entities"

export async function subPartyListService(
	filter: { id?: string; partyId?: string; isDefault?: boolean } & IPagination,
	token?: null | string,
) {
	const subParty = await subPartyListApi(
		{ filter, include: { details: true, party: true } },
		token,
	)
	return subParty as NonNullable<ISubPartyV1[]>
}
