import { IInwardCashReceiptV1 } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	partyId: string
	issueDate: string
	amount: number
	note?: null | string
}

interface ResponseDataShape {
	success: boolean
	data: IInwardCashReceiptV1
}

export async function inwardCashReceiptAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/inwardCashReceipt/add/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
