import { Container } from "@chakra-ui/react"
import { FC } from "react"

export const ContainerWrapper: FC<{}> = ({ children }) => {
	return (
		<Container paddingY={2} maxWidth={"container.lg"}>
			{children}
		</Container>
	)
}
